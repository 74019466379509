import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/i18n';

const PrescriptionsListPage = () =>
  import('@/modules/prescriptions/components/prescriptions-list-page.vue');
// const PrescriptionDetails = () =>
//   import('@/modules/prescriptions/components/prescription-details.vue');
const PrescriptionViewPage = () =>
  import('@/modules/prescriptions/components/prescription-view-page.vue');
const EditPrescription = () =>
  import('@/modules/prescriptions/components/edit-prescription.vue');
const MyPrescriptions = () =>
  import('@/modules/prescriptions/components/my-prescriptions.vue');
  export default [
    {
      path: '/:lang?',
      exact: true,
      component: Layout,
      
      children: [

        {
            name: 'prescriptions',
            path: 'prescriptions',
            component: PrescriptionsListPage,
            meta: { auth: true },
            beforeEnter: (to, from, next) => {
        document.title =
          i18n('app.title') +
          ' | ' +
          i18n('drugDeal.services.prescriptions') 
        next();
      },
        },
        {
          name: 'prescription-details',
          path: 'prescriptions/:id',
          // component: PrescriptionDetails,
          component: PrescriptionViewPage,
          meta: { auth: true },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('drugDeal.services.prescriptions') 
            next();
          },
        },
        {
          name: 'edit-prescription',
          path: 'edit-prescription/:id',
          component: EditPrescription,
          meta: { auth: true },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('drugDeal.services.prescriptions') 
            next();
          },
        },
        {
          name: 'my-prescriptions',
          path: 'my-prescriptions',
          component: MyPrescriptions,
          meta: { auth: true },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('drugDeal.prescriptions.myPrescriptions') 
            next();
          },
          // meta: { auth: true },
        }
      ],
    },]