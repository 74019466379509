import { render, staticRenderFns } from "./mail.vue?vue&type=template&id=6655878a&scoped=true&"
var script = {}
import style0 from "./mail.vue?vue&type=style&index=0&id=6655878a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6655878a",
  null
  
)

export default component.exports
import {QImg,QIcon,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QImg,QIcon,QBtn})
