import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/i18n';

const JobsListPage= () =>
  import('@/modules/jobs/components/jobs-list-page.vue');
const JobFormPage = () =>
  import('@/modules/jobs/components/job-form-page.vue');
const JobViewPage = () =>
  import('@/modules/jobs/components/job-view-page.vue');
const MyJobs = () =>
  import('@/modules/jobs/components/my-jobs.vue');

  export default [
    {
      path: '/:lang?',
      exact: true,
      component: Layout,
     
      children: [
        {
            name: 'job-opportunities',
            path: 'job-opportunities',
            component: JobsListPage,
            meta: { auth: true },
             beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('drugDeal.services.jobs') 
            next();
          },
          },
          {
            name: 'add-job',
            path: 'add-job/:edit?/:id?',
            component: JobFormPage,
            meta: { auth: true },
            beforeEnter: (to, from, next) => {
              document.title =
                i18n('app.title') +
                ' | ' +
                i18n('drugDeal.services.jobs') 
              next();
            },
          },
          {
            name: 'job-view',
            path: 'job-opportunities/:id',
            component: JobViewPage,
            meta: { auth: true },
            beforeEnter: (to, from, next) => {
              document.title =
                i18n('app.title') +
                ' | ' +
                i18n('drugDeal.services.jobs') 
              next();
            },
          },
          {
            name: 'my-jobs',
            path: 'my-jobs',
            component: MyJobs,
            meta: { auth: true },
            beforeEnter: (to, from, next) => {
              document.title =
                i18n('app.title') +
                ' | ' +
                i18n('drugDeal.jobs.myJobs') 
              next();
            },
          },
      ]
    }
]