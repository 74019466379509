import prescriptionsFormStore from '@/modules/prescriptions/prescriptions-form-store';
import prescriptionsDestroyStore from '@/modules/prescriptions/prescriptions-destroy-store';
import prescriptionsListStore from '@/modules/prescriptions/prescriptions-list-store';
import prescriptionsViewStore from '@/modules/prescriptions/prescription-view-store';

export default {
    namespaced: true,
  
    modules: {
      form: prescriptionsFormStore,
      destroy: prescriptionsDestroyStore,
      list: prescriptionsListStore,
      view: prescriptionsViewStore

    },
  };
  