
import { mapGetters, mapActions } from "vuex";
// import { i18n } from '@/i18n';
import i18n from "@/vueI18n";
import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;
import Register from "@/modules/auth/components/register.vue";
import DrugIndexListTable from "@/modules/drug-index/components/drug-index-list-table.vue";
// import firebase from 'firebase';
import moment from "moment";
import Menu from '@/modules/layout/components/menu.vue'
import ServicesItemsExpansion from '@/modules/layout/components/services-items-expansion.vue'



export default {
  name: "app-header",

  data() {
    return {
      searchDrugs: "",
      wid: 0,
      hide: "visible",
      active: "home",
      sidebar: "",
      activeSidebar: false,
      url: "",
      services: false,
      aboutNav: "text-dark text-weight-thin",
      servicesNav: "text-dark text-weight-thin",
      homeNav: "text-dark text-weight-thin",
      downloadNowNav: "text-dark text-weight-thin",
      contactUsNav: "text-dark text-weight-thin",
      loginNav: "text-dark text-weight-thin",
      login: false,
      loginSide: false,
      isUserRegistered: false,
      drugIndex: false,

      hideDrugIndex: true,
      modalVisible: false,
      dialogVisible: false,

      drawer: false,
      showServicesMenu: false
    };
  },
  computed: {
    ...mapGetters({
      currentUserNameOrEmailPrefix: "auth/currentUserNameOrEmailPrefix",
      currentUserAvatar: "auth/currentUserAvatar",
      isMobile: "layout/isMobile",
      isScreen_md: "layout/isScreen_md",
      is_screen_sm: "layout/is_screen_sm",
      is_screen_xs: "layout/is_screen_xs",
      tab: "layout/tab",
      navbar: "layout/navbar",
      currentUser: "auth/currentUser",
      loginHeader: "home/loginHeader",
      drugIndexHomeSearch: "home/drugIndexHomeSearch",
      drugs: "drugIndex/list/drugs",
      searchFilter: "drugIndex/list/searchFilter",
      // notificationMounted:'notification/notificationMounted',
      // newNotifications: 'notification/newNotifications',
      is_screen_galaxy_fold: "layout/is_screen_galaxy_fold",
      firstBatch: "notification/firstBatch",
      addedCreatorId: "notification/addedCreatorId",
    }),

    unreadNotifications() {
      if (this.firstBatch) {
        return this.firstBatch.filter(
          (notification) => notification.status == "unread"
        );
      }
      return [];
    },

    isRTL() {
      return i18n.locale == "ar";
    },
    headerBg: {
      // getter
      get: function () {
        return this.$store.state.layout.headerBg;
      },
      // setter
      set(newValue) {
        this.$store.state.layout.headerBg = newValue;
      },
    },
    isScreenXs() {
      return (
        this.$q.screen.lt.md &&
        this.$q.screen.lt.sm &&
        this.$q.screen.lt.lg &&
        this.$q.screen.lt.xl
      );
    },
    checkIfRouteIsHome() {
      if (this.$route.name == "home") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    firstBatch(newval) {
      newval.forEach(async (record) => {
        if (record.new) {
          await this.notificationShow(
            record.title[localStorage.getItem("language")]
          );
        }
      });

      newval.forEach((notification) => {
        if (notification.new) {
          const payload = {
            type: notification.type,
            status: "unread",
            createdAt: new Date(notification.createdAt.seconds * 1000),
            postId: notification.postId,
            createdBy: notification.createdBy,
            title: notification.title,
            description: notification.description,
            new: false,
          };
          this.doUpdateNotification({
            userId: this.currentUser.id,
            id: notification.id,
            values: payload,
          });
        }
      });
    },
    addedCreatorId(newval) {
      if (newval) {
        this.setCreatorToRow();
      }
    },

    // checkIfRouteIsHome(newvalue, oldvalue) {
    //   console.log("route watchersssss", newvalue);
    //   console.log("route watchersssss", oldvalue);
    // },
    loginHeader(newvalue) {

      if (newvalue) {
        this.navigate("login");
      } else {
        this.navigate("home");
      }
    },
    navbar() {
      // console.log('in watch')
      if (this.navbar == "services") {
        // console.log("call the nav clicked service")
        this.navClicked("services");
      }
      if (this.navbar == "about") {
        this.navClicked("about");
      }
      if (this.navbar == "login") {
        // console.log("call the nav clicked login")
        this.active = "login";
        this.homeNav = "text-dark text-weight-thin";
        this.servicesNav = "text-dark text-weight-thin";
        this.aboutNav = "text-dark text-weight-thin";
        this.downloadNowNav = "text-dark text-weight-thin";
        this.contactUsNav = "text-dark text-weight-thin";
        //   this.navClicked('login')
      }
    },
    currentUser(newvalue) {
      if (newvalue) {
        this.doFetchNotifications({ userId: newvalue.id, limit: 10 });
        this.setMountedTable(true);
      }
    },
  },
  components: {
    [Register.name]: Register,
    [DrugIndexListTable.name]: DrugIndexListTable,
    [Menu.name]: Menu,
    [ServicesItemsExpansion.name]: ServicesItemsExpansion
  },
  async mounted() {
    if (this.currentUser) {
      this.resetCursor();
      await this.doFetchNotifications({
        userId: this.currentUser.id,
        limit: 10,
      });
      this.setMountedTable(true);
    }

    if (this.$router.currentRoute.path == "/services") {
      this.sidebar = "services";
      this.navClicked("services");
    } else {
      this.sidebar = "home";
      this.navClicked("home");
    }
    scrollTo();
    function scrollTo() {
      const links = document.querySelectorAll(".scroll");
      links.forEach((each) => {
        each.onclick = scrollAnchors;
        // let target = each.getAttribute('href');
      });
    }
    function scrollAnchors(e, respond = null) {
      // console.log('in mounted header', this.getAttribute('href'))
      e.preventDefault();
      // const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
      var targetID = respond
        ? respond.getAttribute("href")
        : this.getAttribute("href");
      const targetAnchor = document.querySelector(targetID);
      if (!targetAnchor) return;
      // const originalTop = distanceToTop(targetAnchor);
      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    }
  },

  methods: {
    closeNav(eleId) {
      this.drawer = !this.drawer;
      this.checkRoute()
      this.checkMenuRoute(eleId);
    },
    checkMenuRoute(eleId) {
      //   this.hideDrugIndex = true;
      //   // this.setDrugIndex(false);
      //   console.log("in check route");
      //   this.$emit("changeHomeHeader", true);
      //   this.$emit("changeHomeHeaderAfterDrugIndex", true);

      // if (this.$route.name != "home" ) { 
      //   console.log(this.$route.name);  
      //   console.log("not home");
      //   this.$router.push("/");
      // }
      // if(this.$route.name == 'home'){
      //   this.setDrugIndex(false);
      // }
      // else{
      //   setTimeout(() => {
      //     this.setDrugIndex(false);
      //   }, 100)
      // }

      // console.log(this.$router.currentRoute.name);
      // if (this.$router.currentRoute.name != "home") {
      //   this.$router.push("/");
        setTimeout(() => this.handleMenuScroll(eleId), 500);
      // } else {
      //   setTimeout(() => this.handleMenuScroll(eleId), 500);
      // }
    },
    handleMenuScroll(eleId) {
      const ele = document.getElementById(eleId);
      const target = getScrollTarget(ele);
      const offset = ele.offsetTop;
      const duration = 1000;
      setScrollPosition(target, offset, duration);
    },

    openDrugIndex() {
      const ele = document.getElementById("header");
      const target = getScrollTarget(ele);
      const offset = ele.offsetTop;
      const duration = 0;
      setScrollPosition(target, offset, duration);

      this.drawer = !this.drawer;
      this.searchInputAnimation = true;
      this.showDrugIndexTable = true;

      this.$emit("changeHomeHeader", true);
      this.setDrugIndex(true);
      // setTimeout(() => {
      //   this.setDrugIndex(true)
      // }, 1000);
    },
    setHideDrugIndex(value) {
      this.hideDrugIndex = value;
    },
    async goToNotification(notification) {

      // debugger
      if (notification.type == "prescriptionComment") {
        if(this.$route.name != 'prescription-details'){
          // debugger
          this.$router.push(`/prescriptions/${notification.postId}`);
        }
        if(this.$route.name == 'prescription-details' && notification.postId != this.$route.params.id){
          // debugger
          this.$router.push({
            params:{
              id: notification.postId
            }
          });
        }
        
      }
      // else {
      //   console.log("offersssss");
      //   this.$router.push(`/offers/${notification.postId}`);
      // }
      if(notification.type == "offer"){
        // this.$router.push(`/offers/${notification.postId}`)
        if(this.$route.name != 'offers'){
          this.$router.push(`/offers/${notification.postId}`);
        }
        if(this.$route.name == 'offers' && notification.postId != this.$route.params.id){
          this.$router.push({
            params:{
              id: notification.postId
            }
          });
        }
      }
      if(notification.type == "propertyAccepted" || notification.type == "propertyPending" || notification.type == "propertyDestroyed"){
        if(this.$route.name != 'facility-view'){
          this.$router.push(`/facilities/${notification.postId}`);
        }
        if(this.$route.name == 'facility-view' && notification.postId != this.$route.params.id){
          this.$router.push({
            params:{
              id: notification.postId
            }
          });
        }
      }
      if(notification.type == "jobAccepted" || notification.type == "jobPending" || notification.type == "jobDestroyed"){
        if(this.$route.name != 'job-view'){
          this.$router.push(`/job-opportunities/${notification.postId}`);
        }
        if(this.$route.name == 'job-view' && notification.postId != this.$route.params.id){
          this.$router.push({
            params:{
              id: notification.postId
            }
          });
        }
      }
      if(notification.type == "prescriptionAccepted" || notification.type == "prescriptionPending" || notification.type == "prescriptionDestroyed"){
        // this.$router.push(`/prescriptions/${notification.postId}`)
        if(this.$route.name != 'prescription-details'){
          // debugger
          this.$router.push(`/prescriptions/${notification.postId}`);
        }
        if(this.$route.name == 'prescription-details' && notification.postId != this.$route.params.id){
          // debugger
          this.$router.push({
            params:{
              id: notification.postId
            }
          });
        }
      }
    },
    goToNotifications() {
      const lang = localStorage.getItem("language") || "en";
      this.$router.push(`/${lang}/notification`);
    },
    scrollHandler(scroll) {
      if (scroll.position == 0) {
        this.setHeaderBg("bg-transparent");
      } else {
        this.setHeaderBg("bg-shadow");
        // this.setHeaderBg('bg-red')
      }
    },
    showMenu() {
      this.drawer = !this.drawer;
      // this.$emit('showMenu')
    },
    closeMenu() {
      this.drawer = false;
    },
    markNotificationsRead(){
      this.firstBatch.forEach(async (notif) =>{
        if(notif.status == "unread"){
            const payload = {
              type: notif.type,
              status:'read',
              createdAt: new Date(notif.createdAt.seconds * 1000),
              postId: notif.postId,
              createdBy: notif.createdBy,
              title: notif.title,
              description: notif.description,
              new: notif.new
            }
          await this.doUpdateNotification({userId: this.currentUser.id, id: notif.id, values:payload})
          }
      })
    },
    ...mapActions({
      toggleMenu: "layout/toggleMenu",
      doSignout: "auth/doSignout",
      setDrugIndex: "home/setDrugIndex",
      setHeaderBg: "layout/setHeaderBg",
      doFetchDrugIndex: "drugIndex/list/doFetch",
      setSearch: "drugIndex/list/setSearch",
      doFetchNotifications: "notification/doFetchNotifications",
      setCreatorToRow: "notification/setCreatorToRow",
      resetCursor: "notification/resetCursor",
      // doFetchNotification:'notification/doFetch',
      // setNotificationMounted:'notification/setNotificationMounted',
      doUpdateNotification: "notification/doUpdate",
      setMountedTable: "notification/setMountedTable",

      // navigation: 'layout/navigation',
    }),
    notificationShow(message) {
      this.$q.notify({
        message: message,
        position: "top-right",
        timeout: 3000,
      });
    },
    notificationPresenter(notificationTitle) {
      if (this.isRTL) return notificationTitle["ar"];
      return notificationTitle["en"];
    },
    notificationDatePresenter(notificationDate) {
      const language = localStorage.getItem('language')
      moment.locale(language);
      return moment(notificationDate.seconds * 1000).fromNow() 
    },
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    doGoToProfile() {
      this.login = false;

      this.$router.push(`/${localStorage.getItem("language")}/profile`);
      this.active = "login";
      this.homeNav = "text-dark text-weight-thin";
      this.servicesNav = "text-dark text-weight-thin";
      this.aboutNav = "text-dark text-weight-thin";
      this.downloadNowNav = "text-dark text-weight-thin";
      this.contactUsNav = "text-dark text-weight-thin";
      // this.$router.push('/about')
    },

    async doLogout() {
      await this.doSignout();
      // this.$router.push('/')
      this.navigate("home");
      this.login = false;
      this.loginSide = false;
    },
    closeRegisterDialog() {
      // setTimeout(() => {
      //   this.login = false
      // },500)

      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    handleDropdownCommand(command) {
      if (command === "doSignout") {
        this.doSignout();
      }

      if (command === "doEditProfile") {
        this.doEditProfile();
      }
    },
    registerToggle() {
      this.isUserRegistered = true;
      this.navClicked("home");
    },

    doEditProfile() {
      return this.$router.push("/auth/edit-profile");
    },

    navigate(value) {
      this.drawer = false;
      if (value == "login") {
        this.navClicked(value);
      } else {
        // debugger
        this.checkRoute(value);
        this.navClicked(value);
      }
    },
    navClicked(value) {
      if (value == "home") {
        this.active = "home";
        this.homeNav = "text-primary";
        this.servicesNav = "text-dark text-weight-thin";
        this.aboutNav = "text-dark text-weight-thin";
        this.downloadNowNav = "text-dark text-weight-thin";
        this.contactUsNav = "text-dark text-weight-thin";
        this.loginNav = "text-dark text-weight-thin";
      } else if (value == "services") {
        this.active = "services";
        this.homeNav = "text-dark text-weight-thin";
        this.servicesNav = "text-primary";
        this.aboutNav = "text-dark text-weight-thin";
        this.downloadNowNav = "text-dark text-weight-thin";
        this.contactUsNav = "text-dark text-weight-thin";
        this.loginNav = "text-dark text-weight-thin";
      } else if (value == "about") {
        this.active = "about";
        this.homeNav = "text-dark text-weight-thin";
        this.servicesNav = "text-dark text-weight-thin";
        this.aboutNav = "text-primary";
        this.downloadNowNav = "text-dark text-weight-thin";
        this.contactUsNav = "text-dark text-weight-thin";
        this.loginNav = "text-dark text-weight-thin";
      } else if (value == "downloadNow") {
        this.active = "downloadNow";
        this.homeNav = "text-dark text-weight-thin";
        this.servicesNav = "text-dark text-weight-thin";
        this.aboutNav = "text-dark text-weight-thin";
        this.loginNav = "text-dark text-weight-thin";
        this.downloadNowNav = "text-primary";
        this.contactUsNav = "text-dark text-weight-thin";
      } else if (value == "contactUs") {
        this.active = "contactUs";
        this.homeNav = "text-dark text-weight-thin";
        this.servicesNav = "text-dark text-weight-thin";
        this.aboutNav = "text-dark text-weight-thin";
        this.downloadNowNav = "text-dark text-weight-thin";
        this.contactUsNav = "text-primary";
        this.loginNav = "text-dark text-weight-thin";
      } else if (value == "login") {
        this.modalVisible = true;
        this.dialogVisible = true;
        this.login = true;
        this.loginNav = "text-dark text-weight-thin";
        this.active = "login";
        this.homeNav = "text-dark text-weight-thin";
        this.servicesNav = "text-dark text-weight-thin";
        this.aboutNav = "text-dark text-weight-thin";
        this.downloadNowNav = "text-dark text-weight-thin";
        this.contactUsNav = "text-dark text-weight-thin";
        this.loginNav = " text-dark text-weight-bold";
      }
    },
    checkRoute() {      
        this.hideDrugIndex = true;
        // this.setDrugIndex(false);
        this.$emit("changeHomeHeader", true);
        this.$emit("changeHomeHeaderAfterDrugIndex", true);

      if (this.$route.name != "home" ) { 
        this.$router.push("/");
      }
      if(this.$route.name == 'home'){
        this.setDrugIndex(false);
      }
      else{
        setTimeout(() => {
          this.setDrugIndex(false);
        }, 100)
      }
      
      
    },

    handleScroll(eleId) {
      const ele = document.getElementById(eleId); // You need to get your element here
      const target = getScrollTarget(ele);
      const header = document.getElementById("header");
      const offset = ele.offsetTop - header.offsetHeight;
      // const offset = ele.offsetTop
      const duration = 2000;
      setScrollPosition(target, offset, duration);
      // this.navigation(eleId)
    },

    doGoToDrugIndex() {
      // this.$router.push('drug-index')
    },
    searchInDrugs() {
      this.drugIndex = true;
      this.setDrugIndex(true);
      this.setSearch(this.searchDrugs);
      this.hideDrugIndex = false;
      this.$emit("changeHomeHeader", this.searchDrugs);

      // console.log('hjhjjhhjhj',this.searchFilter)
    },
    changeHomeHeader(value) {
      // console.log('change home header ',value)
      this.$emit("changeHomeHeader", value);
    },
    changeHomeHeaderAfterDrugIndex(value) {
      this.$emit("changeHomeHeaderAfterDrugIndex", value);
    },
    changeDrugIndexHeader(value) {
      this.$emit("changeDrugIndexHeader", value);
    },
    hideHomeComponents(value) {
      this.$emit("hideHomeComponents", value);
    },
    scrollHandlerHeader(scroll) {
      const header = document.getElementById("header");
      const home = document.getElementById("home");
      const services = document.getElementById("services");
      const about = document.getElementById("about");
      const downloadNow = document.getElementById("downloadNow");
      const contactUs = document.getElementById("contactUs");

      const homeSide = document.getElementsByClassName("homeSide");
      const servicesSide = document.getElementsByClassName("servicesSide");
      const aboutSide = document.getElementsByClassName("aboutSide");
      const downloadNowSide =
        document.getElementsByClassName("downloadNowSide");
      const contactUsSide = document.getElementsByClassName("contactUsSide");

      if (this.$router.currentRoute.name == "home") {
        if (
          scroll.position >= home.offsetHeight - header.offsetHeight - 44 &&
          scroll.position < services.offsetTop - header.offsetHeight - 44
        ) {
          // console.log("home")
          this.navClicked("home");
          if (this.$q.screen.width < 1036) {
            homeSide[0].classList.add("active");
            servicesSide[0].classList.remove("active");
            aboutSide[0].classList.remove("active");
            downloadNowSide[0].classList.remove("active");
            contactUsSide[0].classList.remove("active");
          }
        } else if (
          scroll.position >= services.offsetTop - header.offsetHeight - 1000 &&
          scroll.position < about.offsetTop - header.offsetHeight - 1000
        ) {
          this.navClicked("services");
          if (this.$q.screen.width < 1036) {
            homeSide[0].classList.remove("active");
            servicesSide[0].classList.add("active");
            aboutSide[0].classList.remove("active");
            downloadNowSide[0].classList.remove("active");
            contactUsSide[0].classList.remove("active");
          }
        } else if (
          scroll.position >= about.offsetTop - header.offsetHeight - 44 &&
          scroll.position < downloadNow.offsetTop - header.offsetHeight - 44
        ) {
          // console.log("about")
          this.navClicked("about");
          if (this.$q.screen.width < 1036) {
            homeSide[0].classList.remove("active");
            servicesSide[0].classList.remove("active");
            aboutSide[0].classList.add("active");
            downloadNowSide[0].classList.remove("active");
            contactUsSide[0].classList.remove("active");
          }
        } else if (
          scroll.position >= downloadNow.offsetTop - header.offsetHeight &&
          scroll.position < contactUs.offsetTop - header.offsetHeight
        ) {
          // console.log("downloadNow")
          this.navClicked("downloadNow");
          if (this.$q.screen.width < 1036) {
            homeSide[0].classList.remove("active");
            servicesSide[0].classList.remove("active");
            aboutSide[0].classList.remove("active");
            downloadNowSide[0].classList.add("active");
            contactUsSide[0].classList.remove("active");
          }
        }
        // else if (scroll.position >=  contactUs.offsetTop - header.offsetHeight && scroll.position < downloadNow.offsetTop - header.offsetHeight - 150 && this.$q.screen.width > 1024){
        //   console.log("contact us")
        //   this.navClicked('contactUs')

        // }
        else if (scroll.position >= contactUs.offsetTop - header.offsetHeight) {
          // console.log('contactUs')
          this.navClicked("contactUs");
          if (this.$q.screen.width < 1036) {
            homeSide[0].classList.remove("active");
            servicesSide[0].classList.remove("active");
            aboutSide[0].classList.remove("active");
            downloadNowSide[0].classList.remove("active");
            contactUsSide[0].classList.add("active");
          }
        } else {
          // console.log('go home again')
          this.navClicked("home");
          if (this.$q.screen.width < 1036) {
            homeSide[0].classList.add("active");
            servicesSide[0].classList.remove("active");
            aboutSide[0].classList.remove("active");
            downloadNowSide[0].classList.remove("active");
            contactUsSide[0].classList.remove("active");
          }
        }
      }
    },

    loginSidebar() {
      setTimeout(() => {
        const loginSide = document.getElementsByClassName("loginSide");
        loginSide[0].classList.remove("active");
        // console.log('classList',loginSide[0].classList)
        // loginSide[0].classList.remove("active");
        this.loginSide = true;
        this.activeSidebar = !this.activeSidebar;
      }, 10);
    },
  },
};
