// import jobListExporterFields from '@/modules/job/job-list-exporter-fields';
import { firestoreAction, vuexfireMutations } from "vuexfire";
import firebase from "firebase/app";
// import Exporter from '@/shared/exporter/exporter';

const INITIAL_PAGE_SIZE = 20;

export default {
  namespaced: true,

  state: {
    rows: [],
    rowsFromRoute: [],
    // //#region  vuexfire variables
    loadMoreLastRecord: null,
    loadFirstRecord: null,
    recordAfterLast: [],
    recordBeforeFirst: [],
    totalRecords: null,
    pagesNumber: null,
    pageCount: 0,
    mountedTable: false,
    userPrescriptions: [],
    users: [],
    rowsCreator: [],
    addedRowId: null,
    addedCreatorId: null,
    rowComments: [],
    // //#endregion
    currentRoute: undefined,

    count: 0,
    loading: false,
    backgroundLoading: false,
    filter: {},
    pagination: {},
    sorter: {},

    table: null,
    loadingAddMorePrescriptions: true,
  },

  getters: {
    backgroundLoading: (state) => state.backgroundLoading,
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],
    rowsFromRoute: (state) => state.rowsFromRoute || [],
    // //#region vuexfire getters
    mountedTable: (state) => state.mountedTable,
    loadingAddMorePrescriptions: (state) => state.loadingAddMorePrescriptions,
    loadMoreLastRecord: (state) => {
      return state.loadMoreLastRecord;
    },
    loadFirstRecord: (state) => {
      return state.loadFirstRecord;
    },
    recordAfterLast: (state) => state.recordAfterLast,
    recordBeforeFirst: (state) => state.recordBeforeFirst,
    totalRecords: (state) => state.totalRecords,
    pagesNumber: (state) => state.pagesNumber,
    pageCount: (state) => state.pageCount,
    userPrescriptions: (state) => state.userPrescriptions,
    users: (state) => state.users,
    rowsCreator: (state) => state.rowsCreator,
    addedRowId: (state) => state.addedRowId,
    addedCreatorId: (state) => state.addedCreatorId,
    rowComments: (state) => state.rowComments,
    // //#endregion
    currentRoute: (state) => state.currentRoute,

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction = sorter.order === "descending" ? "DESC" : "ASC";

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED_IN_BACKGROUND(state, payload) {
      state.backgroundLoading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination ? state.pagination : {};
    },

    FETCH_STARTED(state) {
      state.loading = true;
      // debugger
    },

    FETCH_SUCCESS(state) {
      state.loading = false;
    },

    FETCH_ERROR(state) {
      state.loading = false;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },

    SET_CURRENTROUTE(state, payload) {
      state.currentRoute = payload;
    },

    //#region VuexfireMutations
    ...vuexfireMutations,
    SET_MOUNTEDTABLE(state, payload) {
      state.mountedTable = payload;
    },
    SET_LOADING_ADD_MORE_PRESCRIPTIONS(state, payload) {
      state.loadingAddMorePrescriptions = payload;
    },
    SET_LOADMORE_LAST(state) {
      state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc;
      state.recordAfterLast = state.rows[state.rows.length - 1];
    },
    SET_LOADFIRSTRECORD(state) {
      state.loadFirstRecord = state.rows[0]._doc;
    },
    RESET_CURSOR(state) {
      state.loadMoreLastRecord = null;
      state.loadFirstRecord = null;
    },
    REMOVELASTELEMENT(state) {
      state.rows.pop();
    },
    SET_TOTAL_RECORDS(state, payload) {
      state.totalRecords = payload;
      state.pagesNumber = Math.ceil(state.totalRecords / 10);
    },
    SET_PAGE_COUNT(state, payload) {
      state.pageCount = payload;
    },
    SET_ROWS(state, payload) {
      state.rows = payload;
      // debugger
    },
    SET_ROWS_FROM_ROUTE(state, payload) {
      state.rowsFromRoute = payload;
    },
    SET_ADDED_ROW_ID(state, payload) {
      state.addedRowId = payload;
    },
    SET_ADDED_CREATOR_ID(state, payload) {
      state.addedCreatorId = payload;
    },
    async SET_CREATOR_TO_ROW(state) {
      const creator = await firebase
        .firestore()
        .collection("user")
        .doc(state.addedCreatorId)
        .get();
      state.rowsCreator.push(creator.data());
      const addedRow = state.rows.find((el) => el.id == state.addedRowId);
      addedRow["creator"] = creator.data();
    },
    //#endregion
  },

  actions: {
    doUnselectAll({ commit }) {
      commit("UNSELECT_ALL");
    },

    doMountTable({ commit }, table) {
      commit("TABLE_MOUNTED", table);
    },

    async doReset({ commit, dispatch }) {
      commit("RESETED");
      return dispatch("doFetch");
    },

    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit("PAGINATION_CHANGED", pagination);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit("PAGINATION_PAGE_SIZE_CHANGED", pageSize);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit("PAGINATION_CURRENT_PAGE_CHANGED", currentPage);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit("SORTER_CHANGED", sorter);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    // //#region vuexfire
    setMountedTable({ commit }, value) {
      commit("SET_MOUNTEDTABLE", value);
    },
    setLoadingAddMorePrescriptions({ commit }, value) {
      commit("SET_LOADING_ADD_MORE_PRESCRIPTIONS", value);
    },
    setCurrentRoute({ commit }, value) {
      commit("SET_CURRENTROUTE", value);
    },
    doFetch: async ({ getters, commit }, payload) => {
      //   commit('SET_PAGE_COUNT',getters.pageCount + 1)
      // debugger
      commit("FETCH_STARTED");
      commit("SET_ROWS", []);
      // const customSerializer = (doc) => {
      //   const data = doc.data();
      //   Object.defineProperty(data, "_doc", { value: doc });
      //   data["creator"] = "";
      //   data["commentsLength"] = 0;
      //   if (payload.currentRoute == "my-prescriptions") {
      //     data["creator"] = payload.currentUser;
      //   } else {
      // if (getters.rowsCreator.length > 0) {
      //   var creatorIndex = getters.rowsCreator.findIndex(
      //     (el) => el.id == data.createdBy
      //   );
      //   if (creatorIndex != -1) {
      //     let creator = getters.rowsCreator.find(
      //       (el) => el.id == data.createdBy
      //     );
      //     data["creator"] = creator;
      //   }
      // }
      // var foundIndex = getters.users.findIndex(
      //   (el) => el == data.createdBy
      // );
      // if (foundIndex == -1) {
      //   getters.users.push(data.createdBy);
      //   if (getters.mountedTable) {
      //     commit("SET_ADDED_ROW_ID", data.id);
      //     commit("SET_ADDED_CREATOR_ID", data.createdBy);
      //   }
      // }
      //   }

      //   return data;
      // };
      const db = firebase.firestore();
      var prescriptionCollection = null;
      if (payload.currentRoute == "my-prescriptions") {
        prescriptionCollection = getters.loadMoreLastRecord
          ? db
              .collection("prescription")
              .where("postStatus", "==", "accepted")
              .where("createdBy", "==", payload.currentUser.id)
              .orderBy("createdAt", "desc")
              .startAfter(getters.loadMoreLastRecord)
              .limit(payload.limit || 9)
          : db
              .collection("prescription")
              .where("postStatus", "==", "accepted")
              .where("createdBy", "==", payload.currentUser.id)
              .orderBy("createdAt", "desc")
              .limit(payload.limit || 9);
      } else {
        prescriptionCollection = getters.loadMoreLastRecord
          ? db
              .collection("prescription")
              .where("postStatus", "in", ["accepted", "deleted"])
              .where("isActive", "==", true)
              .limit(payload.limit || 9)
              .orderBy("createdAt", "desc")
              .startAfter(getters.loadMoreLastRecord)
          : db
              .collection("prescription")
              .where("postStatus", "in", ["accepted", "deleted"])
              .where("isActive", "==", true)
              .orderBy("createdAt", "desc")
              .limit(payload.limit || 9);
      }
      const snapshot = await prescriptionCollection.get();
      const prescriptions = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          data["creator"] = "";
          data["commentsLength"] = 0;
          if (payload.currentRoute === "my-prescriptions") {
            data["creator"] = payload.currentUser;
          } else {
            let user = await db.collection("user").doc(data.createdBy).get();
            data["creator"] = user.exists ? user.data() : "";
            let comments = await db
              .collection("prescription")
              .doc(data.id)
              .collection("comments")
              .get();
            data["commentsLength"] = comments.size || 0;
          }
          return data;
        })
      );
      commit("SET_ROWS", prescriptions);
      // if (payload.currentRoute != "my-prescriptions") {
      // if (getters.users.length > 0) {
      // let userCollection = db.collection("user");
      // for (let i = 0; i < getters.users.length; i += 10) {
      //   const chunk = getters.users.slice(i, i + 10);
      //   userCollection = userCollection.where("id", "in", chunk);
      // }
      // await bindFirestoreRef(`rowsCreator`, userCollection);
      // }
      // getters.rows.forEach(async (row) => {
      //   let creator = getters.rowsCreator.find(
      //     (el) => el.id == row.createdBy
      //   );
      //   row["creator"] = creator;
      // });
      //   getters.rows.forEach(async (row) => {
      //     let doc = await db.collection("user").doc(row.createdBy).get()
      //     let creator = doc.exists ? doc.data() : null
      //     row["creator"] = creator;
      //   });
      // }
      // getters.rows.forEach(async (row) => {
      //   let comments = await firebase
      //     .firestore()
      //     .collection("prescription")
      //     .doc(row.id)
      //     .collection("comments")
      //     .get();
      //   row.commentsLength = comments.docs.length;
      // });
      commit("FETCH_SUCCESS");
    },

    doFetchFromRoute: async ({ commit, getters }, payload) => {
      // console.log("payload", payload);
      // debugger
      commit("FETCH_STARTED");
      // const customSerializer = (doc) => {
      //   const data = doc.data();
      //   Object.defineProperty(data, "_doc", { value: doc });
      //   data["creator"] = "";
      //   data["commentsLength"] = 0;
      //   if (payload.currentRoute == "my-prescriptions") {
      //     data["creator"] = payload.currentUser;
      //   } else {
      //     // console.log("row creator", getters.rowsCreator.length);
      //     // if (getters.rowsCreator.length > 0) {
      //     //   var creatorIndex = getters.rowsCreator.findIndex(
      //     //     (el) => el.id == data.createdBy
      //     //   );
      //     //   if (creatorIndex != -1) {
      //     //     let creator = getters.rowsCreator.find(
      //     //       (el) => el.id == data.createdBy
      //     //     );
      //     //     data["creator"] = creator;
      //     //   }
      //     // }
      //     // var foundIndex = getters.users.findIndex(
      //     //   (el) => el == data.createdBy
      //     // );
      //     // // console.log("foundIndex", foundIndex);
      //     // if (foundIndex == -1) {
      //     //   getters.users.push(data.createdBy);
      //     //   if (getters.mountedTable) {
      //     //     commit("SET_ADDED_ROW_ID", data.id);
      //     //     commit("SET_ADDED_CREATOR_ID", data.createdBy);
      //     //   }
      //     // }
      //   }

      //   return data;
      // };
      const db = firebase.firestore();
      var prescriptionCollection = null;
      const endAt = payload.page * payload.limit;
      if (payload.currentRoute == "my-prescriptions") {
        prescriptionCollection = db
          .collection("prescription")
          .where("postStatus", "==", "accepted")
          .where("createdBy", "==", payload.currentUser.id)
          .orderBy("createdAt", "desc")
          .limit(endAt);
      } else {
        // debugger
        prescriptionCollection = db
          .collection("prescription")
          .where("postStatus", "in", ["accepted", "deleted"])
          .where("isActive", "==", true)
          .orderBy("createdAt", "desc")
          .limit(endAt);
      }
      const snapshot = await prescriptionCollection.get();
      const prescriptions = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          data["creator"] = "";
          data["commentsLength"] = 0;
          if (payload.currentRoute === "my-prescriptions") {
            data["creator"] = payload.currentUser;
          } else {
            let user = await db.collection("user").doc(data.createdBy).get();
            data["creator"] = user.exists ? user.data() : "";
            let comments = await db
              .collection("prescription")
              .doc(data.id)
              .collection("comments")
              .get();
            data["commentsLength"] = comments.size || 0;
          }
          return data;
        })
      );
      commit("SET_ROWS_FROM_ROUTE", prescriptions);
      // if (payload.currentRoute != "my-prescriptions") {
      // console.log("row users", getters.users.length);
      // if (getters.users.length > 0) {
      //   const userCollection = db
      //     .collection("user")
      //     .where("id", "in", getters.users);
      //   await bindFirestoreRef(`rowsCreator`, userCollection);
      // }
      // getters.rowsFromRoute.forEach(async (row) => {
      //   let creator = getters.rowsCreator.find(
      //     (el) => el.id == row.createdBy
      //   );
      //   row["creator"] = creator;
      // });
      // getters.rows.forEach(async (row) => {
      //   let doc = await db.collection("user").doc(row.createdBy).get();
      //   let creator = doc.exists ? doc.data() : null;
      //   // console.log("creator", creator);
      //   row["creator"] = creator;
      // });
      // }
      // getters.rowsFromRoute.forEach(async (row) => {
      //   let comments = await firebase
      //     .firestore()
      //     .collection("prescription")
      //     .doc(row.id)
      //     .collection("comments")
      //     .get();
      //   row.commentsLength = comments.docs.length;
      // });
      commit("FETCH_SUCCESS");
    },
    setRows({ commit }, payload) {
      commit("FETCH_STARTED");
      commit("SET_ROWS", payload);
      commit("FETCH_SUCCESS");
    },
    setLoadMoreLast({ commit }) {
      commit("SET_LOADMORE_LAST");
    },
    resetCursor({ commit }) {
      commit("RESET_CURSOR");
    },
    setLoadFirstRecord({ commit }) {
      commit("SET_LOADFIRSTRECORD");
    },
    removeLastElement({ commit }) {
      commit("REMOVELASTELEMENT");
    },
    setCreatorToRow({ commit }) {
      commit("SET_CREATOR_TO_ROW");
    },
    getPreviousRecordsBatch: async ({ getters, commit }, payload) => {
      commit("FETCH_STARTED");
      // const customSerializer = (doc) => {
      //   const data = doc.data();
      //   Object.defineProperty(data, "_doc", { value: doc });
      //   data["creator"] = "";
      //   data["commentsLength"] = 0;
      //   if (payload.currentRoute == "my-prescriptions") {
      //     data["creator"] = payload.currentUser;
      //   } else {
      //     // if (getters.rowsCreator.length > 0) {
      //     //   var creatorIndex = getters.rowsCreator.findIndex(
      //     //     (el) => el.id == data.createdBy
      //     //   );
      //     //   if (creatorIndex != -1) {
      //     //     let creator = getters.rowsCreator.find(
      //     //       (el) => el.id == data.createdBy
      //     //     );
      //     //     data["creator"] = creator;
      //     //   }
      //     // }
      //     // var foundIndex = getters.users.findIndex(
      //     //   (el) => el == data.createdBy
      //     // );
      //     // if (foundIndex == -1) {
      //     //   getters.users.push(data.createdBy);
      //     //   if (getters.mountedTable) {
      //     //     commit("SET_ADDED_ROW_ID", data.id);
      //     //     commit("SET_ADDED_CREATOR_ID", data.createdBy);
      //     //   }
      //     // }
      //   }

      //   return data;
      // };
      const db = firebase.firestore();
      var prescriptionCollection = null;
      if (payload.currentRoute == "my-prescriptions") {
        prescriptionCollection = db
          .collection("prescription")
          .where("postStatus", "==", "accepted")
          .where("createdBy", "==", payload.currentUser.id)
          .orderBy("createdAt", "desc")
          .endBefore(getters.loadFirstRecord)
          .limitToLast(payload.limit || 9);
      } else {
        prescriptionCollection = db
          .collection("prescription")
          .where("postStatus", "in", ["accepted", "deleted"])
          .where("isActive", "==", true)
          .orderBy("createdAt", "desc")
          .endBefore(getters.loadFirstRecord)
          .limitToLast(payload.limit || 9);
      }
      const snapshot = await prescriptionCollection.get();
      const prescriptions = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          data["creator"] = "";
          data["commentsLength"] = 0;
          if (payload.currentRoute === "my-prescriptions") {
            data["creator"] = payload.currentUser;
          } else {
            let user = await db.collection("user").doc(data.createdBy).get();
            data["creator"] = user.exists ? user.data() : "";
            let comments = await db
              .collection("prescription")
              .doc(data.id)
              .collection("comments")
              .get();
            data["commentsLength"] = comments.size || 0;
          }
          return data;
        })
      );
      commit("SET_ROWS", prescriptions);
      // if (payload.currentRoute != "my-prescriptions") {
      // if (getters.users.length > 0) {
      //   const userCollection = db
      //     .collection("user")
      //     .where("id", "in", getters.users);
      //   await bindFirestoreRef(`rowsCreator`, userCollection);
      // }
      // getters.rows.forEach(async (row) => {
      //   let creator = getters.rowsCreator.find(
      //     (el) => el.id == row.createdBy
      //   );
      //   row["creator"] = creator;
      // });
      //   getters.rows.forEach(async (row) => {
      //     let doc = await db.collection("user").doc(row.createdBy).get();
      //     let creator = doc.exists ? doc.data() : null;
      //     row["creator"] = creator;
      //   });
      // }
      // getters.rows.forEach(async (row) => {
      //   let comments = await firebase
      //     .firestore()
      //     .collection("prescription")
      //     .doc(row.id)
      //     .collection("comments")
      //     .get();
      //   row.commentsLength = comments.docs.length;
      // });
      commit("FETCH_SUCCESS");
    },
    setDisableNext: firestoreAction(
      async ({ getters, bindFirestoreRef }, payload) => {
        const db = firebase.firestore();
        var prescriptionCollection = null;
        if (payload.currentRoute == "my-prescriptions") {
          prescriptionCollection = db
            .collection("prescription")
            .where("postStatus", "==", "accepted")
            .where("createdBy", "==", payload.currentUser.id)
            .orderBy("createdAt", "desc")
            .startAfter(getters.loadMoreLastRecord)
            .limit(1);
        } else {
          prescriptionCollection = db
            .collection("prescription")
            .where("postStatus", "in", ["accepted", "deleted"])
            .where("isActive", "==", true)
            .orderBy("createdAt", "desc")
            .startAfter(getters.loadMoreLastRecord)
            .limit(1);
        }
        await bindFirestoreRef("recordAfterLast", prescriptionCollection);
      }
    ),
    setDisablePrevious: firestoreAction(
      async ({ getters, bindFirestoreRef }, payload) => {
        const db = firebase.firestore();
        var prescriptionCollection = null;
        if (payload.currentRoute == "my-prescriptions") {
          prescriptionCollection = db
            .collection("prescription")
            .where("postStatus", "==", "accepted")
            .where("createdBy", "==", payload.currentUser.id)
            .orderBy("createdAt", "desc")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(1);
        } else {
          prescriptionCollection = db
            .collection("prescription")
            .where("postStatus", "in", ["accepted", "deleted"])
            .where("isActive", "==", true)
            .orderBy("createdAt", "desc")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(1);
        }

        await bindFirestoreRef("recordBeforeFirst", prescriptionCollection);
      }
    ),

    // //#endregion
  },
};
