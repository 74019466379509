
import i18n from "@/vueI18n";
export default {
  name: "app-drug-index-filter-modal",
  props: ["visible", "filterModal"],
  data() {
    return {
      filter: this.filterModal,
      disabled: true,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close", this.filter);
      },
    },
    isRTL() {
      return i18n.locale == "ar";
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    chooseFilter() {
      this.$emit("close", this.filter);
    },
  },
};
