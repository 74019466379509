
import 'boxicons';
import i18n from '@/vueI18n';

export default {
    name:"app-add-dialog",
    props:['input','choiceName','choices','chosenOption'],
    data(){
        return {
            workplaceChoice:'',
            option: "",
            choosen:'',
             radio: '',
             shape:'',
              accepted: 'op1',
              options:[
                   {
                    label: 'Option 1',
                    value: 'op1'
                    },
                    {
                    label: 'Option 2',
                    value: 'op2'
                    },
                    {
                    label: 'Option 3',
                    value: 'op3'
                    }
              ],
              picked:'1'
        }
    },
    computed:{
        addDialog:{
            get(){
                return this.input
            },
            set(){
                this.$emit('close',this.option)
            }
        },
        isRTL() {
            return i18n.locale == 'ar'
        },
    },
    watch:{
        chosenOption(newvalue){
            if(newvalue){
                if(newvalue != undefined){
                    
                    this.option = this.choices.find((choice) => choice.id == this.chosenOption.id)
                }
                else{  
                    this.option = this.choices[0]  
                }
            }
            else{  
                this.option = this.choices[0]  
            }
        },
        choices(newvalue){
            if(newvalue){
                this.option = this.choices[0]
            }
        }
    },
    methods:{
         i18n(key, args) {
        return this.$t(key, args);
        },
        close(){
            this.$emit('close',this.option)
        },
        toggleState(e, id) {
        if (!this.model.scraperData.includes(id)) {
            this.model.scraperData.push(id);
        } else {
            const index = this.model.scraperData.indexOf(id);
            this.model.scraperData.splice(index, 1);
        }
    },

    },
    created(){
        // if(!this.chosenOption || this.chosenOption == undefined && this.choices.length > 0){
        //     debugger
            this.option = this.choices[0]
        // } 
    }

}
