import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/i18n';

const FacilitiesListPage = () =>
  import('@/modules/facilities/components/facilities-list-page.vue');
const FacilityViewPage = () =>
  import('@/modules/facilities/components/facility-view-page.vue');
const FacilityFormPage = () =>
  import('@/modules/facilities/components/facility-form-page.vue');
const MyFacilities = () =>
  import('@/modules/facilities/components/my-facilities.vue');
  export default [
    {
      path: '/:lang?',
      exact: true,
      component: Layout,
      
      children: [
        {
            name: 'facilities',
            path: 'facilities',
            component: FacilitiesListPage,
            meta: { auth: true },
            beforeEnter: (to, from, next) => {
              document.title =
                i18n('app.title') +
                ' | ' +
                i18n('drugDeal.services.facilities') 
              next();
            },
          },
          {
            name: 'facility-view',
            path: 'facilities/:id',
            component: FacilityViewPage,
            meta: { auth: true },
            beforeEnter: (to, from, next) => {
              document.title =
                i18n('app.title') +
                ' | ' +
                i18n('drugDeal.services.facilities') 
              next();
            },
          },
          {
            name: 'add-facility',
            path: 'add-facility/:edit?/:id?',
            component: FacilityFormPage,
            meta: { auth: true },
            beforeEnter: (to, from, next) => {
              document.title =
                i18n('app.title') +
                ' | ' +
                i18n('drugDeal.services.facilities') 
              next();
            },
          },
          {
            name: 'my-facilities',
            path: 'my-facilities',
            component: MyFacilities,
            meta: { auth: true },
            beforeEnter: (to, from, next) => {
              document.title =
                i18n('app.title') +
                ' | ' +
                i18n('drugDeal.facilities.myFacilities') 
              next();
            },
          },
      ]
    }
]