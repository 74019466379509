
// import {i18n} from '@/i18n';

export default {
  // name:'app-footer'
  name: "app-footer",
  created() {},
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    goToRunprofWebsite() {
      this.$router.push("https://www.runprof.com");
    },
  },
  computed: {
    currentYear() {
      const d = new Date();
      return d.getFullYear();
    },
  },
};
