import Vue from 'vue'

import './styles/quasar.scss'
import iconSet from 'quasar/icon-set/fontawesome-v5'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import 'quasar/dist/quasar.addon.css';
import { Quasar } from 'quasar'
import {
  QImg,
  QIcon,
  QLayout,
  QHeader,
  QFooter,
  QTab,
  QTabs,
  QRouteTab,
  QDialog,
  QSpace,
  QInput,
  QDate,
  QPopupProxy,
  QTime,
  QBanner,
  QTabPanels,
  QTabPanel,
  QPageContainer,
  QPage,
  QTable,
  QTh,
  QTr,
  QTd,
  QOptionGroup,
  QAvatar,
  QBreadcrumbs,
  QBreadcrumbsEl,
  QUploader,
  QFile,
  QSelect,
  QDrawer,
  QResponsive,
  // QTransition,
  // vueFlip,
  QSpinner,
  QBtn,
  Meta,
  Notify,
  // Dialog,
  Loading,
  // LoadingBar,
  // LocalStorage,
  // SessionStorage,
  // QScrollObservable,
  QScrollObserver,
} from 'quasar'

const path = require("path");
// const SitemapPlugin = require('sitemap-webpack-plugin').default
// const paths = [
//   { path: '/en' },
//   // { path: '/about' },
//   // { path: '/credits' },
//   // { path: '/contact' },
//   // { path: '/download' }
  
// ] 




Vue.use(Quasar, {
  animations: 'all' ,
  config: {
    notify: { /* Notify defaults */ },
    loading: { /* Loading defaults */ },
    // loadingBar: {
    //   color: 'lima',
    // },
  },
  build:{
    rtl: true,

    extendWebpack(cfg) {

      // cfg.module.rules.push({
      //   enforce: "pre",
      //   test: /\.(js|vue)$/,
      //   exclude: /(node_modules|quasar)/
      // });
      // cfg.plugins.push(
      //   new SitemapPlugin({
      //     base: 'https://web.drugdealapp.com',
      //     paths,
      //     options: {
      //       filename: 'sitemap.xml',
      //       lastmod: true,
      //       changefreq: 'weekly',
      //     }
      //   })
      // ) 
      cfg.resolve.alias = {
        ...cfg.resolve.alias, // This adds the existing alias
        // Add your own alias like this
        utils: path.resolve(__dirname, "./src/utils"),
      };
}

    
  },
  components: {
    QImg,
    QIcon,
    QLayout,
    QHeader,
    QFooter,
    QTab,
    QTabs,
    QRouteTab,
    QDialog,
    QSpace,
    QInput,
    QDate,
    QPopupProxy,
    QTime,
    QBanner,
    QTabPanels,
    QTabPanel,
    QPageContainer,
    QPage,
    QTable,
    QTh,
    QTr,
    QTd,
    QOptionGroup,
    QAvatar,
    QBreadcrumbs,
    QBreadcrumbsEl,
    QUploader,
    QFile,
    QSelect,
    QDrawer,
    QResponsive,
    // QTransition,
    QSpinner,
    QBtn,
    // QScrollObservable,
    QScrollObserver,
  },
  // components: [
  //   'QLayout',
  //   'QHeader',
  //   'QFooter',
  //   'QTab',
  //   'QTabs',
  //   'QRouteTab',
  //   'QDialog',
  //   'QSpace',
  //   'QInput',
  //   'QDate',
  //   'QPopupProxy',
  //   'QTime',
  //   'QBanner',
  //   'QTabPanels',
  //   'QTabPanel',
  //   'QPageContainer',
  //   'QPage',
  //   'QTable',
  //   'QTh',
  //   'QTr',
  //   'QTd',
  //   'QIcon',
  //   'QOptionGroup',
  //   'QAvatar',
  //   'QBreadcrumbs',
  //   'QBreadcrumbsEl',
  //   'QUploader',
  //   'QFile',
  //   'QImg',
  //   'QSelect',
  //   'QPageContainer',
  //   'QPage',
  //   'QDrawer',
  //   'QResponsive',
  //   'vueFlip',
  //   'QTransition',
  //   'QSpinner'
  // ],
  directives: { /* not needed if importStrategy is not 'manual' */ },
  plugins: {
    Meta,
    Loading,
    // LoadingBar,
    // Dialog,
    Notify,
    // LocalStorage,
    // SessionStorage,
  },
  
  framework: {
    iconSet: 'fontawesome-v5',
    // components: ['QScrollObservable'],
    config:{
      screen: {
        bodyClasses: true // <<< add this
      }
    },
    screen: {
      bodyClasses: true // <<< add this
    }
  },
  iconSet: iconSet
 })