import facilitiesFormStore from '@/modules/facilities/facilities-form-store';
import facilitiesListStore from '@/modules/facilities/facilities-list-store';
import facilitiesViewStore from '@/modules/facilities/facilities-view-store';

export default {
    namespaced: true,
  
    modules: {
      form: facilitiesFormStore,
      list: facilitiesListStore,
      view: facilitiesViewStore
    },
  };
  