import { DrugIndexService } from '@/modules/drug-index/drugIndex-service';
// import customerListExporterFields from '@/modules/customer/customer-list-exporter-fields';
import Errors from '@/shared/error/errors';
// import Exporter from '@/shared/exporter/exporter';
const algoliasearch = require('algoliasearch')

// import { createNullCache } from '@algolia/cache-common';
import { createInMemoryCache } from '@algolia/cache-in-memory';


const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    algoliaInitLoading: false,
    filter: {},
    pagination: {},
    sorter: {},
    limit: {},
    table: null,
    drugs:[],
    searchFilter:'',
    mountedTable: false,


       // algolia keys
    client: null,
    index: null,
    ALGOLIA_APP_ID:'B2AOKDY1OP',
    // ALGOLIA_API_KEY:'622ac2eb5a11ec382b46996b96f59469',    
    ALGOLIA_API_KEY:'a8fb78c339cee6569ddf7ead3389c9ce',// admin key
    searchQuery:'',
    algoliaDrugs:null

  },

  getters: {
    loading: (state) => state.loading,

    algoliaInitLoading: (state) => state.algoliaInitLoading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,
    limit: (state) => state.limit,
    mountedTable: (state) => state.mountedTable,
    // limit: (state) => {
    //   const pagination = state.pagination;

    //   if (!pagination || !pagination.pageSize) {
    //     return INITIAL_PAGE_SIZE;
    //   }

    //   return pagination.pageSize;
    // },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
    drugs: (state) => state.drugs,
    searchFilter: (state)=> state.searchFilter,
    index: (state) => state.index
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    // FETCH_STARTED(state, payload) {
      
    FETCH_STARTED(state) {
      state.loading = true;

    //   if (state.table) {
    //     state.table.clearSelection();
    //   }

    //   state.filter =
    //     payload && payload.filter ? payload.filter : {};
    //   state.pagination =
    //     payload && payload.keepPagination
    //       ? state.pagination
    //       : {};
    },
    SET_MOUNTEDTABLE(state,payload){
      state.mountedTable = payload
    },
    APPEND_TO_DRUGS(state,payload){
      if(payload.docType == "added"){
        state.drugs.push(payload.doc)
      }
      if(payload.docType == "removed"){
        state.drugs = state.drugs.filter((el) => el.drugName.toString().toLowerCase() !== payload.doc.drugName.toLowerCase())
      }
      if(payload.docType == "modified"){
        var foundIndex = state.drugs.findIndex((el) => el.drugName.toString().toLowerCase() == payload.doc.drugName.toLowerCase());
        Object.assign(state.drugs[foundIndex], payload.doc)
      }
    },



    // APPEND_TO_DRUGS(state,payload){
    //   // state.drugs = state.drugs.filter(I => I.id !== payload.id)
    //   state.drugs.push(payload)
    // },
    FETCH_SUCCESS(state) {
      state.loading = false;
      // state.rows = payload.rows;
      // state.count = payload.count;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    SET_LIMIT(state, payload) {
        state.limit = payload
    },
    SET_SEARCH(state,payload){
      state.searchFilter = payload
      // value.company.toString().toLowerCase().includes(this.search.toLowerCase())
      state.drugs = state.drugs.filter((el) => el.drugName.toString().toLowerCase().includes(payload.toLowerCase()))
    
    },
    INIT_ALGOLIA_CLIENT(state){
      state.client = algoliasearch(state.ALGOLIA_APP_ID, state.ALGOLIA_API_KEY,
        {protocol: 'https'},
        {
          // Caches responses from Algolia
          responsesCache: createInMemoryCache(), // or createNullCache()
      
          // Caches Promises with the same request payload
          requestsCache: createInMemoryCache({ serializable: false }), // or createNullCache()
        },)
      // state.algoliaInitLoading = true
      // debugger
      state.index = state.client.initIndex('drugIndex')
      // state.algoliaInitLoading = false
      // debugger


      // state.index.setSettings({
      //   // paginationLimitedTo: 1000,
      // //   searchableAttributes: [
      // //   'drugName',
      // //   'oldPrice',
      // //   'price',
      // //   'company',
      // //   'genericName',
      // //   'route',
      // //   'srde',
      // //   'mechOfAction',
      // //   'pharmacologicalUse'

      // // ]
      // });

      // state.index.setSettings({
      //   // ranking: [
      //   //   "asc(drugName)",
      //   // ]
      //   customRanking: [
      //     'asc(drugName)'
      //   ],
      //   ranking: [
      //     'custom',
      //     'typo',
      //     'geo',
      //     'words',
      //     'filters',
      //     'proximity',
      //     'attribute',
      //     'exact'
      //   ]
      // })

      

      





      // state.index.search({
      //   // query: state.searchQuery
      //   query:'TRYPTIZOL'
      // }).then((data) => {
      //   state.algoliaDrugs = data.hits
      // })

      // let hits = [];

      // state.index.browseObjects({
      //   // query: '', // Empty query will match all records
      //   // filters: 'i<42',
      //   batch: batch => {
      //     hits = hits.concat(batch);
      //   }
      // }).then(() => console.log('browse all hits',hits));
      }
  },

  actions: {
    setSearch( { commit },value){
      commit('SET_SEARCH',value)
    },
    setMountedTable({commit},value){
      commit('SET_MOUNTEDTABLE',value)
    },
   
    async doFetch(
      { commit },
      { filter, keepPagination } = {},
    ) {
      try {
        commit('FETCH_STARTED', { filter,  keepPagination });
         await DrugIndexService.list((doc,docType) => {
          commit('APPEND_TO_DRUGS',{doc,docType})
          commit('FETCH_SUCCESS')
        })
        // const response =
        // commit('FETCH_SUCCESS', {
        //   rows: response.rows,
        //   count: response.count,
        // });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
    algoliaSearch({commit}){
      commit('INIT_ALGOLIA_CLIENT')
    }
    // algoliaSearch({commit},payload){
    //   commit('ALGOLIA_SEARCH')
    // }
  },
};