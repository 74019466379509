import Error403Page from '@/modules/layout/components/error-403-page.vue';
import Error404Page from '@/modules/layout/components/error-404-page.vue';
import Error500Page from '@/modules/layout/components/error-500-page.vue';
import mail from '@/modules/layout/components/mail.vue';

export default [
  {
    name: 'mail',
    path: '/:lang?/mail',
    component: mail,
  },
  {
    name: 'error403',
    path: '/:lang?/403',
    component: Error403Page,
  },
  {
    name: 'error404',
    path: '/:lang?/404',
    component: Error404Page,
  },
  {
    name: 'error500',
    path: '/:lang?/500',
    component: Error500Page,
  },
];
