
export default {
    name:'app-form-error-modal',
    props:['visible','description'],
    computed:{
      dialogVisible:{
            get(){
                // setTimeout(() => {
                return this.visible
                // },500)                
            },
            set(){
                this.$emit('closeErrorModal')
            }
        },
    }

}
