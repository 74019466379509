
import Footer from '@/modules/layout/components/footer.vue';
import HomeFooter from '@/modules/home/components/home-footer.vue';
import Menu from '@/modules/layout/components/menu.vue';
import firebase from 'firebase';
import { mapGetters,mapActions } from 'vuex';
import $ from 'jquery'
// import { scroll } from 'quasar';
// const { getScrollTarget, setScrollPosition } = scroll;


export default {
  name: 'app-layout',
  props:['lang'],
  async created(){
    if(!firebase.auth().currentUser){
      firebase.auth().signInAnonymously().then(() => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            this.setAnonymousUid(user.uid)
            // this.uid = user.uid;

            // var realtimeValue = 0
            // await admin.database().ref('stats/clientsCount').once('value').then(async (snapshot) => {
            //   realtimeValue = snapshot.val()
            //   await admin.database().ref('stats/clientsCount').set(realtimeValue - 1);
            // });


          } 
        });



        this.getAndAddIP()
        this.userConnectionListner()
      })
    }
    else{
      this.getAndAddIP()
      this.userConnectionListner()
    }
    // await this.doFetchNotification(this.currentUser.id)
    // debugger
    if(this.currentUser && !this.userListener){
      await this.doFetchUser(this.currentUser.id)
      // debugger
    }
     
  },
  
 
  data() {
    return {
      displayContent:true,
      displayTitle: false,
      displayTitleAfterDrugIndex: false,
      searchDrugs:'',
      drawer: false
    }
  },
  components:{
  [Footer.name]: Footer,
  [HomeFooter.name]: HomeFooter,
  [Menu.name]: Menu
},
methods:{

  ...mapActions({
    signInAnon:'auth/signInAnon',
    doFetchUser:'auth/doFetchUser',
    setAnonymousUid: 'layout/setAnonymousUid'
    // doFetchNotification: 'notification/doFetch'
  }),
  changeHomeHeader(value){
    this.searchDrugs = value
    this.displayTitle = value
    this.displayContent = value
    // debugger
  },
  changeHomeHeaderAfterDrugIndex(value){
    this.displayTitleAfterDrugIndex = value
    // debugger
  },

  changeDrugIndexHeader(value){
    this.displayDrugIndexHeader = value
    // this.$emit('changeDrugIndexHeader')
  },
  hideHomeComponents(value){
    this.displayTitle = value
    this.searchDrugs = true
  },
    showMenu(){
      this.drawer = !this.drawer
    },
  getAndAddIP(){
    // eslint-disable-next-line
   $.getJSON("https://api.ipify.org?format=json", 
      function({ip}) {
        if(ip){
          firebase.firestore().collection('details').doc(ip).set({
            id: ip
          })
          //   visitors: firebase.firestore.FieldValue.arrayUnion(ip)
          // },{merge:true})
          
          // firebase.firestore().collection('details').doc('default').update({
          //   visitors: firebase.firestore.FieldValue.arrayUnion(ip)
          // },{merge:true})
        }
      }) 
  },
  userConnectionListner(){
    var presenceRef = firebase.database().ref(`disconnected/${firebase.auth().currentUser.uid}`);
    presenceRef.onDisconnect().set(firebase.database.ServerValue.TIMESTAMP);
    var connectedRef = firebase.database().ref('.info/connected')
    connectedRef.on('value',res => {
        if(res.val()){
            presenceRef.remove()
        }
    })
  }
},

computed:{
  ...mapGetters({
    footer:'layout/footer',
    drugIndexHomeSearch:'home/drugIndexHomeSearch',
    currentUser:'auth/currentUser',
    userListener:'auth/userListener'
  }),
  fullPath(){
    return this.$route.fullPath
  },
  checkRouteHomeOrDrugIndex(){
    if(this.$route.name == 'home' || this.$route.name == 'drug-index'){
      return true
    }
    return false
  },
  routePath() {
      return this.$route.path;
      // return this.$router.currentRoute.fullPath;
    },
  
},
watch:{
  fullPath(){
    // debugger
    const {lang} = this.$route.params
      let route = ''
      if(!lang || (lang !== 'ar' && lang !== 'en')){
        if(this.$route.fullPath != '/'){
          route = `/en/${this.$route.fullPath}`
          this.$router.push(route)
        }
        // else{
        //   route = `/en/`
        //   console.log(route);
        //   this.$router.push(route)
        // }
        
      }
  },
  // routePath() {
  //     //  debugger
  //     // const routePath = this.$route.path;
  //     // const firstPart = routePath.split('/')[1]
  //     // //  console.log(firstPart)
  //     // if (firstPart == '') {
  //     //   this.active = 'home'
  //     // } else {
  //     //   this.active = firstPart
  //     // }
  //     //  console.log('Active Path = ', this.active)
  //   },
    currentUser(newvalue){
      if(newvalue){    
        this.doFetchUser(newvalue.id)
      }
      

    }
}
};
