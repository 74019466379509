import Layout from '@/modules/layout/components/layout';
const AboutPage = () =>
  import('@/modules/about/components/about-page.vue');

  export default [
    {
      // path: '',
      path: '/:lang?',
      exact: true,
      component: Layout,
      children: [
        {
          name: 'about',
          path: 'about',
          component: AboutPage,
        //   exact: true,
          // meta: { unauth: true },
        },
    ]
}
  ]