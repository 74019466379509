import { LayoutService } from '@/modules/layout/layout-service';

export default {
  namespaced: true,

  state: {
    tab: 'home',
    menuCollapsed: false,
    isMobile: false,
    isScreen_md: false,
    // isScreen_sm: false,
    is_screen_sm: false,
    is_screen_xs: false,
    is_screen_md: false,
    is_screen_lg: false,
    is_screen_galaxy_fold: false,
    headerBg:'',
    navbar:'',
    footer:false,
    anonymousUid: null,
    loadingAnonymous: false
  },

  getters: {
    tab: (state) => state.tab,
    menuCollapsed: (state) => state.menuCollapsed,
    isMobile: (state) => !!state.isMobile,
    isScreen_md: (state) => !!state.isScreen_md,
    is_screen_sm: (state) => !!state.is_screen_sm,
    is_screen_xs: (state) => !!state.is_screen_xs,
    is_screen_md: (state) => !!state.is_screen_md,
    is_screen_lg: (state) => !!state.is_screen_lg,
    is_screen_galaxy_fold: (state) => !!state.is_screen_galaxy_fold,
    
    paginationLayout: (state) =>
      state.isMobile
        ? 'total, prev, pager, next'
        : 'total, sizes, prev, pager, next',
    labelPosition: (state) =>
      state.isMobile ? 'top' : undefined,
    labelWidthForm: (state) =>
      state.isMobile ? undefined : '180px',
    labelWidthFilter: (state) =>
      state.isMobile ? undefined : '120px',
    headerBg:(state) => state.headerBg,
    navbar:(state) => state.navbar,
    footer:(state) => state.footer,
    anonymousUid: (state) => state.anonymousUid
  },

  mutations: {
    COLLAPSE_MENU(state) {
      state.menuCollapsed = true;
    },

    TOGGLE_MENU(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },

    RESIZE(state, payload) {
      state.isMobile = payload.width < 576;
      state.isScreen_md = payload.width < 1024;
      state.is_screen_xs = payload.width < 600;
      state.is_screen_galaxy_fold = payload.width < 300;
      state.is_screen_sm = payload.width >= 600 && payload.width < 1024;
      state.is_screen_md = payload.width >= 1024 && payload.width < 1440;
      state.is_screen_lg = payload.width >= 1440
    },
    TOGGLE_TAP(state, payload) {
      state.tab = payload
    },
    SET_HEADERBG(state,payload) {
      state.headerBg = payload
    },
    SET_NAVBAR(state,payload) {
      state.navbar = payload
    },
    SET_FOOTER(state,payload) {
      state.footer = payload
    },
    SET_ANONYMOUS_UID(state, payload){
      state.anonymousUid = payload
    },
    DELETE_ANONYMOUS_STARTED(state){
      state.loadingAnonymous = true
    },
    DELETE_ANONYMOUS_SUCCESS(state){
      state.loadingAnonymous = false
    }
  },

  actions: {
    resize({ commit }, payload) {
      commit('RESIZE', payload);
    },

    toggleMenu({ commit }) {
      commit('TOGGLE_MENU');
    },

    collapseMenu({ commit }) {
      commit('COLLAPSE_MENU');
    },

    navigation({ commit }, payload) {
      commit('TOGGLE_TAP', payload);
    },
    setHeaderBg({ commit },payload) {
      commit('SET_HEADERBG',payload)
    },
    setNavbar({ commit },payload) {
      commit('SET_NAVBAR',payload)
    },
    setFooter({commit},payload){
      commit('SET_FOOTER',payload)
    },
    setAnonymousUid({commit}, payload){
      commit('SET_ANONYMOUS_UID', payload)
    },
    async deleteUserFromAuthTable({commit, getters}){
      commit('DELETE_ANONYMOUS_STARTED')
      await LayoutService.deleteUserFromAuthTable(getters.anonymousUid)
      commit('DELETE_ANONYMOUS_SUCCESS')

    }
  },
};
