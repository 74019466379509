import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/i18n';

const DrugIndexListPage = () =>
  import('@/modules/drug-index/components/drug-index-list-page.vue');
export default [
    {
      path: '/:lang?',
      exact: true,
      component: Layout,
      children: [
        {
            name: 'drugIndex',
            path: 'drug-index/:id?',
            component: DrugIndexListPage,
        },
      ],
      beforeEnter: (to, from, next) => {
        document.title =
          i18n('app.title') +
          ' | ' +
          i18n('drugDeal.services.drugIndex') 
        next();
      },
    }
]