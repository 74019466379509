import { FacilitiesService } from "@/modules/facilities/facilities-service";
// import productListExporterFields from '@/modules/product/product-list-exporter-fields';
import Errors from "@/shared/error/errors";
import { firestoreAction, vuexfireMutations } from "vuexfire";
import firebase from "firebase/app";

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    //#region [vuexfire]
    rows: [],
    rowsFromRoute: [],
    users: [],
    rowsCreator: [],
    addedRowId: null,
    addedCreatorId: null,
    loadMoreLastRecord: null,
    loadFirstRecord: null,
    recordAfterLast: [],
    recordBeforeFirst: [],
    //#endregion

    count: 0,
    loading: false,
    detailsLoading: false,
    userLoading: false,
    filter: {},
    pagination: {},
    sorter: {},
    facilities: [],
    facilityListedFor: [],
    deactivationReasons: [],
    facilityTypes: [],
    jobPositions: [],
    cities: [],
    // regions:[],
    regions: {},
    currentUserFacilities: [],
    facilitiesWithoutFilter: [],
    user: {},
    currentRoute: undefined,
    table: null,
    mountedTable: false,
    mountedCityTable: false,
    mountedPositionTable: false,
    mountedFacilityListedfForTable: false,
    mountedDeactivationReasonTable: false,
    mountedFacilityTypeTable: false,
    loadingAddMoreFacilities: true,
  },
  getters: {
    loading: (state) => state.loading,
    detailsLoading: (state) => state.detailsLoading,
    userLoading: (state) => state.userLoading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],
    rowsFromRoute: (state) => state.rowsFromRoute || [],
    users: (state) => state.users,
    rowsCreator: (state) => state.rowsCreator,
    addedRowId: (state) => state.addedRowId,
    addedCreatorId: (state) => state.addedCreatorId,
    loadMoreLastRecord: (state) => state.loadMoreLastRecord,
    loadFirstRecord: (state) => state.loadFirstRecord,
    recordAfterLast: (state) => state.recordAfterLast,
    recordBeforeFirst: (state) => state.recordBeforeFirst,

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction = sorter.order === "descending" ? "DESC" : "ASC";

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
    facilities: (state) => {
      return state.facilities.sort((a, b) => {
        var keyA = new Date(a.publishedAt.seconds),
          keyB = new Date(b.publishedAt.seconds);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      // state.prescriptions
    },
    facilitiesWithoutFilter: (state) => state.facilitiesWithoutFilter,
    currentUserFacilities: (state) => state.currentUserFacilities,
    facilityListedFor: (state) => state.facilityListedFor,
    deactivationReasons: (state) => state.deactivationReasons,
    facilityTypes: (state) => state.facilityTypes,
    jobPositions: (state) => state.jobPositions,
    cities: (state) => state.cities,
    regions: (state) => state.regions,
    mountedTable: (state) => state.mountedTable,
    mountedCityTable: (state) => state.mountedCityTable,
    mountedPositionTable: (state) => state.mountedPositionTable,
    mountedFacilityListedfForTable: (state) =>
      state.mountedFacilityListedfForTable,
    mountedDeactivationReasonTable: (state) =>
      state.mountedDeactivationReasonTable,
    mountedFacilityTypeTable: (state) => state.mountedFacilityTypeTable,
    loadingAddMoreFacilities: (state) => state.loadingAddMoreFacilities,

    user: (state) => state.user,

    currentRoute: (state) => state.currentRoute,
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },
    // TABLE_CITY_MOUNTED(state, payload) {
    //   state.table = payload;
    // },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },
    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_SUCCESS(state) {
      state.loading = false;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    APPEND_TO_CURRENT_USER_FACILITIES(state, payload) {
      if (payload.docType == "added") {
        if (
          payload.doc.postStatus !== "deleted" &&
          payload.doc.postStatus !== "pending"
        ) {
          state.currentUserFacilities.push(payload.doc);
        }
      }
      if (payload.docType == "removed") {
        state.currentUserFacilities = state.currentUserFacilities.filter(
          (el) => el.id !== payload.doc.id
        );
      }
      if (payload.docType == "modified") {
        if (
          payload.doc.postStatus == "deleted" ||
          payload.doc.postStatus == "pending"
        ) {
          state.currentUserFacilities = state.currentUserFacilities.filter(
            (el) => el.id !== payload.doc.id
          );
        } else {
          var foundIndex = state.currentUserFacilities.findIndex(
            (el) => el.id == payload.doc.id
          );
          if (foundIndex != -1) {
            Object.assign(state.currentUserFacilities[foundIndex], payload.doc);
          } else {
            state.currentUserFacilities.push(payload.doc);
          }
        }
      }
    },
    APPEND_TO_FACILITIES_WITHOUT_FILTER(state, payload) {
      // console.log('payload in  prescriptions',payload)
      if (payload.docType == "added") {
        if (
          payload.doc.postStatus !== "deleted" &&
          payload.doc.postStatus !== "pending"
        ) {
          state.facilitiesWithoutFilter.push(payload.doc);
        }
      }
      if (payload.docType == "removed") {
        state.facilitiesWithoutFilter = state.facilitiesWithoutFilter.filter(
          (el) => el.id !== payload.doc.id
        );
      }
      if (payload.docType == "modified") {
        // console.log('modified',payload.doc.postStatus);

        if (
          payload.doc.postStatus == "deleted" ||
          payload.doc.postStatus == "pending"
        ) {
          state.facilitiesWithoutFilter = state.facilitiesWithoutFilter.filter(
            (el) => el.id !== payload.doc.id
          );
        } else {
          var foundIndex = state.facilitiesWithoutFilter.findIndex(
            (el) => el.id == payload.doc.id
          );
          // Object.assign(state.jobs[foundIndex], payload.doc)
          if (foundIndex != -1) {
            Object.assign(
              state.facilitiesWithoutFilter[foundIndex],
              payload.doc
            );
          } else {
            state.facilitiesWithoutFilter.push(payload.doc);
          }
        }
      }
    },
    APPEND_TO_FACILITIES(state, payload) {
      // console.log('payload in  prescriptions',payload)
      if (payload.docType == "added") {
        if (
          payload.doc.postStatus !== "deleted" &&
          payload.doc.postStatus !== "pending"
        ) {
          state.facilities.push(payload.doc);
        }
      }
      if (payload.docType == "removed") {
        state.facilities = state.facilities.filter(
          (el) => el.id !== payload.doc.id
        );
      }
      if (payload.docType == "modified") {
        // console.log('modified',payload.doc.postStatus);

        if (
          payload.doc.postStatus == "deleted" ||
          payload.doc.postStatus == "pending"
        ) {
          state.facilities = state.facilities.filter(
            (el) => el.id !== payload.doc.id
          );
        } else {
          var foundIndex = state.facilities.findIndex(
            (el) => el.id == payload.doc.id
          );
          // Object.assign(state.jobs[foundIndex], payload.doc)
          if (foundIndex != -1) {
            Object.assign(state.facilities[foundIndex], payload.doc);
          } else {
            state.facilities.push(payload.doc);
          }
        }
      }
    },
    SET_FACILITIES(state, payload) {
      state.facilities = payload;
    },
    APPEND_TO_FACILITYLISTEDFOR(state, payload) {
      state.facilityListedFor.push(payload);
    },
    APPEND_TO_FACILITYTYPES(state, payload) {
      state.facilityTypes.push(payload);
    },
    APPEND_TO_CITIES(state, payload) {
      state.cities.push(payload);
    },
    APPEND_TO_REGIONS(state, payload) {
      state.regions[payload.cityId] = payload.regions;
    },
    SET_MOUNTEDTABLE(state, payload) {
      state.mountedTable = payload;
    },
    SET_MOUNTEDCITYTABLE(state, payload) {
      state.mountedCityTable = payload;
    },
    SET_MOUNTEDPOSITIONTABLE(state, payload) {
      state.mountedPositionTable = payload;
    },
    SET_FACILITYLISTEDFORTABLE(state, payload) {
      state.mountedFacilityListedfForTable = payload;
      // console.log('mountedFacilityListedForTable',state.mountedFacilityListedForTable);
    },
    SET_MOUNTEDDEACTIVATIONREASON(state, payload) {
      state.mountedDeactivationReasonTable = payload;
    },
    APPEND_TO_DEACTIVATIONREASONS(state, payload) {
      state.deactivationReasons.push(payload);
    },
    SET_FACILITYTYPETABLE(state, payload) {
      state.mountedFacilityTypeTable = payload;
    },
    FETCH_USER_STARTED(state) {
      state.userLoading = true;
    },
    FETCH_USER_SUCCESS(state, user) {
      state.user = user;
      state.userLoading = false;
    },
    FETCH_USER_ERROR(state) {
      state.userLoading = false;
    },
    SET_CURRENTROUTE(state, payload) {
      state.currentRoute = payload;
    },

    //#region [ VuexfireMutations ]
    ...vuexfireMutations,
    SET_ADDED_ROW_ID(state, payload) {
      state.addedRowId = payload;
    },
    SET_ADDED_CREATOR_ID(state, payload) {
      state.addedCreatorId = payload;
    },
    async SET_CREATOR_TO_ROW(state) {
      const creator = await firebase
        .firestore()
        .collection("user")
        .doc(state.addedCreatorId)
        .get();
      state.rowsCreator.push(creator.data());
      const addedRow = state.rows.find((el) => el.id == state.addedRowId);
      addedRow["creator"] = creator.data();
    },
    RESET_CURSOR(state) {
      state.loadMoreLastRecord = null;
      state.loadFirstRecord = null;
    },
    SET_LOADMORE_LAST(state) {
      state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc;
      state.recordAfterLast = state.rows[state.rows.length - 1];
    },
    SET_LOADFIRSTRECORD(state) {
      state.loadFirstRecord = state.rows[0]._doc;
    },
    SET_ROWS(state, payload) {
      state.rows = payload;
    },
    SET_LOADING_ADD_MORE_FACILITIES(state, payload) {
      state.loadingAddMoreFacilities = payload;
    },
    DETAILS_LOADING_STARTED(state) {
      state.detailsLoading = true;
      // debugger
      // console.log('getters.detailsLoading',getters.detailsLoading);
      // debugger
    },
    DETAILS_LOADING_SUCCESS(state) {
      state.detailsLoading = false;
      // debugger
      // console.log('getters.detailsLoading',getters.detailsLoading);

      // debugger
    },
    //#endregion
  },

  actions: {
    doUnselectAll({ commit }) {
      commit("UNSELECT_ALL");
    },

    doMountTable({ commit }, table) {
      commit("TABLE_MOUNTED", table);
    },

    async doReset({ commit, dispatch }) {
      commit("RESETED");
      return dispatch("doFetch");
    },

    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit("PAGINATION_CHANGED", pagination);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit("PAGINATION_PAGE_SIZE_CHANGED", pageSize);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit("PAGINATION_CURRENT_PAGE_CHANGED", currentPage);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit("SORTER_CHANGED", sorter);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },
    setMountedTable({ commit }, value) {
      commit("SET_MOUNTEDTABLE", value);
    },
    setMountedCityTable({ commit }, value) {
      commit("SET_MOUNTEDCITYTABLE", value);
    },
    setMountedPositionTable({ commit }, value) {
      commit("SET_MOUNTEDPOSITIONTABLE", value);
    },
    setMountedFacilityListedForTable({ commit }, value) {
      // console.log('setMountedFacilityListedForTable',value);
      commit("SET_FACILITYLISTEDFORTABLE", value);
    },
    setMountedDeactivationReasonTable({ commit }, value) {
      commit("SET_MOUNTEDDEACTIVATIONREASON", value);
    },
    setMountedFacilityTypeTable({ commit }, value) {
      commit("SET_FACILITYTYPETABLE", value);
    },
    setLoadingAddMoreFacilities({ commit }, value) {
      commit("SET_LOADING_ADD_MORE_FACILITIES", value);
    },
    ////#region [vuexfire]
    doFetch: firestoreAction(
      async ({ bindFirestoreRef, getters, commit, dispatch }, payload) => {
        commit("FETCH_STARTED");
        if (!getters.mountedFacilityListedfForTable) {
          await dispatch("doFetchFacilityListedFor");
          commit("SET_FACILITYLISTEDFORTABLE", true);
        }
        const customSerializer = (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          const listedFor = getters.facilityListedFor.find(
            (element) => element.id == data.listedForId
          );
          data["listedFor"] = listedFor;
          data["creator"] = "";

          if (payload.currentRoute == "my-facilities") {
            data["creator"] = payload.currentUser;
          } else {
            if (getters.rowsCreator.length > 0) {
              var creatorIndex = getters.rowsCreator.findIndex(
                (el) => el.id == data.createdBy
              );
              if (creatorIndex != -1) {
                let creator = getters.rowsCreator.find(
                  (el) => el.id == data.createdBy
                );
                data["creator"] = creator;
              }
            }
            var foundIndex = getters.users.findIndex(
              (el) => el == data.createdBy
            );
            if (foundIndex == -1) {
              getters.users.push(data.createdBy);
              if (getters.mountedTable) {
                commit("SET_ADDED_ROW_ID", data.id);
                commit("SET_ADDED_CREATOR_ID", data.createdBy);
              }
            }
          }
          // console.log('data',data._doc);
          return data;
        };
        const db = firebase.firestore();
        var propertyCollection = null;
        if (payload.currentRoute != "my-facilities") {
          if (payload.searchBy) {
            switch (payload.searchBy) {
              case "listedFor":
                propertyCollection = getters.loadMoreLastRecord
                  ? db
                      .collection("property")
                      .limit(payload.limit || 10)
                      .orderBy("publishedAt", "desc")
                      .where("listedForId", "==", payload.filter)
                      .where("postStatus", "in", ["accepted", "deleted"])
                      .where("isActive", "==", true)
                      .startAfter(getters.loadMoreLastRecord)
                  : db
                      .collection("property")
                      .orderBy("publishedAt", "desc")
                      .where("listedForId", "==", payload.filter)
                      .where("postStatus", "in", ["accepted", "deleted"])
                      .where("isActive", "==", true)
                      .limit(payload.limit || 10);
                break;
              case "type":
                propertyCollection = getters.loadMoreLastRecord
                  ? db
                      .collection("property")
                      .limit(payload.limit || 10)
                      .orderBy("publishedAt", "desc")
                      .where("typeId", "==", payload.filter)
                      .where("postStatus", "in", ["accepted", "deleted"])
                      .where("isActive", "==", true)
                      .startAfter(getters.loadMoreLastRecord)
                  : db
                      .collection("property")
                      .orderBy("publishedAt", "desc")
                      .where("typeId", "==", payload.filter)
                      .where("postStatus", "in", ["accepted", "deleted"])
                      .where("isActive", "==", true)
                      .limit(payload.limit || 10);
                break;
              case "city":
                propertyCollection = getters.loadMoreLastRecord
                  ? db
                      .collection("property")
                      .orderBy("publishedAt", "desc")
                      .where("cityId", "==", payload.filter)
                      .where("postStatus", "in", ["accepted", "deleted"])
                      .where("isActive", "==", true)
                      .startAfter(getters.loadMoreLastRecord)
                      .limit(payload.limit || 10)
                  : db
                      .collection("property")
                      .orderBy("publishedAt", "desc")
                      .where("cityId", "==", payload.filter)
                      .where("postStatus", "in", ["accepted", "deleted"])
                      .where("isActive", "==", true)
                      .limit(payload.limit || 10);
                break;
            }
          } else {
            // debugger
            propertyCollection = getters.loadMoreLastRecord
              ? db
                  .collection("property")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .startAfter(getters.loadMoreLastRecord)
              : db
                  .collection("property")
                  .orderBy("publishedAt", "desc")
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .limit(payload.limit || 10);
          }
        } else {
          propertyCollection = getters.loadMoreLastRecord
            ? db
                .collection("property")
                .limit(payload.limit || 10)
                .orderBy("publishedAt", "desc")
                .where("createdBy", "==", payload.currentUser.id)
                .where("postStatus", "in", ["accepted", "pending"])
                .startAfter(getters.loadMoreLastRecord)
            : db
                .collection("property")
                .orderBy("publishedAt", "desc")
                .where("createdBy", "==", payload.currentUser.id)
                .where("postStatus", "in", ["accepted", "pending"])
                .limit(payload.limit || 10);
        }
        await bindFirestoreRef("rows", propertyCollection, {
          serialize: customSerializer,
        });
        if (payload.currentRoute != "my-facilities") {
          if (getters.users.length > 0) {
            const userCollection = db
              .collection("user")
              .where("id", "in", getters.users);
            await bindFirestoreRef(`rowsCreator`, userCollection);
          }
          getters.rows.forEach(async (row) => {
            let creator = getters.rowsCreator.find(
              (el) => el.id == row.createdBy
            );
            row["creator"] = creator;
          });
        }
        commit("FETCH_SUCCESS");
      }
    ),

    doFetchFromRoute: firestoreAction(
      async ({ bindFirestoreRef, commit, getters, dispatch }, payload) => {
        commit("FETCH_STARTED");
        if (!getters.mountedFacilityListedfForTable) {
          await dispatch("doFetchFacilityListedFor");
          commit("SET_FACILITYLISTEDFORTABLE", true);
        }
        const customSerializer = (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          const listedFor = getters.facilityListedFor.find(
            (element) => element.id == data.listedForId
          );
          data["listedFor"] = listedFor;
          data["creator"] = "";
          if (payload.currentRoute == "my-facilities") {
            data["creator"] = payload.currentUser;
          } else {
            if (getters.rowsCreator.length > 0) {
              var creatorIndex = getters.rowsCreator.findIndex(
                (el) => el.id == data.createdBy
              );
              if (creatorIndex != -1) {
                let creator = getters.rowsCreator.find(
                  (el) => el.id == data.createdBy
                );
                data["creator"] = creator;
              }
            }
            var foundIndex = getters.users.findIndex(
              (el) => el == data.createdBy
            );
            if (foundIndex == -1) {
              getters.users.push(data.createdBy);
              if (getters.mountedTable) {
                commit("SET_ADDED_ROW_ID", data.id);
                commit("SET_ADDED_CREATOR_ID", data.createdBy);
              }
            }
          }

          return data;
        };
        const db = firebase.firestore();
        var propertyCollection = null;
        const endAt = payload.page * payload.limit;
        if (payload.currentRoute == "my-facilities") {
          propertyCollection = db
            .collection("property")
            .where("postStatus", "in", ["accepted", "pending"])
            .where("createdBy", "==", payload.currentUser.id)
            .orderBy("publishedAt", "desc")
            .limit(endAt);
        } else {
          if (payload.searchBy) {
            switch (payload.searchBy) {
              case "listedFor":
                propertyCollection = db
                  .collection("property")
                  .orderBy("publishedAt", "desc")
                  .where("listedForId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .limit(endAt);
                break;
              case "type":
                propertyCollection = db
                  .collection("property")
                  .orderBy("publishedAt", "desc")
                  .where("typeId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .limit(endAt);
                break;
              case "city":
                propertyCollection = db
                  .collection("property")
                  .orderBy("publishedAt", "desc")
                  .where("cityId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .limit(endAt);
                break;
            }
          } else {
            propertyCollection = db
              .collection("property")
              .where("postStatus", "in", ["accepted", "deleted"])
              .where("isActive", "==", true)
              .orderBy("publishedAt", "desc")
              .limit(endAt);
          }
        }
        await bindFirestoreRef("rowsFromRoute", propertyCollection, {
          serialize: customSerializer,
        });
        if (payload.currentRoute != "my-facilities") {
          if (getters.users.length > 0) {
            const userCollection = db
              .collection("user")
              .where("id", "in", getters.users);
            await bindFirestoreRef(`rowsCreator`, userCollection);
          }
          getters.rowsFromRoute.forEach(async (row) => {
            let creator = getters.rowsCreator.find(
              (el) => el.id == row.createdBy
            );
            row["creator"] = creator;
          });
        }
        commit("FETCH_SUCCESS");
      }
    ),

    getPreviousRecordsBatch: firestoreAction(
      async ({ bindFirestoreRef, getters, commit }, payload) => {
        commit("FETCH_STARTED");
        const customSerializer = (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          data["creator"] = "";
          const listedFor = getters.facilityListedFor.find(
            (element) => element.id == data.listedForId
          );
          data["listedFor"] = listedFor;

          if (payload.currentRoute == "my-facilities") {
            data["creator"] = payload.currentUser;
          } else {
            if (getters.rowsCreator.length > 0) {
              var creatorIndex = getters.rowsCreator.findIndex(
                (el) => el.id == data.createdBy
              );
              if (creatorIndex != -1) {
                let creator = getters.rowsCreator.find(
                  (el) => el.id == data.createdBy
                );
                data["creator"] = creator;
              }
            }
            var foundIndex = getters.users.findIndex(
              (el) => el == data.createdBy
            );
            if (foundIndex == -1) {
              getters.users.push(data.createdBy);
              if (getters.mountedTable) {
                commit("SET_ADDED_ROW_ID", data.id);
                commit("SET_ADDED_CREATOR_ID", data.createdBy);
              }
            }
          }

          return data;
        };
        const db = firebase.firestore();
        var propertyCollection = null;
        if (payload.currentRoute == "my-facilities") {
          propertyCollection = db
            .collection("property")
            .where("postStatus", "in", ["accepted", "pending"])
            .where("createdBy", "==", payload.currentUser.id)
            .orderBy("publishedAt", "desc")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(payload.limit || 9);
        } else {
          if (payload.searchBy) {
            switch (payload.searchBy) {
              case "listedFor":
                propertyCollection = db
                  .collection("property")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("listedForId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(payload.limit || 9);
                break;
              case "type":
                propertyCollection = db
                  .collection("property")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("typeId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(payload.limit || 9);
                break;
              case "city":
                propertyCollection = db
                  .collection("property")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("cityId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(payload.limit || 9);
                break;
            }
          } else {
            propertyCollection = db
              .collection("property")
              .where("postStatus", "in", ["accepted", "deleted"])
              .where("isActive", "==", true)
              .orderBy("publishedAt", "desc")
              .endBefore(getters.loadFirstRecord)
              .limitToLast(payload.limit || 9);
          }
        }
        await bindFirestoreRef("rows", propertyCollection, {
          serialize: customSerializer,
        });
        if (payload.currentRoute != "my-facilities") {
          if (getters.users.length > 0) {
            const userCollection = db
              .collection("user")
              .where("id", "in", getters.users);
            await bindFirestoreRef(`rowsCreator`, userCollection);
          }
          getters.rows.forEach(async (row) => {
            let creator = getters.rowsCreator.find(
              (el) => el.id == row.createdBy
            );
            row["creator"] = creator;
          });
        }
        commit("FETCH_SUCCESS");
      }
    ),

    setRows({ commit }, payload) {
      commit("FETCH_STARTED");
      commit("SET_ROWS", payload);
      commit("FETCH_SUCCESS");
    },
    setLoadMoreLast({ commit }) {
      commit("SET_LOADMORE_LAST");
    },
    resetCursor({ commit }) {
      commit("RESET_CURSOR");
    },
    setLoadFirstRecord({ commit }) {
      commit("SET_LOADFIRSTRECORD");
    },
    setCreatorToRow({ commit }) {
      commit("SET_CREATOR_TO_ROW");
    },

    async doFetchFacilityListedFor({ commit }) {
      commit("FETCH_STARTED");
      const db = firebase.firestore();
      const facilityListedForCollection = await db
        .collection("facilityListedFor")
        .orderBy("index", "asc")
        .get();
      facilityListedForCollection.docs.forEach((doc) => {
        commit("APPEND_TO_FACILITYLISTEDFOR", doc.data());
      });
      commit("FETCH_SUCCESS");
    },
    async doFetchFacilityType({ commit }) {
      commit("FETCH_STARTED");
      const db = firebase.firestore();
      const facilityTypeCollection = await db
        .collection("facilityType")
        .orderBy("index", "asc")
        .get();
      facilityTypeCollection.docs.forEach((doc) => {
        commit("APPEND_TO_FACILITYTYPES", doc.data());
      });
      commit("FETCH_SUCCESS");
    },
    async doFetchDeactivationReason({ commit }) {
      try {
        commit("FETCH_STARTED");
        const deactivationReasonCollection = await firebase
          .firestore()
          .collection("deactivationReason")
          .orderBy("index", "asc")
          .where("category", "==", "property")
          .get();
        deactivationReasonCollection.docs.forEach((doc) => {
          commit("APPEND_TO_DEACTIVATIONREASONS", doc.data());
        });
      } catch (error) {
        Errors.handle(error);
        commit("FETCH_ERROR");
      }
    },
    async doFetchCity({ commit }) {
      const citiesCollection = await firebase
        .firestore()
        .collection("city")
        .get();
      citiesCollection.docs.forEach(async (doc) => {
        let city = doc.data();
        city.id = doc.id;
        commit("APPEND_TO_CITIES", city);
        const regions = await firebase
          .firestore()
          .collection(`city/${city.id}/regions`)
          .get();
        let cityRegions = [];
        if (regions.docs.length > 0) {
          regions.docs.forEach((doc) => {
            let region = doc.data();
            region.id = doc.id;
            cityRegions.push(region);
          });
        }

        commit("APPEND_TO_REGIONS", { cityId: city.id, regions: cityRegions });
      });
      // try {
      //   commit('FETCH_STARTED')
      //   FacilitiesService.listCity((doc,docType) => {
      //     commit('APPEND_TO_CITIES',{doc,docType})
      //     commit('FETCH_SUCCESS')
      //   },(cityId,regions)=>{
      //     commit('APPEND_TO_REGIONS',{cityId,regions})
      //   })
      // } catch (error) {
      //   Errors.handle(error);
      //   commit('FETCH_ERROR');
      // }
    },

    setFacilityDetailsStarted({ commit }) {
      commit("DETAILS_LOADING_STARTED");
      // debugger
    },
    setFacilityDetailsSuccess({ commit }) {
      commit("DETAILS_LOADING_SUCCESS");
    },

    setDisableNext: firestoreAction(
      async ({ getters, bindFirestoreRef }, payload) => {
        const db = firebase.firestore();
        var propertyCollection = null;
        if (payload.currentRoute == "my-facilities") {
          propertyCollection = db
            .collection("property")
            .where("postStatus", "in", ["accepted", "pending"])
            .where("createdBy", "==", payload.currentUser.id)
            .orderBy("publishedAt", "desc")
            .startAfter(getters.loadMoreLastRecord)
            .limit(1);
        } else {
          if (payload.searchBy) {
            switch (payload.searchBy) {
              case "listedFor":
                propertyCollection = db
                  .collection("property")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("listedForId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .startAfter(getters.loadMoreLastRecord)
                  .limit(1);
                break;
              case "type":
                propertyCollection = db
                  .collection("property")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("typeId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .startAfter(getters.loadMoreLastRecord)
                  .limit(1);
                break;
              case "city":
                propertyCollection = db
                  .collection("property")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("cityId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .startAfter(getters.loadMoreLastRecord)
                  .limit(1);
                break;
            }
          } else {
            propertyCollection = db
              .collection("property")
              .where("postStatus", "in", ["accepted", "deleted"])
              .where("isActive", "==", true)
              .orderBy("publishedAt", "desc")
              .startAfter(getters.loadMoreLastRecord)
              .limit(1);
          }
        }
        await bindFirestoreRef("recordAfterLast", propertyCollection);
      }
    ),
    setDisablePrevious: firestoreAction(
      async ({ getters, bindFirestoreRef }, payload) => {
        const db = firebase.firestore();
        var propertyCollection = null;
        if (payload.currentRoute == "my-facilities") {
          propertyCollection = db
            .collection("property")
            .where("postStatus", "in", ["accepted", "pending"])
            .where("createdBy", "==", payload.currentUser.id)
            .orderBy("publishedAt", "desc")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(1);
        } else {
          if (payload.searchBy) {
            switch (payload.searchBy) {
              case "listedFor":
                propertyCollection = db
                  .collection("property")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("listedForId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(1);
                break;
              case "type":
                propertyCollection = db
                  .collection("property")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("typeId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(1);
                break;
              case "city":
                propertyCollection = db
                  .collection("property")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("cityId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(1);
                break;
            }
          } else {
            propertyCollection = db
              .collection("property")
              .where("postStatus", "in", ["accepted", "deleted"])
              .where("isActive", "==", true)
              .orderBy("publishedAt", "desc")
              .endBefore(getters.loadFirstRecord)
              .limitToLast(1);
          }
        }

        await bindFirestoreRef("recordBeforeFirst", propertyCollection);
      }
    ),

    // setMountedTable({commit},value){
    //   commit('SET_MOUNTEDTABLE',value)
    // },
    //#endregion

    // async doFetch(
    //   { commit },
    //   currentUserId
    // ) {
    //   try {
    //     commit('FETCH_STARTED')
    //     FacilitiesService.list((doc,docType) => {
    //       if(doc.createdBy == currentUserId){
    //         commit('APPEND_TO_CURRENT_USER_FACILITIES',{doc,docType})
    //       }
    //       commit('APPEND_TO_FACILITIES',{doc,docType})
    //       commit('APPEND_TO_FACILITIES_WITHOUT_FILTER',{doc,docType})
    //       commit('FETCH_SUCCESS')
    //     }
    //     )

    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FETCH_ERROR');
    //   }
    // },
    async doFetchUser({ commit }, userId) {
      try {
        commit("FETCH_USER_STARTED");
        FacilitiesService.findUser(userId, (doc) => {
          commit("FETCH_USER_SUCCESS", doc);
        });
      } catch (error) {
        Errors.handle(error);
        commit("FETCH_USER_ERROR");
      }
    },

    async doFetchRegion({ commit }) {
      try {
        commit("FETCH_STARTED");
        FacilitiesService.listRegion((doc, docType) => {
          commit("APPEND_TO_REGIONS", { doc, docType });
          commit("FETCH_SUCCESS");
        });
      } catch (error) {
        Errors.handle(error);
        commit("FETCH_ERROR");
      }
    },
    setCurrentRoute({ commit }, value) {
      commit("SET_CURRENTROUTE", value);
    },
    setFacilities({ commit }, value) {
      commit("SET_FACILITIES", value);
    },
  },
};
