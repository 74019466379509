const firebaseConfig = {
  // apiKey: "AIzaSyAjzlpO8QLbk-lg8i3kjHZMwthzfDYlISo",
  // authDomain: "web.drugdealapp.com",
  // databaseURL: "https://drug-deal-web-app.firebaseio.com",
  // projectId: "drug-deal-6e05f",
  // storageBucket: "drug-deal-6e05f.appspot.com",
  // messagingSenderId: "207604195666",
  // appId: "1:207604195666:web:c37790b997a8e0040cda8b",
  // measurementId: "G-4BLBVXG8Z8"
  apiKey: "AIzaSyAjzlpO8QLbk-lg8i3kjHZMwthzfDYlISo",
  // authDomain: "drug-deal-6e05f.firebaseapp.com",
  authDomain: "web.drugdealapp.com",
  databaseURL: "https://drug-deal-6e05f.firebaseio.com",
  projectId: "drug-deal-6e05f",
  storageBucket: "drug-deal-6e05f.appspot.com",
  messagingSenderId: "207604195666",
  appId: "1:207604195666:web:6c84d0b34aa60ebc0cda8b",
  measurementId: "G-3LE5S5EMXV"

};

// Cloud Functions
const backendUrl = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/settingsAndEmails`;

const backendUrlAuth = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/auth`;

const backendUrlCity = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/city`;

const backendUrlCustomer = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/customer`;

const backendUrlOrder = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/order`;

const backendUrlPrescription = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/prescription`;

const backendUrlJob = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/job`;

const backendUrlProperty = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/property`;

const backendUrlContact = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/contact`;

const backendUrlDrugIndex = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/drugIndex`;
// App Engine
// const backendUrl = `<insert app engine url here>`;
const backendPreRender = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/preRender`;

export default {
  firebaseConfig,
  backendUrl,
  backendUrlAuth,
  backendUrlCity,
  backendUrlCustomer,
  backendUrlOrder,
  backendUrlPrescription,
  backendUrlJob,
  backendUrlProperty,
  backendUrlContact,
  backendUrlDrugIndex,
  backendPreRender
};
