
import { ImporterPager } from '@/shared/importer/importer-pager';
// import { i18n } from '@/i18n';
import ImporterStatusRow from '@/shared/importer/components/importer-status-row.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'app-importer-list',

  props: ['storePath'],

  components: {
    [ImporterStatusRow.name]: ImporterStatusRow,
  },

  data() {
    return {
      pager: new ImporterPager(),
    };
  },

  computed: {
    ...mapGetters({
      paginationLayout: 'layout/paginationLayout',
    }),

    rows() {
      return this.$store.getters[`${this.storePath}/rows`];
    },

    fields() {
      return this.$store.getters[
        `${this.storePath}/fields`
      ];
    },
  },

  methods: {
    i18n(code) {
      return this.$t(code);
    },
  },
};
