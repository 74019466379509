
import { mapGetters, mapActions } from 'vuex';
// import { routerAsync } from '@/app-module';
// import firebase from 'firebase/app';
// import 'firebase/firestore';
import i18n from '@/vueI18n'
import firebase from 'firebase';
import { AnalyticsManager } from '@/shared/firebase/firebase-analytics';


export default {
  name: 'app',
  data(){
    return{
      // lang:null,
      renderComponent: true,
    }
  },

  // created() {
  //   this.doInit();
  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  // },
  
  async created() {

    // setTimeout(() => {
    //   i18n.locale = 'en'
    //   localStorage.setItem('language','en')
    //   document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
    //   this.$q.lang.rtl = false
    //   routerAsync().push('/en');
    // }, 10000);
// localStorage.setItem('language', "en")
    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises).then(
    )
      AnalyticsManager.logAppOpen()

    // window.addEventListener('load', this.addToCache(['/']));
    // await this.doInit();
    // firebase.auth().languageCode = localStorage.getItem('language');
    window.addEventListener('resize', this.handleResize);
    // document.addEventListener('scroll', this.handleScroll);
    this.handleResize();
    // firebase.firestore().enablePersistence({synchronizeTabs:true})
    // .catch(function(err) {
    //   if (err.code == 'failed-precondition') {
    //   } else if (err.code == 'unimplemented') {
    //   }
    // });

    // firebase.firestore().enablePersistence({synchronizeTabs:true})


    // window.addEventListener('beforeunload', this.beforeUnloadHandler)
    // await window.addEventListener('beforeunload', await () => {

    // })

  },
  
  watch:{
    // 'i18n.locale'(newVal){
    //   // debugger
    //   // 
    //   },
      // lang(newVal){
      //   if(newVal == 'ar') document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
      //   else document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
      // }
      lang(newVal){
        // debugger

        if(newVal == 'ar') {
          document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
          this.$q.lang.rtl = true
        }
        if(newVal == 'en') {
          document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
          this.$q.lang.rtl = false
        }
      }
      
  },
  mounted() {

    if (localStorage.getItem("language") == "ar") {
      import(`quasar/lang/ar`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    } else {
      import(`quasar/lang/en-us`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }
    


    
    // if (localStorage.getItem('language') == "ar") {
    //   import(`quasar/lang/ar`).then((lang) => {
    //     this.$q.lang.set(lang.default);
    //   });
    // } else {
    //   import(`quasar/lang/en-us`).then((lang) => {
    //     this.$q.lang.set(lang.default);
    //   });
    // }



    // localStorage.setItem('language', "en")
  },
  // destroyed() {
  //   window.removeEventListener('resize', this.handleResize);
  //   debugger
  //   // document.addEventListener('scroll', this.handleScroll);
  // },
  // beforeDestroy() {
  //   debugger
  // },

  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize',
      navigation: 'layout/navigation',
      deleteUserFromAthTable: 'layout/deleteUserFromAthTable'
    }),
    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    forceRerender(language){
      // debugger
      this.lang = language
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    beforeUnloadHandler(event){      
      // debugger
      firebase.auth().currentUser.delete()    
      event.preventDefault()
         
    }
  },
 
  computed: {
    ...mapGetters({
      loadingInit: 'auth/loadingInit',
      tab: 'layout/tab',
      anonymousUid: 'layout/anonymousUid'
    }),
    getRoutePath() {
      return this.$route;
    },
    lang(){
      return i18n.locale
    }
  },
};
