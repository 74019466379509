import firebase from 'firebase';
import 'firebase/analytics';


export class AnalyticsManager {
    static async logDrugFilterSelection(filter) {
        let filterName = null
        switch (filter) {          
            case 'tradeName':
                filterName = 'Trade name'
            break
            case 'genericName':
                filterName = 'Generic name'
            break
            case 'company':
                filterName = 'Company'
            break
            case 'newPrice':
                filterName = 'Price'
            break
        }
        const analytics = firebase.analytics()
        analytics.logEvent('drug_filter', {
            name: filterName
        });
    }
    static async logDrugSimilaritiesAlternativesSelection(button) {
        let buttonName = null
        switch(button){
            case 'similarities':
                buttonName = 'Similarities'
            break
            case 'alternatives':
                buttonName = 'Alternatives'
            break
        }
        firebase.analytics().logEvent('button_click', {
            name: buttonName
        })
    }
    static async logOfferSelection(offerId, offerName){
        firebase.analytics().logEvent('screen_view', {
            promotionId: offerId, 
            promotionName: offerName
          });

    }
    static async logScreen(screenName, screenClass){
        firebase.analytics().logEvent('screen_view', {
            screenName, 
            screenClass
          });
    }
    static async logSignUp(method){
        firebase.analytics().logEvent('sign_up', {
            signUpMethod: method, 
          });
    }
    static async logSignIn(method){
        firebase.analytics().logEvent('login', {
            loginMethod: method, 
          });
    }
    static async logShare(id, type){
        firebase.analytics().logEvent('share', {
            contentType: type,
            itemId: id, 
            method: type 
          });
    }
    static async setUserId(id){
        firebase.analytics().setUserProperties({ user_id: id });
    }
    static async setUserLanguage(language){
        firebase.analytics().setUserProperties({ user_language: language });
    }
    static async logAppOpen(){
        firebase.analytics().logEvent('app_open')
    }
  }