// import gql from 'graphql-tag';
// import graphqlClient from '@/shared/graphql/client-drug-index';
import 'firebase/firestore';
import firebase from 'firebase/app'

export class DrugIndexService {
  static async list(callback) {
    firebase.firestore().collection("drugIndexCurrent").onSnapshot(coll => {
      coll.docChanges().forEach(change => {
        let drug =  change.doc.data()
          callback(drug,change.type)
      });
    });
  }


}
