import '@/shared/plugins/element';
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
// import vbclass from 'vue-body-class'

import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from '@/app-module';
import app from '@/app.vue';
import PortalVue from 'portal-vue';
import './quasar';
import { colors } from 'quasar';
const { setBrand } = colors;
setBrand("myBrand","#642B73");
setBrand("myBrand2","#fdf0f4");
setBrand("aboutCard","#FBE6EC");
setBrand("Whisper","#E1DEDE");
setBrand("required","#ed3035");



import vueSmoothScroll from 'vue2-smooth-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Vuesax from 'vuesax';
import VieOtpInput from "@bachdgvn/vue-otp-input";
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);
Vue.component('vie-otp-input', VieOtpInput);

// ============ for image carousel in facility view =============
import VueAgile from 'vue-agile'
Vue.use(VueAgile)
// ==============================================================


import 'vuesax/dist/vuesax.css'; //Vuesax styles
Vue.use(Vuesax, {
  primary:'#3A026C',
  success:'rgb(23, 201, 100)',
  danger:'rgb(242, 19, 93)',
  warning:'rgb(255, 130, 0)',
  dark:'rgb(255, 255, 255)',
  transparent:'rgb(255, 255, 255) '
});
// eslint-disable-next-line to ignore the next line.
/* eslint-disable */

import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox);
import '@mdi/font/css/materialdesignicons.css';
import i18n from './vueI18n'



import VueMeta from 'vue-meta';
Vue.use(VueMeta);

// ============ VueDatePicker for job form date =============
// import VueDatePicker from '@mathieustan/vue-datepicker';
// import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

// Vue.use(VueDatePicker);

// // Options: You can set lang by default
// Vue.use(VueDatePicker, {
//   // lang: 'ar'
// });
// ============================================================
(async function() {
  // document.title = i18n('app.title');
  // ProgressBar.start();
  // SettingsService.fetchAndApply();

  Vue.use(Router);
  // const router = new Router()
  // router.beforeEach((to,from,next) => {
  //   debugger
  // //   let language = to.params.lang
  // //   if(!language){
  // //     language = 'en'
  // //   }
  // //   next()
  // })



  Vue.use(PortalVue);
  Vue.config.productionTip = process.env.NODE_ENV === 'production';
  Vue.use(Vuex);
  setupComponentsFiltersDirectivesAndMixins();

  // ======================================= New Installer ======================================= //
  Vue.use(BootstrapVue);
  Vue.use(vueSmoothScroll);
  AOS.init({
    once: false,
  })
  // ============================================================================================= //

  // eslint-disable-next-line
  new Vue({
    store: storeAsync(),
    router: routerAsync(),
    i18n,
    render: (h) => h(app)
  }).$mount('#app');
})();
