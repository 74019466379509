const ar = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    
    
  },

  app: {
    title: 'Drug Deal',
  },

  drugDeal: {
    common: {
      drugDeal: 'Drug Deal',
      drug: 'DRUG',
      deal: 'DEAL',
      about: 'عن',
      home: 'الرئيسية',
      aboutUs: 'عن التطبيق',
      services:'خدمات',
      login:'تسجيل الدخول',
      features: 'مميزات التطبيق',
      contactUs: 'تواصل معنا',
      privacyPolicy: 'سياسة الخصوصية',
      termsAndConditions: 'الشروط والأحكام',
      theBest:'افضل',
      medicalReference:'مرجع طبى',
      youNeedInOne:'تحتاج اليه فى',
      youNeed:'تحتاج اليه',
      inOne:'فى',
      place:'.مكان واحد',
      readMore:'اقرا المزيد',
      downloadNow:'حمل التطبيق الان',
      submit:'ارسل',
      views:'مشاهدات',
      viewDetails:'عرض التفاصيل',
      publish:'انشر',
      edit:'عدل',
      delete:'احذف',
      successfully:'لقد نجحت',
      emptyJobs:"لا توجد فرص عمل",
      emptyPrescriptions:"لا  توجد وصفات طبية",
      emptyFacilities:"لا توجد مرافق",
      pleaseLogin:"الرجاء تسجيل الدخول لمشاهدة",
      error:"خطأ",
      youCanNotAddPrescription:"لا يمكنك إضافة أكثر من 3 وصفات طبية في الشهر",
      youCanNotAddFacility:"لا يمكنك إضافة أكثر من 5 مرافق في الشهر",
      invalidTitle:'عنوان غير صالح',
      invalidDescripiton:'وصف غير صالح',
      invalidCompnayName:'اسم الشركة غير صالح',
      invalidWorkplaceName:'اسم مكان العمل غير صالح',
      invalidPositionName:'اسم الوظيفة غير صحيح',
      invalidRegionName:'اسم المنطقة غير صالح',
      invalidCityName:'اسم المدينة غير صالح',
      invalidSalary:'راتب غير صالح',
      invalidEmail:'عنوان البريد الإلكتروني غير صالح',
      invalidPhone:'رقم الهاتف غير صحيح',
      invalidAddress:'عنوان غير صالح',
      fileSizeLessThanOneMB:"يجب أن يكون حجم الملف أقل من 1 ميغا بايت",
      fileSizeLessThanHalfMB:"يجب أن يكون حجم الملف أقل من 0.5 ميغا بايت",
      saveChanges:'احفظ التغييرات',
      profile:'الملف',
      logout:'الخروج',
      copyRight:"حقوق النشر © 2021 Drug Deal",
     developedBy:" @ 2021 طور بواسطة Runprof"
    },
    contactUs:{
      contact:"تواصل معنا",
      email:"البريد الاكترونى",
      yourName:"الاسم",
      phone:"الهاتف",
      msg:"الرسالة"
    },
    services:{ 
      ourServices:'خدماتنا',
      servicesDesc:`Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, 
      lectus magna fringilla urna, porttitor , consectetur adipiscing elit ut aliquam, 
      purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor`,
      servicesDetail1:`Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor , 
      consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor`,
      servicesDetail2:`Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor , 
      consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor`,
      drugIndex:'فهرس الدوية',
      drugInteractions:'تفاعلات الدوية',
      prescriptions:'وصفات طبية',
      jobs:'فرص العمل',
      facilities:'ممتلكات',
      sellBuyRent:'بيع,شراء,ايجار',
      offers:'عروض',
      prescription:'وصفات طبية',
      job:'job',
      facility:'ممتلكات',
    },
    header: {
      welcomeTo: 'مرحبا بك في',
      message: 'أفضل مرجع طبي و دوائي في مكان واحد.',
      downloadOnTheAppStore: 'تحميل على متجر التطبيقات',
      getItOn: 'احصل عليه',
      googlePlay: 'تطبيقات جوجل',
      home:'الرئيسية',
      services:'الخدمات',
      about:'عنا',
      
    },
    // about: {
    //   about:'عنا',
    //   knowMoreAboutUs:'اعرف المزيد عنا',
    //   message: 'العديد من المزايا المقنعة لاستخدام تطبيقات الهاتف المحمول Drug Deal',
    //   point1: 'تحديث فرص العمل بانتظام.',
    //   point2: 'المساعدة الرقمية للموظفين الطبيين والعملاء.',
    //   timeline1: 'مريح وسهل الاستخدام.',
    //   timeline2: 'توفير معلومات طبية دقيقة و مفصلة.',
    //   timeline3: 'توفير فرص عمل محدثة بانتظام.',
    //   timeline4: 'المساعدة الرقمية للتسهيل علي الأطقم الطبية والعملاء.',
      

    // },
    about: {
      about:'عنا',
      knowMoreAboutUs:'اعرف المزيد عنا',
      aboutDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor .',
      searchForDrugIndexes:'ابحث عن فهارس الأدوية',
      alternatives:'البدائل والتشابه',
      searchForDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor .',
      checkout:'راجع',
      prescription:'الوصفة الطبية الخاصة بك',
      checkoutDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor .',
      findYour:'ابحث عن',
      job:'الوظيفة الخاصة بك',
      jobDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor .'
    },
    features: {
      mobileApp: 'تطبيق جوال',
      message: 'Stay on top of the best deals',
      searchFor: 'البحث عن الادوية',
      'searchFor-description': 'تصفح دليل الأدوية و الأدوية البديلة و المشابهة',
      drugInteraction: 'التعارضات الدوائية',
      'drugInteraction-description': 'اكتب اسم الدواء و اعرف اكثر عن تفاعلاته و الأدوية المعارضه له و ارشادات عدم استخدامه',
      prescriptions: 'الوصفات الطبية',
      'prescriptions-description': 'تصفح الكثير من الوصفات الطبية و العلاجية',
      jobOpportunities: 'فرص العمل',
      'jobOpportunities-description': 'ابحث عن الكثير من فرص عمل والوظائف المحدثة بشكل دوري',
      offers: 'العروض',
      'offers-description': 'احصل على عروض يومية على جميع المنتجات الطبية',
      sellAndBuyandrent: 'الخدمات التجارية و العقارية',
      'sellAndBuyandrent-description': 'يمكنك بيع، شراءأو تأجير صيدلية بسهولة. عن طريق التطبيق مع إعطاءوصف كامل للمكان ',
      keepMessage: 'قم باستخدام التطبيق و استفيد بأحدث العروض والعروض الترويجيه المستمرة على فئات التطبيق المختلفه.',
    },
    download:{
      mobileApp:'التطبيق على الهاتف المحمول',
      download:'حمل التطبيق الان'
    },
    footer: {
      facebook:'فيسبوك',
      twitter:'تويتر',
      instagram:'انسجرام',
      by: 'كل',
      madeWith: 'قدمت مع',
      // copyRight : `جميع الحقوق محفوظة لـ  Drug Deal `,
      // c: '© 2020',
      copyright: ` جميع الحقوق محفوظة لـ 2021 © Drug Deal`,
      createdByRunprof:'تطور من خلال شركة Runprof',
      downloadMobileApp:'حمل التطبيق',
      getTheLatestNews:'اخر الاخبار',
      join:'انضم الينا',
      emailAddress:'البريد الالكترونى'
    },
    drugIndex:{
      searchByDrugName:'ابحث باسم الدواء',
        searchForDrugs:'ابحث عن اسم',
        name:'الدواء',
        searchBy:'ابحث باسم الدواء',
        mostSearchedDrugs:'اكثر الادوية بحثا',
        moreDetails:'اعرف المزيد',
        oldPrice:'السعر القديم',
        newPrice:"السعر الجديد",
        category:'النوع',
        manifacturingCompany:'الشركة المصنعة',
        manifacturingCompanies:'الشركات المصنعة',
        additionalInfo:'معلومات اضافية',
        noSearchResults:'لايوجد نتائج للبحث',
        tryAgain:'حاول البحث مرة اخرى',
        le:'ج.م',
        alternatives:"البدائل",
        similarities:"المثيلات",
        tradeName:'الاسم التجارى',
        genericName:'الاسم عام',
        company:'الشركة',
        
      
    }, 
    facilities:{
      myProperties:'ممتلكاتي',
      name:'الاسم',
      spaceArea:'المساحة',
      type:'النوع',
      listedFor:'مدرجة في',
      city:'المدينة',
      region:'المنطقة',
      status:'الحالة',
      expectedPrice:'السعر المتوقع',
      averageIncome:'متوسط ​​الدخل',
      makeSure:'يرجى التأكد من أن هذا هو دخلك اليومي',
      address:'العنوان',
      phoneNumber:'رقم الهاتف',
      description:'الوصف',
      uploadPictures:'تحميل الصور',
      companyPlaceholder:'أدخل اسم شركتك هنا',
      spaceAreaPlaceholder:'أدخل مساحتك هنا',
      typePlaceholder:'أدخل النوع هنا',
      listedForPlaceholder:'تم سرد هذا المرفق ل',
      cityPlaceholder:'أدخل مدينتك هنا',
      regionPlaceholder:'أدخل منطقتك هنا',
      statusPlaceholder:'أدخل الحالة هنا',
      pricePlaceholder:'أدخل السعر المتوقع هنا',
      averageIncomePlaceholder:'أدخل متوسط ​​دخلك هنا',
      addressPlaceholder:'أدخل عنوانك هنا',
      phonePlaceholder:'أدخل رقم هاتفك هنا',
      descriptionPlaceholder:'أدخل وصفك هنا',
      selling:'بيع',
      adNumber:'رقم الاعلان',
      dailyIncome:'الدخل اليومي',
      prices:'الأسعار',
      LE:"ج.م",
      youCan:'يمكنك بيع أو شراء أو تأجير أ',
      pharmacy:' صيدلية بسهولة',
      oneClick:'بنقره واحدة',
      add:'إضافة منشأة جديدة',
      facilities:'مرافق',
      searchFor:'ابحث عن منشأة',
      filter:'ابحث عن طريق',
      category:'الفئة',
      // pharmacy:'pharmacy easily within',
      currentStatus:'الحالة',
      closed:'مغلق',
      opened:'مفتوح',
      space:'المساحة',
      dailyIcome:'الدخل اليومي'

    },
    jobs:{
      myJobs:'فرص العمل الخاصة بى',
      browse:'تصفح الوصف الوظيفي و',
      moreInfo:'المزيد من المعلومات وبسهولة',
      find:'اعثر على وظيفة جديدة',
      add:'اضف وظيفة جديدة',
      jobOpportunities:'فرص العمل',
      search:'البحث عن وظائف',
      filter:'ابحث عن طريق',
      aboutCompany:'عن الشركة',
      hiring:'توظيف',
      workplace:'مكان العمل',
      city:'المدينة',
      position:'المركز',
      salary:'الراتب',
      region:'المنطقة',
      jobInfo:'معلومات عن الوظيفة',
      company:'مالك',
      individual:'موظف',
      companyName:'اسم المالك',
      optional:'اختياري',
      negotiable:'قابل للتفاوض',
      from:'من',
      to:'إلى',
      perHour:'في الساعة',
      perMonth:'فى الشهر',
      email:'البريد الإلكتروني',
      phoneNumber:'رقم الهاتف',
      address:'العنوان',
      description:'الوصف',
      run:'تشغيل هذا الإعلان حتى',
      selectDate:'حدد تاريخ',
      successMsg:'فرصتك في العمل تنتظر موافقة المسؤول',
      selectCity:'اختر مدينة',
      selectRegion:'اختر المنطقة',
      selectPosition:'حدد الوظيفة',
      selectWorkplace:'حدد مكان العمل',
      companyPlaceholder:'أدخل اسم شركتك هنا',
      emailPlaceholder:'أدخل عنوان بريدك الالكتروني هنا',
      phonePlaceholder:'أدخل رقم الهاتف هنا',
      addressPlaceholder:'أدخل العنوان هنا',
      datePlaceholder:'أدخل التاريخ هنا',
      descriptionPlaceholder:'أدخل الوصف هنا',
      hired:'تم التعيين',
      searchForJobs:'ابحث عن وظيفة'
      // optional:"اختيارى"

      // hiring:'Hiring'
    },
    prescriptions:{
      // prescrition:'Prescription',
      myPrescriptions:'الوصفات الطبية الخاصة بي',
      prescriptionTitle:'عنوان الوصفة',
      prescriptionText:'نص الوصفة',
      viewAndAddPrescription:'عرض وإضافة وصفة جديدة',
      // viewAndAddPrescription:'View And Add New Prescription',
      addNewPrescription:'اضف وصفة طبية جديدة',
      add:'اضف وصفة طبية جديدة',
      comments:'تعليقات',
      viewAllComments:'اعرض المزيد من التعليقات',
      uploadPhoto:'يمكنك تحميل صورة الوصفة الخاصة بك',
      title:'العنوان',
      description:'الوصف',
      commentPlaceholder:"أدخل تعليقك هنا...",
      typing:"شخص ما يكتب تعليقًا ..."
     
    },
   

  },
  
  entities: {
    customer: {
      name: 'customer',
      label: 'Customers',
      menu: 'Customers',
      exporterFileName: 'customer_export',
      list: {
        menu: 'Customers',
        title: 'Customers',
      },
      create: {
        success: 'Customer saved successfully',
      },
      update: {
        success: 'Customer saved successfully',
      },
      destroy: {
        success: 'Customer deleted successfully',
      },
      destroyAll: {
        success: 'Customer(s) deleted successfully',
      },
      edit: {
        title: 'Edit Customer',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'birthdateRange': 'Birthdate',
        'birthdate': 'Birthdate',
        'gender': 'Gender',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'gender': {
          'male': 'Male',
          'female': 'Female',
        },
      },
      new: {
        title: 'New Customer',
      },
      view: {
        title: 'View Customer',
      },
      importer: {
        title: 'Import Customers',
        fileName: 'customer_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    product: {
      name: 'product',
      label: 'Products',
      menu: 'Products',
      exporterFileName: 'product_export',
      list: {
        menu: 'Products',
        title: 'Products',
      },
      create: {
        success: 'Product saved successfully',
      },
      update: {
        success: 'Product saved successfully',
      },
      destroy: {
        success: 'Product deleted successfully',
      },
      destroyAll: {
        success: 'Product(s) deleted successfully',
      },
      edit: {
        title: 'Edit Product',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'description': 'Description',
        'unitPriceRange': 'Unit Price',
        'unitPrice': 'Unit Price',
        'photos': 'Photos',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'New Product',
      },
      view: {
        title: 'View Product',
      },
      importer: {
        title: 'Import Products',
        fileName: 'product_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    order: {
      name: 'order',
      label: 'Orders',
      menu: 'Orders',
      exporterFileName: 'order_export',
      list: {
        menu: 'Orders',
        title: 'Orders',
      },
      create: {
        success: 'Order saved successfully',
      },
      update: {
        success: 'Order saved successfully',
      },
      destroy: {
        success: 'Order deleted successfully',
      },
      destroyAll: {
        success: 'Order(s) deleted successfully',
      },
      edit: {
        title: 'Edit Order',
      },
      fields: {
        id: 'Id',
        'customer': 'Customer',
        'products': 'Products',
        'employee': 'Employee',
        'delivered': 'Delivered',
        'attachments': 'Attachments',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'New Order',
      },
      view: {
        title: 'View Order',
      },
      importer: {
        title: 'Import Orders',
        fileName: 'order_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',
    alreadyHaveAnAccount: 'Already have an account? Sign in.',
    signinWithAnotherAccount: 'Sign in with another account',
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email verification`,
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
    },
    passwordResetEmail: {
      message: 'Send password reset email',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: `Full access to manage users roles`,
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    customerEditor: {
      label: 'Customer Editor',
      description: 'Edit access to Customers',
    },
    customerViewer: {
      label: 'Customer Viewer',
      description: 'View access to Customers',
    },
    productEditor: {
      label: 'Product Editor',
      description: 'Edit access to Products',
    },
    productViewer: {
      label: 'Product Viewer',
      description: 'View access to Products',
    },
    orderEditor: {
      label: 'Order Editor',
      description: 'Edit access to Orders',
    },
    orderViewer: {
      label: 'Order Viewer',
      description: 'View access to Orders',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint: 'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success: 'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },
  home: {
    menu: 'Home',
    message: `This page uses fake data for demonstration purposes only. You can edit it at frontend/src/modules/home/components/home-page.vue.`,
    verificationCode:'من فضلك أدخل رمز التأكيد',
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({
        path,
        type,
        value,
        originalValue
      }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage: 'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors: 'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload: 'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },

  firebaseErrors: {
    'auth/user-disabled': 'Your account is disabled',
    'auth/user-not-found': `Sorry, we don't recognize your credentials`,
    'auth/wrong-password': `Sorry, we don't recognize your credentials`,
    'auth/weak-password': 'This password is too weak',
    'auth/email-already-in-use': 'Email is already in use',
    'auth/invalid-email': 'Please provide a valid email',
    'auth/account-exists-with-different-credential': 'Email is already in use for a different authentication method.',
    'auth/credential-already-in-use': 'Credentials are already in use',
  },

  privacyPolicy: {
    content: `  <div>
                  <strong class="privacy-policy-title fontResize" style="color: #C6426E;">سياسة </strong>
                  <strong class="privacy-policy-title fontResize" style="color: #000000;">الخصوصية.</strong> 
                  <img src="/images/short-line.png" class="short-line-w short-line-h widthResize heightResize" style="padding: 1.389vw 0 5.764vw !important" />
                  <img src="/images/lock.png" class="img-lock" />

                             <p> بنى RunProf تطبيق Drug Deal كتطبيق مجاني. يتم توفير هذه الخدمة عن طريق Drug Deal بدون أي تكلفة وهي مخصصة للاستخدام كما هي.
                            </p> <p>
                              تُستخدم هذه الصفحة لإعلام الزوار فيما يتعلق بسياساتنا فيما يتعلق بجمع المعلومات الشخصية واستخدامها والكشف عنها إذا قرر أي شخص استخدام خدمتنا.
                            </p> <p>
                              إذا اخترت استخدام خدمتنا ، فأنت توافق على جمع المعلومات واستخدامها فيما يتعلق بهذه السياسة. يتم استخدام المعلومات الشخصية التي نجمعها لتوفير الخدمة وتحسينها. لن نستخدم أو نشارك معلوماتك مع أي شخص باستثناء ما هو موضح في سياسة الخصوصية هذه.
                            </p> <p>
                              المصطلحات المستخدمة في سياسة الخصوصية هذه لها نفس المعاني كما هي في الشروط والأحكام الخاصة بنا ، والتي يمكن الوصول إليها في Drug Deal ما لم يتم تحديد خلاف ذلك في سياسة الخصوصية هذه.
                            </p> <p><strong>جمع المعلومات واستخدامها</strong></p> <p>
                              للحصول على تجربة أفضل ، أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا ببعض المعلومات المحددة للهوية الشخصية. سنحتفظ بالمعلومات التي نطلبها واستخدامها كما هو موضح في سياسة الخصوصية هذه.
                            </p> <div><p>
                              يستخدم التطبيق خدمات الطرف الثالث التي قد تجمع المعلومات المستخدمة لتحديد هويتك.
                              </p> <p>
                              رابط إلى سياسة الخصوصية لموفري خدمة الطرف الثالث المستخدمة من قبل التطبيق
                              </p> <ul><li>
                              <a style="color: blue !important;" href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><li>
                              <a style="color: blue !important;" href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li>
                              <a style="color: blue !important;" href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><li>
                              <a style="color: blue !important;" href="https://www.facebook.com/about/privacy/update/printable" target="_blank" rel="noopener noreferrer">Facebook</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>تسجيل البيانات</strong></p> <p>
                              نريد إبلاغك أنه كلما استخدمت خدمتنا ، في حالة وجود خطأ في التطبيق ، نقوم بجمع البيانات والمعلومات (من خلال منتجات الطرف الثالث) على هاتفك تسمى Log Data. قد تتضمن بيانات السجل هذه معلومات مثل عنوان بروتوكول الإنترنت ("IP" الخاص بجهازك ، واسم الجهاز ، وإصدار نظام التشغيل ، وتكوين التطبيق عند استخدام خدمتنا ، ووقت وتاريخ استخدامك للخدمة ، وإحصاءات أخرى .
                            </p> <p><strong>ملفات تعريف الارتباط</strong></p> <p>
                              ملفات تعريف الارتباط هي ملفات تحتوي على كمية صغيرة من البيانات التي يتم استخدامها بشكل شائع كمعرفات فريدة مجهولة الهوية. يتم إرسالها إلى متصفحك من مواقع الويب التي تزورها ويتم تخزينها على الذاكرة الداخلية لجهازك.
                            </p> <p>
                              لا تستخدم هذه الخدمة "ملفات تعريف الارتباط" هذه بشكل صريح. ومع ذلك ، قد يستخدم التطبيق رمزًا ومكتبات تابعة لجهة خارجية تستخدم "ملفات تعريف الارتباط" لجمع المعلومات وتحسين خدماتها. لديك خيار إما قبول أو رفض ملفات تعريف الارتباط هذه ومعرفة متى يتم إرسال ملف تعريف ارتباط إلى جهازك. إذا اخترت رفض ملفات تعريف الارتباط الخاصة بنا ، فقد لا تتمكن من استخدام بعض أجزاء هذه الخدمة.
                            </p> <p><strong>مقدمي الخدمة</strong></p> <p>
                            يجوز لنا توظيف شركات وأفراد من جهات خارجية للأسباب التالية:
                            </p> <ul><li>لتسهيل خدمتنا ؛</li> <li>لتقديم الخدمة نيابة عنا ؛
                            </li> <li>لأداء الخدمات المتعلقة بالخدمة ؛ أو
                            </li> <li>لمساعدتنا في تحليل كيفية استخدام خدمتنا.
                            </li></ul> <p>
                              نريد إبلاغ مستخدمي هذه الخدمة أن هذه الأطراف الثالثة يمكنها الوصول إلى معلوماتك الشخصية. والسبب هو أداء المهام المسندة إليهم نيابة عنا. ومع ذلك ، فإنهم ملزمون بعدم الكشف عن المعلومات أو استخدامها لأي غرض آخر.
                            </p> <p><strong>الأمان</strong></p> <p>
                            نحن نقدر ثقتك في تزويدنا بمعلوماتك الشخصية ، وبالتالي نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحمايتها. ولكن تذكر أنه لا توجد طريقة إرسال عبر الإنترنت ، أو طريقة تخزين إلكترونية آمنة وموثوق بها بنسبة 100٪ ، ولا يمكننا ضمان أمانها المطلق.
                            </p> <p><strong>روابط لمواقع أخرى</strong></p> <p>
                              قد تحتوي هذه الخدمة على روابط لمواقع أخرى. إذا قمت بالنقر فوق ارتباط جهة خارجية ، فسيتم توجيهك إلى هذا الموقع. لاحظ أن هذه المواقع الخارجية لا نقوم بتشغيلها. لذلك ، ننصحك بشدة بمراجعة سياسة الخصوصية لهذه المواقع. لا نتحكم ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو الممارسات الخاصة بأي مواقع أو خدمات تابعة لجهات خارجية.
                            </p> <p><strong>خصوصية الأطفال</strong></p> <p>
                            لا تتعامل هذه الخدمات مع أي شخص يقل عمره عن 13 عامًا. نحن لا نجمع عن قصد معلومات تحديد الهوية الشخصية من الأطفال تحت سن 13 عامًا. في حالة اكتشافنا أن طفلًا أقل من 13 عامًا قدم لنا معلومات شخصية ، فإننا نحذفها على الفور من خوادمنا. إذا كنت أحد الوالدين أو الوصي وكنت على علم بأن طفلك قد قدم لنا معلومات شخصية ، يرجى الاتصال بنا حتى نتمكن من القيام بالإجراءات اللازمة.

                            </p> <p><strong>التغييرات على سياسة الخصوصية هذه</strong></p> <p>
                              قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. وبالتالي ، ننصحك بمراجعة هذه الصفحة بشكل دوري لأية تغييرات. سنقوم بإعلامك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة.

                            </p> <p>تسري هذه السياسة اعتبارًا من 2020-07-07</p> <p><strong>اتصل بنا</strong></p> <p>
                              إذا كان لديك أي أسئلة أو اقتراحات حول سياسة الخصوصية الخاصة بنا ، فلا تتردد في الاتصال بنا على support@drugdealapp.com.
                            </p>
                </div>`
  },

  termsAndConditions: {
    content: `<div>
                <strong></strong> 
                <strong class="privacy-policy-title fontResize" style="color: #C6426E;">الشروط و </strong>
                <strong class="privacy-policy-title fontResize" style="color: #000000;">الأحكام</strong> 
                <img src="/images/short-line.png" class="short-line-w short-line-h widthResize heightResize" style="padding: 1.389vw 0 5.764vw !important" />
                <img src="/images/lock.png" class="img-lock" />

                <p>
                                من خلال تنزيل التطبيق أو استخدامه ، سيتم تطبيق هذه الشروط عليك تلقائيًا - يجب عليك التأكد من قراءتها بعناية قبل استخدام التطبيق. لا يُسمح لك بنسخ أو تعديل التطبيق أو أي جزء من التطبيق أو علاماتنا التجارية بأي شكل من الأشكال. لا يُسمح لك بمحاولة استخراج شفرة المصدر للتطبيق ، كما يجب ألا تحاول ترجمة التطبيق إلى لغات أخرى ، أو إنشاء إصدارات مشتقة. التطبيق نفسه ، وجميع العلامات التجارية وحقوق التأليف والنشر وحقوق قواعد البيانات وحقوق الملكية الفكرية الأخرى المتعلقة به ، لا تزال تنتمي إلى Drug Deal.
                              </p> <p>
                                تلتزم Drug Deal بضمان أن التطبيق مفيد وفعال قدر الإمكان. لهذا السبب ، نحتفظ بالحق في إجراء تغييرات على التطبيق أو فرض رسوم على خدماته ، في أي وقت ولأي سبب. لن نقوم أبدًا بتحصيل رسوم منك مقابل التطبيق أو خدماته دون أن نوضح لك تمامًا ما تدفعه مقابل ذلك.

                              </p> <p>
                               يقوم تطبيق Drug Deal بتخزين ومعالجة البيانات الشخصية التي قدمتها لنا ، من أجل تقديم خدمتنا. تقع على عاتقك مسؤولية الحفاظ على هاتفك والوصول إلى التطبيق آمنًا. لذلك ، نوصي بعدم كسر حماية الهاتف أو القضاء عليه ، وهي عملية إزالة قيود البرامج والقيود التي يفرضها نظام التشغيل الرسمي لجهازك. يمكن أن يجعل هاتفك عرضة للبرامج الضارة / الفيروسات / البرامج الضارة ، ويعرّض ميزات الأمان لهاتفك للخطر ، وقد يعني أن تطبيق Drug Deal لن يعمل بشكل صحيح أو على الإطلاق.
                              </p> <div><p>
                                  يستخدم التطبيق خدمات الطرف الثالث التي تعلن الشروط والأحكام الخاصة بها.

                                </p> <p>
                                  رابط إلى شروط وأحكام مزودي الخدمة التابعين للجهات الخارجية الذين يستخدمهم التطبيق
                                </p> <ul><li>
                                <a style="color: blue !important;" href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><li>
                                <a style="color: blue !important;" href="https://firebase.google.com/terms/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li>
                                <a style="color: blue !important;" href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><li>
                                <a style="color: blue !important;" href="https://www.facebook.com/legal/terms/plain_text_terms" target="_blank" rel="noopener noreferrer">Facebook</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p>
                                يجب أن تدرك أن هناك أشياء معينة لن تتحملها Drug Deal. تتطلب وظائف معينة في التطبيق أن يكون للتطبيق اتصال إنترنت نشط. يمكن أن يكون الاتصال Wi-Fi ، أو مقدمًا من قِبل مزود شبكة الهاتف المحمول ، ولكن لا يمكن أن تتحمل Drug Deal مسؤولية عدم عمل التطبيق بكامل وظائفه إذا لم يكن لديك وصول إلى Wi-Fi ، ولم يكن لديك أي من ترك بدل البيانات الخاصة بك.

                              </p> <p></p> <p>
                                إذا كنت تستخدم التطبيق خارج منطقة مزودة بشبكة Wi-Fi ، يجب أن تتذكر أن شروط الاتفاقية الخاصة بك مع مزود شبكة الهاتف المحمول ستظل سارية. ونتيجة لذلك ، قد يفرض موفر خدمة الجوال الخاص بك رسومًا على تكلفة البيانات طوال مدة الاتصال أثناء الوصول إلى التطبيق ، أو رسوم الطرف الثالث الأخرى. عند استخدام التطبيق ، فأنت تقبل المسؤولية عن أي رسوم من هذا القبيل ، بما في ذلك رسوم بيانات التجوال إذا كنت تستخدم التطبيق خارج إقليمك الأصلي (أي المنطقة أو البلد) دون إيقاف تجوال البيانات. إذا لم تكن دافع الفواتير للجهاز الذي تستخدم التطبيق عليه ، فيرجى العلم أننا نفترض أنك تلقيت إذنًا من دافع الفواتير لاستخدام التطبيق.

                              </p> <p>
                               على نفس المنوال ، لا يمكن لصفقة الدواء تحمل المسؤولية دائمًا عن الطريقة التي تستخدم بها التطبيق ، أي أنك تحتاج إلى التأكد من بقاء جهازك مشحونًا - إذا نفد شحن البطارية ولا يمكنك تشغيله للاستفادة من الخدمة ، لا يمكن للصفقة تحمل المسؤولية.
                              </p> <p>
                                فيما يتعلق بمسؤولية Drug Deal عن استخدامك للتطبيق ، عند استخدام التطبيق ، من المهم أن تضع في اعتبارك أنه على الرغم من سعينا لضمان تحديثه وتصحيحه في جميع الأوقات ، فإننا نعتمد على أطراف ثالثة في توفير المعلومات لنا حتى نتمكن من إتاحتها لك. لا تقبل Drug Deal أي مسؤولية عن أي خسارة ، مباشرة أو غير مباشرة ، تواجهها نتيجة الاعتماد بالكامل على هذه الوظيفة للتطبيق.

                              </p> <p>
                               في وقت ما ، قد نرغب في تحديث التطبيق. التطبيق متاح حاليًا على نظام التشغيل Android & iOS - قد تتغير متطلبات كلا النظامين (وأي أنظمة إضافية نقرر تمديد توفر التطبيق إليها) ، وستحتاج إلى تنزيل التحديثات إذا كنت ترغب في الاستمرار في استخدام تطبيق. لا تعد شركة Drug Deal بأنها ستقوم دائمًا بتحديث التطبيق بحيث يكون مناسبًا لك و / أو يعمل مع إصدار Android & iOS الذي قمت بتثبيته على جهازك. ومع ذلك ، فإنك تتعهد بقبول تحديثات التطبيق دائمًا عند تقديمها لك ، وقد نرغب أيضًا في التوقف عن تقديم التطبيق ، وقد ننهي استخدامه في أي وقت دون إشعارك بالإنهاء. ما لم نخبرك بخلاف ذلك ، عند أي إنهاء ، (أ) تنتهي الحقوق والتراخيص الممنوحة لك في هذه الشروط ؛ (ب) يجب عليك التوقف عن استخدام التطبيق و (إذا لزم الأمر) حذفه من جهازك.

                              </p> <p><strong>التغييرات على هذه الشروط والأحكام</strong></p> <p>
                               قد نقوم بتحديث الشروط والأحكام الخاصة بنا من وقت لآخر. وبالتالي ، ننصحك بمراجعة هذه الصفحة بشكل دوري لأية تغييرات. سنقوم بإعلامك بأي تغييرات عن طريق نشر الشروط والأحكام الجديدة على هذه الصفحة.

                              </p> <p>
                                تسري هذه الشروط والأحكام اعتبارًا من 2020-07-07
                              </p> <p><strong>اتصل بنا</strong></p> <p>
                                إذا كان لديك أي أسئلة أو اقتراحات حول الشروط والأحكام الخاصة بنا ، فلا تتردد في الاتصال بنا على support@drugdealapp.com.
                              </p>
                </div>`
  },

};

export default ar;