
import 'firebase/firestore';
import { mapGetters } from 'vuex'
// import UploadImages from "vue-upload-drop-images"
export default {
    name:"app-image-upload-v2",
    props:['fileId', 'imageId', 'src', 'imageName', ''],
    data(){
        return{
            rawFile:'',
            fileName:'',
            path:'',
            downloadURL:'',
            file:'',
            fileExist:false,
            progress:null,
            fileSizeExceeded:false,
            fileSizeExceededIndicies: [],


        }
    },
    components:{
        // UploadImages,
    },
    computed:{
        ...mapGetters({
            currentUser:'auth/currentUser'
        }),
        progressBar(){
            // this.progress = this.progress / 100
            return this.progress / 100
        }
    },
    watch:{
        fileSizeExceeded(newval){
            if(newval){
                this.showNotif('top-right')
            }
        }
    },
    methods:{
        deleteImage(imageName){
            const profilePicture = document.getElementById(this.imageId)
            profilePicture.src = 'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fupload-prescription.png?alt=media&token=b6292983-ca16-41be-a9d9-3458088d3581'
            const fileInput = document.getElementById(this.fileId)
            fileInput.value = "";
            this.$emit('deleteImage',imageName)
        },
        
   
    openImageUpload(){
        const fileUpload = document.getElementById(this.fileId)
        fileUpload.click()
    }, 
    async readUrl(event){
        const profilePicture = document.getElementById(this.imageId)
        if (event.target.files && event.target.files[0]) {
            this.rawFile = event.target.files[0]
            this.fileName = event.target.files[0].name
            var reader = new FileReader();
            
            this.fileExist = true
            var totalBytes = this.rawFile.size
            var _size = Math.floor(totalBytes/1000000)
            if(_size > 10){
                this.fileExist = false
                this.fileSizeExceeded = true
                profilePicture.src = 'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fupload-prescription.png?alt=media&token=b6292983-ca16-41be-a9d9-3458088d3581'
                // debugger
                const fileInput = document.getElementById(this.fileId)
                fileInput.value = "";
            }
            else{
                reader.onload = function (e) {
                profilePicture.src =  e.target.result;
                this.file = e.target.result
                }
                reader.readAsDataURL(event.target.files[0]);
                this.fileExist = true
                this.fileSizeExceeded = false
                this.$emit('uploadImage',this.rawFile)
            }
            
        }
    },
    showNotif (position) {
        

        this.$q.notify({
          color:"negative",
        //   textColor,
          icon:"report_problem",
          message:"File is too large",
          position,
        })
      }
  
    
},

    
}
