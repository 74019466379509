import Layout from '@/modules/layout/components/layout';

const ServicesPage = () =>
  import('@/modules/services/components/services-page.vue');

// const Prescriptions = () =>
//   import('@/modules/services/components/prescriptions.vue');
// const PrescriptionDetails = () =>
//   import('@/modules/services/components/prescription-details.vue');
// const EditPrescription = () =>
//   import('@/modules/services/components/edit-prescription.vue');
  export default [
    {
      path: '/:lang?',
      exact: true,
      component: Layout,
      children: [
        {
          name: 'services',
          path: 'services',
          component: ServicesPage,
        //   exact: true,
        //   meta: { auth: true },
        },
        // {
        //     name: 'prescriptions',
        //     path: 'prescriptions',
        //     component: Prescriptions,
        // },
        // {
        //   name: 'prescription-details',
        //   path: 'prescriptions/:id',
        //   component: PrescriptionDetails,
        // },
        // {
        //   name: 'edit-prescription',
        //   path: 'edit-prescription/:id',
        //   component: EditPrescription,
        // },

      ],
    },]