import jobsFormStore from '@/modules/jobs/jobs-form-store';
import jobsListStore from '@/modules/jobs/jobs-list-store';
import jobsViewStore from '@/modules/jobs/jobs-view-store';

export default {
    namespaced: true,
  
    modules: {
      form: jobsFormStore,
      list: jobsListStore,
      view: jobsViewStore

    },
  };
  