import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-prescription';
import firebase from 'firebase/app'

export class PrescriptionsService {
  static async list(callback1) {
    // ,callback2
    // await firebase.firestore().collection("drugIndex").onSnapshot(coll => {
    //   coll.forEach(doc => {
    //     let drug =  doc.data()
    //       drug.oldPrice = drug.oldPrice.toString().slice(0,7)
    //       drug.price = drug.price.toString().slice(0,7)
    //       callback(drug)
    //   });
    // });
    firebase.firestore().collection("prescription").onSnapshot(async coll => {
      // this.loading = true
      coll.docChanges().forEach(async (doc) => {
        // console.log('in doc change looppppp',doc.doc.data())
        let prescription =  doc.doc.data()
        
        prescription.createdAtUpdated = new Date(prescription.createdAt.seconds * 1000)
        prescription.createdAtUpdated = prescription.createdAtUpdated.toString().slice(0,15)
        // if(prescription.postStatus !== "deleted"){
          const creator = await firebase.firestore().collection("user").doc(prescription.createdBy).get()
          prescription.creator = creator.data()
          const comments = await firebase.firestore().collection('prescription').doc(prescription.id).collection('comments').limit(5).get()
          prescription['commentsLength'] = comments.size 
          callback1(prescription,doc.type)

      });

    });
  }


  // static async listCurrentUserPrescription(callback1) {
  //   // ,callback2
  //   // await firebase.firestore().collection("drugIndex").onSnapshot(coll => {
  //   //   coll.forEach(doc => {
  //   //     let drug =  doc.data()
  //   //       drug.oldPrice = drug.oldPrice.toString().slice(0,7)
  //   //       drug.price = drug.price.toString().slice(0,7)
  //   //       callback(drug)
  //   //   });
  //   // });
  //   await firebase.firestore().collection("prescription").onSnapshot(async coll => {
  //     console.log('in list service')
  //     // this.loading = true
  //     coll.docChanges().forEach(async (doc) => {
  //       // console.log('in doc change looppppp',doc.doc.data())
  //       let prescription =  doc.doc.data()
        
  //       prescription.createdAtUpdated = new Date(prescription.createdAt.seconds * 1000)
  //       prescription.createdAtUpdated = prescription.createdAtUpdated.toString().slice(0,15)
  //       if(prescription.postStatus !== "deleted"){
  //         const creator = await firebase.firestore().collection("user").doc(prescription.createdBy).get()
  //         prescription.creator = creator.data()
  //         const comments = await firebase.firestore().collection('prescription').doc(prescription.id).collection('comments').get()
  //         prescription['commentsLength'] = comments.size 
  //         callback1(prescription,doc.type)
  //         // this.setPrescriptionCreator(prescription,callback2)
  //           // const creator = await firebase.firestore().collection("user").doc(prescription.createdBy).get()
  //           // prescription.creator = creator.data()
  //           // const comments = await firebase.firestore().collection('prescription').doc(prescription.id).collection('comments').get()
  //           // prescription['commentsLength'] = comments.size 
  //           // console.log('pressss',prescription)
            
  //           // this.prescriptions = this.prescriptions.filter(I => I.id !== prescription.id)
  //           // this.prescriptions.push(prescription)
  //           // this.prescriptions.sort((a, b) =>{
  //           // var keyA = new Date(a.createdAt.seconds),
  //           //     keyB = new Date(b.createdAt.seconds);
  //           // if (keyA < keyB) return 1;
  //           // if (keyA > keyB) return -1;
  //           // return 0;
  //           // })
  //           // console.log('prescr in doc change',this.prescriptions)
  //       }
  //       // callback(prescription)
  //       // console.log('prescr in doc change deleted',this.prescriptions)
  //         // this.loading = false
  //     });
  //   // this.prescriptions.forEach(async (presc) => {
  //   // if(presc.createdBy){
  //   //   presc.creator = ""
  //   //   const creator = await firebase.firestore().collection("user").doc(presc.createdBy).get()
  //   //   presc.creator = creator.data()
  //   //   this.prescriptionsWithCreators.push(presc)
  //   //   console.log('prescr in doc change',presc)
  //   // }
  //   // })
  //   // console.log('prescr in doc change',this.prescriptions)
  //   // this.loading = false
  //   });
  // }




  static async setPrescriptionCreator(prescription,callback){
    const creator = await firebase.firestore().collection("user").doc(prescription.createdBy).get()
    prescription.creator = creator.data()
    callback(prescription)
            // const comments = await firebase.firestore().collection('prescription').doc(prescription.id).collection('comments').get()
            // prescription['commentsLength'] = comments.size 
            // console.log('pressss',prescription)
    // ids.forEach(id => {
    //     firebase.firestore().collection('complaints').doc(id).collection('messages').onSnapshot(subSnapshot => {
    //         subSnapshot.docs.forEach(subDoc=>{
    //             callback({id:id,message:{...subDoc.data(),id:subDoc.id}})
    //         })
    //     })
    // })
  }
  static async find(id,callback1,callback2){
    await firebase.firestore().collection('prescription').doc(id).onSnapshot({ includeMetadataChanges: true },coll => {
      // var source = coll.metadata.fromCache ? "local cache" : "server";
      callback1(coll.data())
      this.findUser(coll.data().createdBy,callback2)
      
      // coll.docChanges().forEach(change => {
      //   let prescription =  change.doc.data()
      //     // drug.oldPrice = drug.oldPrice.toString().slice(0,7)
      //     // drug.price = drug.price.toString().slice(0,7)
      //   callback(prescription,change.type)
      // });
    });
  }
  // static async findComments(prescriptionId,callback){
  //   await firebase.firestore().collection('prescription').doc(prescriptionId).collection('comments').onSnapshot(async (snapshot) => {
  //     snapshot.docChanges().forEach(async (doc) => {
  //        if(doc.type != "removed"){
  //             let comment = doc.doc.data()
  //             comment.createdAtUpdated = new Date(comment.createdAt.seconds * 1000)
  //             comment.createdAtUpdated = comment.createdAtUpdated.toString().slice(0,15)
  //             let key = comment.id
  //             const creator = await firebase.firestore().collection('user').doc(comment.createdBy).get()
  //             comment.creator = creator.data()
  //             this.comments = this.comments.filter(I => I.id !== comment.id)
  //             this.comments.sort((a, b) =>{
  //                 var keyA = new Date(a.createdAt.seconds),
  //                     keyB = new Date(b.createdAt.seconds);
  //                 if (keyA < keyB) return 1;
  //                 if (keyA > keyB) return -1;
  //                 return 0;
  //             })
  //             this.comments.push(comment)
  //             this.commentsObject[key] = comment
  //         }
        
         
  //     }) 
   
      
          
  //     })
  // }


  static async findUser(userId,callback){
    await firebase.firestore().collection('user').doc(userId).onSnapshot((coll) => {
      // console.log('user doc type,',coll.type);
      callback(coll.data())
     })
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PRESCRIPTION_CREATE($data: PrescriptionInput!) {
          prescriptionCreate(data: $data) {
            id
            title
            description
            image
            createdAt
            createdBy
            postStatus
            viewers
            subscribers
          }
        }
      `,

      variables: {
        data,
      },
    });
    const id = response.data.prescriptionCreate.id
    firebase.database().ref('prescriptions').child(id).set(false)
    return response.data.prescriptionCreate;
  }
  static async update(id, data) {
    // console.log('serviceee',id)
    // console.log('data',data)
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PRESCRIPTION_UPDATE(
          $id: String!
          $data: PrescriptionInput!
        ) {
          prescriptionUpdate(id: $id, data: $data) {
            title
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });
    // console.log('response.data.prescriptionUpdate',response.data.prescriptionUpdate)
    return response.data.prescriptionUpdate;
  }
  static async createComment(data,id) {

    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation COMMENT_CREATE($data: CommentInput!,$id: String!) {
          commentCreate(data: $data,id: $id) {
            comment
            createdBy
          }
        }
      `,

      variables: {
        data,
        id
      },
    });
    return response.data.commentCreate;
  }
  static async convertImage(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation IMAGE_CONVERT($data: String!) {
          imageConvert(data: $data) 
        }
      `,
      variables: {
        data
      },
    });
    return response.data.convertImage;
  }
  static async updateComment(data,prescriptionId,commentId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation COMMENT_UPDATE($data: CommentInput!,$prescriptionId: String!,$commentId: String!) {
          commentUpdate(data: $data, prescriptionId: $prescriptionId, commentId: $commentId) {
            comment
            createdBy
          }
        }
      `,

      variables: {
        data,
        prescriptionId,
        commentId
      },
    });

    return response.data.commentUpdate;
  }

  static async destroyComment(prescId,ids) {

    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation COMMENT_DESTROY($prescId: String!,$ids: [String!]!) {
          commentDestroy(prescId: $prescId, ids: $ids) 
        }
      `,
      variables: {
        prescId,
        ids
      },
    });

    return response.data.commentDestroy;
  }

 
}