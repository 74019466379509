import gql from "graphql-tag";
import graphqlClient from "@/shared/graphql/client-job";
import firebase from "firebase/app";
import "firebase/firestore";
export class JobsService {
  static async create(data) {
    console.log(data, "data from service");
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation JOB_CREATE($data: JobInput!) {
          jobCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });
    return response.data.jobCreate;
  }
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation JOB_UPDATE($id: String!, $data: JobInput!) {
          jobUpdate(id: $id, data: $data) {
            name
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.jobUpdate;
  }
  static async fetch() {
    await firebase
      .firestore()
      .collection("job")
      .onSnapshot((coll) => {
        // this.loading = true
        coll.docChanges().forEach(async (doc) => {
          // this.loading = true
          let job = doc.doc.data();
          if (!this.isScreenXs) {
            job.address = job.address.toString().slice(0, 15) + "...";
          } else {
            job.address = job.address.toString().slice(0, 7) + "...";
          }

          if (job.createdAt) {
            job.createdAtUpdated = new Date(job.createdAt.seconds * 1000);
            job.createdAtUpdated = job.createdAtUpdated.toString().slice(0, 15);
          }
          if (job.postStatus !== "deleted") {
            if (!this.isScreenXs) {
              job.description = job.description.slice(0, 340) + "...";
            } else {
              job.description = job.description.slice(0, 100) + "...";
            }
            const creator = await firebase
              .firestore()
              .collection("user")
              .doc(job.createdBy)
              .get();
            job.creator = creator.data();
            this.jobs = this.jobs.filter((I) => I.id !== job.id);
            this.jobs.push(job);
            this.jobsWithoutFilter.push(job);
            this.jobs.sort((a, b) => {
              var keyA = new Date(a.createdAt.seconds),
                keyB = new Date(b.createdAt.seconds);
              // Compare the 2 dates
              if (keyA < keyB) return 1;
              if (keyA > keyB) return -1;
              return 0;
            });
            this.idOfUpdatedJob = job.id;
          }
          // this.loading = false
        });

        // this.updatedJobs = this.jobs
        this.loading = false;
        if (this.jobs.length == 0) this.loading = false;
        // this.jobsWithoutFilter = this.jobs
      });
  }

  static async list(startAt, limit, callback) {
    // .limit(6)
    firebase
      .firestore()
      .collection("job")
      .orderBy("createdAt", "desc")
      // .startAt(4)
      // .startAfter(startAt)
      // .limit(6)
      // .endAt(startAt + limit)
      .onSnapshot(async (coll) => {
        coll.docChanges().forEach(async (doc) => {
          let job = doc.doc.data();
          // prescription.createdAtUpdated = new Date(prescription.createdAt.seconds * 1000)
          // prescription.createdAtUpdated = prescription.createdAtUpdated.toString().slice(0,15)
          const creator = await firebase
            .firestore()
            .collection("user")
            .doc(job.createdBy)
            .get();
          job.creator = creator.data();
          // const comments = await firebase.firestore().collection('prescription').doc(prescription.id).collection('comments').get()
          // prescription['commentsLength'] = comments.size
          callback(job, doc.type);
        });
      });
  }

  static async find(id, callback1, callback2) {
    await firebase
      .firestore()
      .collection("job")
      .doc(id)
      .onSnapshot({ includeMetadataChanges: true }, (coll) => {
        // var source = coll.metadata.fromCache ? "local cache" : "server";
        // console.log('source Prescription',source)
        // console.log('coll ',coll.data())
        callback1(coll.data());
        this.findUser(coll.data().createdBy, callback2);

        // coll.docChanges().forEach(change => {
        //   let prescription =  change.doc.data()
        //     // drug.oldPrice = drug.oldPrice.toString().slice(0,7)
        //     // drug.price = drug.price.toString().slice(0,7)
        //   callback(prescription,change.type)
        // });
      });
  }
  static async findCity({ cityId, regionId }, callback) {
    await firebase
      .firestore()
      .collection("city")
      .doc(cityId)
      .onSnapshot({ includeMetadataChanges: true }, async (coll) => {
        const cityData = coll.data();
        if (regionId) {
          const region = await firebase
            .firestore()
            .collection("city")
            .doc(cityId)
            .collection("regions")
            .doc(regionId)
            .get();
          const regionData = region.data();
          callback({ cityData, regionData });
        } else {
          callback({ cityData });
        }
      });
  }
  static async findJobPosition(id, callback) {
    await firebase
      .firestore()
      .collection("jobPosition")
      .doc(id)
      .onSnapshot({ includeMetadataChanges: true }, (coll) => {
        // var source = coll.metadata.fromCache ? "local cache" : "server";
        // console.log('source Prescription',source)
        // console.log('coll ',coll.data())
        callback(coll.data());
      });
  }
  static async findWorkplace(id, callback) {
    await firebase
      .firestore()
      .collection("workplace")
      .doc(id)
      .onSnapshot({ includeMetadataChanges: true }, (coll) => {
        // var source = coll.metadata.fromCache ? "local cache" : "server";
        // console.log('source Prescription',source)
        // console.log('coll ',coll.data())
        callback(coll.data());
      });
  }
  static async listWorkplace(callback) {
    await firebase
      .firestore()
      .collection("workplace")
      .orderBy("index", "asc")
      .onSnapshot(async (coll) => {
        // console.log('in list service workplace',coll)
        coll.docs.forEach(async (doc) => {
          let workplace = doc.data();
          workplace.id = doc.id;
          callback(workplace);
        });
      });
  }
  static async listJobPosition(callback) {
    await firebase
      .firestore()
      .collection("jobPosition")
      .onSnapshot(async (coll) => {
        // docChanges()
        coll.docs.forEach(async (doc) => {
          // let jobPosition =  doc.doc.data()
          let jobPosition = doc.data();
          jobPosition["id"] = doc.id;
          // console.log('job position',jobPosition);
          callback(jobPosition);
        });
      });
  }
  static async listCity(callback, callback2) {
    await firebase
      .firestore()
      .collection("city")
      .onSnapshot(async (coll) => {
        coll.docs.forEach(async (doc) => {
          let city = doc.data();
          city.id = doc.id;
          callback(city);
          let cityRegions = [];
          const regions = await firebase
            .firestore()
            .collection(`city/${city.id}/regions`)
            .get();
          regions.forEach((doc) => {
            let region = doc.data();
            region.id = doc.id;
            cityRegions.push(region);
          });
          // console.log('jobCitiesListener',jobCitiesListener);
          callback2(city.id, cityRegions);
        });
      });
  }
  static async listRegion(callback) {
    await firebase
      .firestore()
      .collection("region")
      .onSnapshot(async (coll) => {
        // console.log('in list service region')
        coll.docChanges().forEach(async (doc) => {
          let region = doc.doc.data();
          callback(region, doc.type);
        });
      });
  }
  static async findUser(userId, callback) {
    await firebase
      .firestore()
      .collection("user")
      .doc(userId)
      .onSnapshot((coll) => {
        callback(coll.data());
      });
  }
}
