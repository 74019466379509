import Layout from '@/modules/layout/components/layout';
// import { i18n } from '@/i18n';
const NotificationListPage = () =>
  import('@/modules/notification/components/notification-list-page.vue');


export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,    
    children: [
      {
        name: 'notification',
        path: 'notification',
        component: NotificationListPage,
        exact: true,
        meta: { auth: true },
        // beforeEnter: (to, from, next) => {
          // document.title =
          //   i18n('app.title') +
          //   ' | ' +
          //   i18n('entities.notification.title') 
        //   next();
        // },
      },
    ],
  },
];
