
// import{i18n} from '@/i18n'
export default {
    name:'app-drug-index-view-modal',
    props:['openDialog','record','name','category','company','info','oldPrice','newPrice'],
    computed:{
        dialog:{
            get(){
                return this.openDialog
            },
            set(){
                this.$emit('closeDialog')
            }
        }
    },
    methods:{
      i18n(key, args) {
      return this.$t(key, args);
    },
        close(){
            this.$emit('closeDialog')
        },
      capitalize(s)  {
        if (typeof s !== 'string') return ''
        s = s.toLowerCase()
        s = s.charAt(0).toUpperCase() + s.slice(1)
        return s
        }
    },
    
      created(){
    this.record['mechOfAction'] = this.capitalize(this.record['mechOfAction'])
    this.record['genericName'] = this.record['genericName'].map(element => {
       element = element.trim()
       return this.capitalize(element)
     })
     this.record['company'] = this.record['company'].map(element => {
       element = element.trim()
       return this.capitalize(element)
     })
    this.paragraphs = this.record['mechOfAction'].split("\n")  
    }
    

}
