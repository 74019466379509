import firebase from 'firebase';
import 'firebase/auth';
import config from '@/config';
import 'firebase/firestore';
import 'firebase/app-check';
export default function firebaseInit() {
firebase.initializeApp(config.firebaseConfig)
firebase.analytics()
// const app = firebase.initializeApp(config.firebaseConfig);  
// const appCheck = firebase.appCheck();
// appCheck.activate('6Le_NgobAAAAAOmxzraGianR1CvWqapeUeTRBGiG');

firebase.firestore().enablePersistence({synchronizeTabs:true})
    // .catch(function(err) {
    //   if (err.code == 'failed-precondition') {
    //     console.log('failed-precondition',err)
    //   } else if (err.code == 'unimplemented') {
    //     console.log('unimplemented')
    //   }
    // });
// app.auth();  
// const db = app.firestore()


}