
export default {
    props:['visible','item'],
    name:'app-no-current-user-modal',
    computed:{
        dialogVisible:{
            get(){
                return this.visible
            },
            set(){
                 this.$emit('close')
            }
        },
    },
    methods:{
        login(){
            // this.noCurrentUser = !this.noCurrentUser
            this.$emit('login')
        },
        i18n(key, args) {
            return this.$t(key, args);
        },
    }
}
