
import { mapGetters } from 'vuex';
import { scroll } from 'quasar';
const { getScrollTarget, setScrollPosition } = scroll;
import NoCurrentUserModal from "@/modules/layout/components/no-current-user-modal.vue";

export default {
  data(){
    return{
      modalVisible: false,
      dialogVisible: false,
      item:''
    }
  },

  name: 'app-menu',

  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),

  },
  components: {
      [NoCurrentUserModal.name]: NoCurrentUserModal,
  },
  methods: {
    classFor(path) {

      // debugger
      // if (exact) {        
        return {
          active: this.$route.name == path,
        };
      // }
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    // scrollHandler(scroll) {
    //   // console.log('scroll handler',scroll.setScrollPosition);
    // //   if (this.$route.name == 'home') {
    // //     const about = document.getElementById('about');
    // //     const features = document.getElementById('services');
    // //     const contact = document.getElementById('contact');

    // //     if (!this.isScreen_md) {
    // //       const homeTab = document.getElementById(
    // //         'homeTab'
    // //       );
    // //       const aboutTab = document.getElementById(
    // //         'aboutTab',
    // //       );
    // //       const featuresTab = document.getElementById(
    // //         'featuresTab'
    // //       );
    // //       const contactTab = document.getElementById(
    // //         'contactTab'
    // //       );

    // //       if (
    // //         scroll.position >= 0 &&
    // //         scroll.position < about.offsetTop
    // //       ) {
    // //         this.activeTab = 'home';
    // //         homeTab.classList.remove('q-tab--inactive');
    // //         aboutTab.classList.remove('q-tab--active');
    // //         featuresTab.classList.remove('q-tab--active');
    // //         contactTab.classList.remove('q-tab--active');
    // //         homeTab.classList.add('q-tab--active');
    // //       } else if (
    // //         scroll.position >= about.offsetTop &&
    // //         scroll.position < features.offsetTop
    // //       ) {
    // //         this.activeTab = 'about';

    // //         aboutTab.classList.remove('q-tab--inactive');
    // //         homeTab.classList.remove('q-tab--active');
    // //         featuresTab.classList.remove('q-tab--active');
    // //         contactTab.classList.remove('q-tab--active');
    // //         aboutTab.classList.add('q-tab--active');
    // //       } else if (
    // //         scroll.position >= features.offsetTop &&
    // //         scroll.position <
    // //           contact.offsetTop - contact.scrollHeight - 200
    // //       ) {
    // //         // console.log('features')
    // //         // this.navigation('features')
    // //         this.activeTab = 'features';
    // //         homeTab.classList.remove('q-tab--active');
    // //         aboutTab.classList.remove('q-tab--active');
    // //         contactTab.classList.remove('q-tab--active');
    // //         featuresTab.classList.remove('q-tab--inactive');
    // //         featuresTab.classList.add('q-tab--active');
    // //       } else {
    // //         // this.navigation('contact')
    // //         this.activeTab = 'contact';
    // //         homeTab.classList.remove('q-tab--active');
    // //         aboutTab.classList.remove('q-tab--active');
    // //         featuresTab.classList.remove('q-tab--active');
    // //         contactTab.classList.remove('q-tab--inactive');
    // //         contactTab.classList.add('q-tab--active');
    // //       }
    // //     }
    // //     if(this.isMobile){
    // //       console.log('scroll mobile')
    // //       this.scrollHandlerSideMenuMobile(scroll)
    // //       return
    // //     }
    // //     if (this.isScreen_md) {
    // //       this.scrollHandlerSideMenu(scroll)
        
    // //     }
    // //   }
    // },
    closeMenu(value){
      const lang = localStorage.getItem('language')
      // if(value != 'drug-interactions'){
      this.item = value
      if(this.currentUser){
        this.$router.push(`/${lang}/${value}?page=1`)
      }
      else{
        if(value == 'drug-index'){
          this.$router.push(`/${lang}/${value}?page=1`)
        }
        else{
          
          this.modalVisible = true;
          this.dialogVisible = true;
          
        }
      }
    },
    close() {
      // this.prescriptionDialog = false
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    login(){
      this.$emit('login')
      this.$emit('toggleShowServicesMenu')
    },
    closeNav(eleId) {
      this.$emit('closeNav',eleId)
    },

    checkRoute(eleId){
      // debugger
      if(this.$router.currentRoute.name != "home"){
        this.$router.push('/')
        setTimeout(() => this.handleScroll(eleId), 500);
      }
      else{
        setTimeout(() => this.handleScroll(eleId), 500);
      }
      
    },
    handleScroll(eleId) {
      // debugger
      
      const ele = document.getElementById(eleId); // You need to get your element here
      const target = getScrollTarget(ele);
      const offset = ele.offsetTop;
      const duration = 100;
      // console.log(setScrollPosition(getScrollTarget(ele), offset, duration));
      setScrollPosition(target, offset, duration);      
    },
  }
};
