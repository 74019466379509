import { PrescriptionsService } from '@/modules/prescriptions/prescriptions-service';
import Errors from '@/shared/error/errors';
// import firebase from 'firebase'
// import { routerAsync } from '@/app-module';
// import Message from '@/shared/message/message';
// import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    saveCommentLoading:false,
    record: null,
    recordCreated: null
  },

  getters: {
    record: (state) => state.record,
    recordCreated: (state) => state.recordCreated,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    saveCommentLoading: (state) => !!state.saveCommentLoading,

  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
      state.recordCreated = null;

    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
      
    },

    CREATE_SUCCESS(state,recordCreated) {
      state.saveLoading = false;
      state.recordCreated = recordCreated
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },
    CREATE_COMMENT_STARTED(state) {
      state.saveCommentLoading = true;
    },

    CREATE_COMMENT_SUCCESS(state) {
      state.saveCommentLoading = false;
    },

    CREATE_COMMENT_ERROR(state) {
      state.saveCommentLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    // async doFind({ commit }, id) {
    //   try {
    //     commit('FIND_STARTED');

    //     const record = await ProductService.find(id);

    //     commit('FIND_SUCCESS', record);
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FIND_ERROR');
    //     routerAsync().push('/product');
    //   }
    // },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const record = await PrescriptionsService.create(values);
        commit('CREATE_SUCCESS',record);
        return record
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
    async doUpdate({ commit }, { id, values }) {
      try {
       
        commit('UPDATE_STARTED');

        await PrescriptionsService.update(id, values);

        commit('UPDATE_SUCCESS');
        // Message.success(i18n('entities.product.update.success'));
        // routerAsync().push('/product');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    // ,dispatch,rootGetters
    async doCreateComment({ commit  }, values) {
      // debugger
   
      const id = values.id
      delete values.id
      // console.log('idd',id)
      // console.log('valuessss',values)
      try {
        commit('CREATE_COMMENT_STARTED');
        await PrescriptionsService.createComment(values,id);
        // let post = (await firebase.firestore().collection('prescription').doc(id).get()).data()
        // if(post && rootGetters['auth/currentUser'] && rootGetters['auth/currentUser'].id !== post.createdBy){
        //   dispatch('notification/notificationCreate',
        //     {
        //       title:'New Comment',
        //       message:`you received a new comment on your prescription '${post.title}'`,
        //       to:post.createdBy
        //     },
        //     {root:true})
        // }
        commit('CREATE_COMMENT_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_COMMENT_ERROR');
      }
    },
    async doConvertImage({ commit }, value) {
      // console.log('id#####',values.id)
      // const id = values.id
      // delete values.id
      // console.log('idd',id)
      // console.log('valuessss',values)
      try {
        commit('CREATE_STARTED');
        await PrescriptionsService.convertImage(value);
        commit('CREATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
    async doUpdateComment({ commit },  { prescId, commentId, values} ) {
    
      // const prescId = values.prescId
      // const commentId = values.commentId
      // delete values.prescId
      // delete values.commentId
      
      try {
        commit('UPDATE_STARTED');
        const updatedComment = await PrescriptionsService.updateComment(values, prescId, commentId);
        commit('UPDATE_SUCCESS');
        return updatedComment
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
