import Layout from '@/modules/layout/components/layout';
// import { i18n } from '@/i18n';

const Offers = () =>
  import('@/modules/offers/components/offer-list-page.vue');
export default [
    {
      path: '/:lang?',
      exact: true,
      component: Layout,
      
      children: [
        {
        name: 'offers',
        path: 'offers/:id?',
        component: Offers,
        meta: { auth: true },
          beforeEnter: (to, from, next) => {
            // document.title =
            //   i18n('app.title') +
            //   ' | ' +
            //   i18n('drugDeal.services.offers') 
          next();
        },
        },
      ]
    }
]