
// import { i18n } from '@/i18n';
import { mapGetters } from 'vuex';
export default {
    name:'app-phone-verify',
    data(){
        return {
            code:'',
            verified:false,
        }
    },
    computed:{
        ...mapGetters({
            loadingUpdateProfile:'auth/loadingUpdateProfile'
        })
    },
    methods:{
        doSubmit(){
            this.$emit('submit',this.code)
        },
          i18n(key, args) {
            return this.$t(key, args);
            },
        handleOnCompleteVerifiy(value) {
            if (this.hasNumber(value)) {
                this.code = value
                this.error = '';
                this.verified = true
            } else {
                this.error = this.$t('auth.error.verificationCodeShouldOnlyBeNumbers');
                this.code = ''
                this.verified = false
            }
        },
        handleOnChangeVerifiy(value){
            if (value.length > 0) {
                this.error = '';
            }
            if (value.length < 6) {
                this.code = '';
                this.verified = false
            }
        },
        hasNumber(myString) {
            return /^\d+$/.test(myString);
            },
    }
}
