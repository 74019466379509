
// import {i18n} from '@/i18n'
export default {
    name:'app-error-dialog',
    props:['description'],
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
        },
    }
}
