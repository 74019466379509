
// import { i18n } from '@/i18n';

export default {
  name: 'app-importer-toolbar',

  props: ['storePath'],

  computed: {
    hasRows() {
      return this.$store.getters[
        `${this.storePath}/hasRows`
      ];
    },
    importing() {
      return this.$store.getters[
        `${this.storePath}/importing`
      ];
    },
    completed() {
      return this.$store.getters[
        `${this.storePath}/completed`
      ];
    },
    hint() {
      return this.$store.getters[`${this.storePath}/hint`];
    },
  },

  methods: {
    async doNew() {
      try {
        await this.$confirm(
          this.$t('common.areYouSure'),
          this.$t('common.confirm'),
          {
            confirmButtonText: this.$t('common.yes'),
            cancelButtonText: this.$t('common.no'),
            type: 'warning',
          },
        );

        return this.$store.dispatch(
          `${this.storePath}/doReset`,
        );
      } catch (error) {
        // no
      }
    },

    async doDiscard() {
      try {
        await this.$confirm(
          this.$t('importer.list.discardConfirm'),
          this.$t('common.confirm'),
          {
            confirmButtonText: this.$t('common.yes'),
            cancelButtonText: this.$t('common.no'),
            type: 'warning',
          },
        );

        return this.$store.dispatch(
          `${this.storePath}/doReset`,
        );
      } catch (error) {
        // no
      }
    },

    doReset() {
      return this.$store.dispatch(
        `${this.storePath}/doReset`,
      );
    },
    doPause() {
      return this.$store.dispatch(
        `${this.storePath}/doPause`,
      );
    },
    doImport() {
      return this.$store.dispatch(
        `${this.storePath}/doImport`,
      );
    },
    doDownloadTemplate() {
      return this.$store.dispatch(
        `${this.storePath}/doDownloadTemplate`,
      );
    },
  },
};
