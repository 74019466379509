import { JobsService } from "@/modules/jobs/jobs-service";
import Errors from "@/shared/error/errors";
import firebase from "firebase/app";
import { firestoreAction, vuexfireMutations } from "vuexfire";

export default {
  namespaced: true,

  state: {
    // ref: firebase.firestore().collection("job"),
    // rows: [],
    count: 0,
    loading: false,
    userLoading: false,
    filter: {},
    pagination: {},
    sorter: {},
    jobs: [],
    jobsWithoutFilter: [],
    workplaces: [],
    jobPositions: [],
    cities: [],
    deactivationReasons: [],
    // regions:[],
    regions: {},
    currentUserJobs: [],
    user: {},
    currentRoute: undefined,
    table: null,
    mountedTable: false,
    mountedCityTable: false,
    mountedPositionTable: false,
    mountedWorkplaceTable: false,
    mountedDeactivationReasonTable: false,
    startJob: null,

    //#region [vuexfire]
    rows: [],
    rowsFromRoute: [],
    users: [],
    rowsCreator: [],
    addedRowId: null,
    addedCreatorId: null,
    loadMoreLastRecord: null,
    loadFirstRecord: null,
    recordAfterLast: [],
    recordBeforeFirst: [],
    loadingAddMoreJobs: true,
    detailsLoading: false,
    //#endregion

    // vuexFire
    // allJobs: [],
    // jobCreator:[],
    // users:[],
    // addedJobId:null,
    // addedUserId:null,
    // jobsBatch: [],
    // loadMoreLastJob: null,
    // loadFirstJob: null,
    // moreJobsPending: true,
    // disableNext: false,
    // disablePrevious: false,
    // recordAfterLast: [],
    // recordBeforeFirst: []
  },
  getters: {
    loading: (state) => state.loading,
    detailsLoading: (state) => state.detailsLoading,
    userLoading: (state) => state.userLoading,
    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction = sorter.order === "descending" ? "DESC" : "ASC";

      return `${sorter.prop}_${direction}`;
    },

    //#region [vuexfire]
    rows: (state) => state.rows || [],
    rowsFromRoute: (state) => state.rowsFromRoute || [],
    users: (state) => state.users,
    rowsCreator: (state) => state.rowsCreator,
    addedRowId: (state) => state.addedRowId,
    addedCreatorId: (state) => state.addedCreatorId,
    loadMoreLastRecord: (state) => state.loadMoreLastRecord,
    loadFirstRecord: (state) => state.loadFirstRecord,
    recordAfterLast: (state) => state.recordAfterLast,
    recordBeforeFirst: (state) => state.recordBeforeFirst,
    //#endregion

    workplaces: (state) => state.workplaces,
    jobPositions: (state) => state.jobPositions,
    deactivationReasons: (state) => state.deactivationReasons,
    cities: (state) => state.cities,
    regions: (state) => state.regions,
    mountedTable: (state) => state.mountedTable,
    mountedCityTable: (state) => state.mountedCityTable,
    mountedPositionTable: (state) => state.mountedPositionTable,
    mountedWorkplaceTable: (state) => state.mountedWorkplaceTable,
    mountedDeactivationReasonTable: (state) =>
      state.mountedDeactivationReasonTable,
    loadingAddMoreJobs: (state) => state.loadingAddMoreJobs,

    currentRoute: (state) => state.currentRoute,
    user: (state) => state.user,
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },
    // TABLE_CITY_MOUNTED(state, payload) {
    //   state.table = payload;
    // },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },
    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },
    // , payload
    FETCH_STARTED(state) {
      state.loading = true;
    },
    // , payload
    FETCH_SUCCESS(state) {
      state.loading = false;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    FETCH_START_JOB(state, payload) {
      state.startJob = payload;
      // console.log('state startJob',state.startJob);
    },
    APPEND_TO_CURRENT_USER_JOBS(state, payload) {
      // console.log('payload in  prescriptions',payload)
      if (payload.docType == "added") {
        if (
          payload.doc.postStatus !== "deleted" &&
          payload.doc.postStatus !== "pending"
        ) {
          state.currentUserJobs.push(payload.doc);
        }
      }
      if (payload.docType == "removed") {
        state.currentUserJobs = state.currentUserJobs.filter(
          (el) => el.id !== payload.doc.id
        );
      }
      if (payload.docType == "modified") {
        // console.log('modified',payload.doc.postStatus);
        if (
          payload.doc.postStatus == "deleted" ||
          payload.doc.postStatus == "pending"
        ) {
          state.currentUserJobs = state.currentUserJobs.filter(
            (el) => el.id !== payload.doc.id
          );
        } else {
          var foundIndex = state.currentUserJobs.findIndex(
            (el) => el.id == payload.doc.id
          );
          if (foundIndex != -1) {
            Object.assign(state.currentUserJobs[foundIndex], payload.doc);
          } else {
            state.currentUserJobs.push(payload.doc);
          }
        }
      }
    },
    APPEND_TO_JOBS_WITHOUT_FILTER(state, payload) {
      // console.log('payload in  prescriptions',payload)
      if (payload.docType == "added") {
        if (
          payload.doc.postStatus !== "deleted" &&
          payload.doc.postStatus !== "pending"
        ) {
          state.jobsWithoutFilter.push(payload.doc);
        }
      }
      if (payload.docType == "removed") {
        state.jobsWithoutFilter = state.jobsWithoutFilter.filter(
          (el) => el.id !== payload.doc.id
        );
      }
      if (payload.docType == "modified") {
        // console.log('modified',payload.doc.postStatus);

        if (
          payload.doc.postStatus == "deleted" ||
          payload.doc.postStatus == "pending"
        ) {
          state.jobsWithoutFilter = state.jobsWithoutFilter.filter(
            (el) => el.id !== payload.doc.id
          );
        } else {
          var foundIndex = state.jobsWithoutFilter.findIndex(
            (el) => el.id == payload.doc.id
          );
          // Object.assign(state.jobs[foundIndex], payload.doc)
          if (foundIndex != -1) {
            Object.assign(state.jobsWithoutFilter[foundIndex], payload.doc);
          } else {
            state.jobsWithoutFilter.push(payload.doc);
          }
        }
      }
    },
    APPEND_TO_JOBS(state, payload) {
      var foundIndexAdd = state.jobs.findIndex((el) => el.id == payload.doc.id);
      if (foundIndexAdd != -1) {
        Object.assign(state.jobs[foundIndexAdd], payload.doc);
      } else {
        state.jobs.push(payload.doc);
      }
    },

    APPEND_TO_WORKPLACES(state, payload) {
      state.workplaces.push(payload);
    },
    APPEND_TO_JOBPOSITIONS(state, payload) {
      state.jobPositions.push(payload);
    },
    APPEND_TO_CITIES(state, payload) {
      state.cities.push(payload);
    },
    APPEND_TO_REGIONS(state, payload) {
      state.regions[payload.cityId] = payload.regions;
      // console.log('state regions',state.regions);
    },
    APPEND_TO_DEACTIVATIONREASONS(state, payload) {
      state.deactivationReasons.push(payload);
    },
    SET_MOUNTEDTABLE(state, payload) {
      state.mountedTable = payload;
    },
    SET_MOUNTEDCITYTABLE(state, payload) {
      state.mountedCityTable = payload;
    },
    SET_MOUNTEDPOSITIONTABLE(state, payload) {
      state.mountedPositionTable = payload;
    },
    SET_MOUNTEDWORKPLACETABLE(state, payload) {
      state.mountedWorkplaceTable = payload;
    },
    SET_MOUNTEDDEACTIVATIONREASON(state, payload) {
      state.mountedDeactivationReasonTable = payload;
    },
    SET_LOADING_ADD_MORE_JOBS(state, payload) {
      state.loadingAddMoreJobs = payload;
    },

    FETCH_USER_STARTED(state) {
      state.userLoading = true;
    },
    FETCH_USER_SUCCESS(state, user) {
      state.user = user;
      state.userLoading = false;
    },
    FETCH_USER_ERROR(state) {
      state.userLoading = false;
    },
    SET_CURRENTROUTE(state, payload) {
      state.currentRoute = payload;
    },
    SET_JOBS(state, payload) {
      state.jobs = payload;
    },
    ...vuexfireMutations,
    // own mutations

    //#region [ VuexfireMutations ]
    ...vuexfireMutations,
    SET_ADDED_ROW_ID(state, payload) {
      state.addedRowId = payload;
    },
    SET_ADDED_CREATOR_ID(state, payload) {
      state.addedCreatorId = payload;
    },
    async SET_CREATOR_TO_ROW(state) {
      const creator = await firebase
        .firestore()
        .collection("user")
        .doc(state.addedCreatorId)
        .get();
      state.rowsCreator.push(creator.data());
      const addedRow = state.rows.find((el) => el.id == state.addedRowId);
      addedRow["creator"] = creator.data();
    },
    RESET_CURSOR(state) {
      state.loadMoreLastRecord = null;
      state.loadFirstRecord = null;
    },
    SET_LOADMORE_LAST(state) {
      state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc;
      state.recordAfterLast = state.rows[state.rows.length - 1];
    },
    SET_LOADFIRSTRECORD(state) {
      state.loadFirstRecord = state.rows[0]._doc;
    },
    SET_ROWS(state, payload) {
      state.rows = payload;
    },
    SET_ROWS_FROM_ROUTE(state, payload) {
      state.rowsFromRoute = payload;
    },
    DETAILS_LOADING_STARTED(state) {
      state.detailsLoading = true;
    },
    DETAILS_LOADING_SUCCESS(state) {
      state.detailsLoading = false;
    },
    //#endregion
  },

  actions: {
    ////#region [vuexfire]
    doFetch: async ({ getters, commit }, payload) => {
      // debugger
      commit("FETCH_STARTED");
      commit("SET_ROWS", []);
      // const customSerializer = (doc) => {
      //   const data = doc.data();
      //   Object.defineProperty(data, "_doc", { value: doc });
      //   data["creator"] = "";
      //   if (payload.currentRoute == "my-jobs") {
      //     data["creator"] = payload.currentUser;
      //   } else {
      //     // if (getters.rowsCreator.length > 0) {
      //     //   var creatorIndex = getters.rowsCreator.findIndex(
      //     //     (el) => el.id == data.createdBy
      //     //   );
      //     //   if (creatorIndex != -1) {
      //     //     let creator = getters.rowsCreator.find(
      //     //       (el) => el.id == data.createdBy
      //     //     );
      //     //     data["creator"] = creator;
      //     //   }
      //     // }
      //     // var foundIndex = getters.users.findIndex(
      //     //   (el) => el == data.createdBy
      //     // );
      //     // if (foundIndex == -1) {
      //     //   getters.users.push(data.createdBy);
      //     //   if (getters.mountedTable) {
      //     //     commit("SET_ADDED_ROW_ID", data.id);
      //     //     commit("SET_ADDED_CREATOR_ID", data.createdBy);
      //     //   }
      //     // }
      //   }
      //   return data;
      // };
      const db = firebase.firestore();
      var jobCollection = null;
      if (payload.currentRoute != "my-jobs") {
        if (payload.searchBy) {
          switch (payload.searchBy) {
            case "type":
              jobCollection = getters.loadMoreLastRecord
                ? db
                    .collection("job")
                    .limit(payload.limit || 10)
                    .orderBy("publishedAt", "desc")
                    .where("type", "==", payload.filter)
                    .where("postStatus", "in", ["accepted", "deleted"])
                    .where("isActive", "==", true)
                    .startAfter(getters.loadMoreLastRecord)
                : db
                    .collection("job")
                    .orderBy("publishedAt", "desc")
                    .where("type", "==", payload.filter)
                    .where("postStatus", "in", ["accepted", "deleted"])
                    .where("isActive", "==", true)
                    .limit(payload.limit || 10);
              break;
            case "workplace":
              jobCollection = getters.loadMoreLastRecord
                ? db
                    .collection("job")
                    .limit(payload.limit || 10)
                    .orderBy("publishedAt", "desc")
                    .where("workplaceId", "==", payload.filter)
                    .where("postStatus", "in", ["accepted", "deleted"])
                    .where("isActive", "==", true)
                    .startAfter(getters.loadMoreLastRecord)
                : db
                    .collection("job")
                    .orderBy("publishedAt", "desc")
                    .where("workplaceId", "==", payload.filter)
                    .where("postStatus", "in", ["accepted", "deleted"])
                    .where("isActive", "==", true)
                    .limit(payload.limit || 10);
              break;
            case "position":
              jobCollection = getters.loadMoreLastRecord
                ? db
                    .collection("job")
                    .limit(payload.limit || 10)
                    .orderBy("publishedAt", "desc")
                    .where("positionId", "==", payload.filter)
                    .where("postStatus", "in", ["accepted", "deleted"])
                    .where("isActive", "==", true)
                    .startAfter(getters.loadMoreLastRecord)
                : db
                    .collection("job")
                    .orderBy("publishedAt", "desc")
                    .where("positionId", "==", payload.filter)
                    .where("postStatus", "in", ["accepted", "deleted"])
                    .where("isActive", "==", true)
                    .limit(payload.limit || 10);
              break;
            case "city":
              jobCollection = getters.loadMoreLastRecord
                ? db
                    .collection("job")
                    .orderBy("publishedAt", "desc")
                    .where("cityId", "==", payload.filter)
                    .where("postStatus", "in", ["accepted", "deleted"])
                    .where("isActive", "==", true)
                    .startAfter(getters.loadMoreLastRecord)
                    .limit(payload.limit || 10)
                : db
                    .collection("job")
                    .orderBy("publishedAt", "desc")
                    .where("cityId", "==", payload.filter)
                    .where("postStatus", "in", ["accepted", "deleted"])
                    .where("isActive", "==", true)
                    .limit(payload.limit || 10);
              break;
          }
        } else {
          jobCollection = getters.loadMoreLastRecord
            ? db
                .collection("job")
                .limit(payload.limit || 10)
                .orderBy("publishedAt", "desc")
                .where("postStatus", "in", ["accepted", "deleted"])
                .where("isActive", "==", true)
                .startAfter(getters.loadMoreLastRecord)
            : db
                .collection("job")
                .orderBy("publishedAt", "desc")
                .where("postStatus", "in", ["accepted", "deleted"])
                .where("isActive", "==", true)
                .limit(payload.limit || 10);
        }
      } else {
        // debugger
        jobCollection = getters.loadMoreLastRecord
          ? db
              .collection("job")
              .limit(payload.limit || 10)
              .orderBy("publishedAt", "desc")
              .where("createdBy", "==", payload.currentUser.id)
              .where("postStatus", "in", ["accepted", "pending"])
              .startAfter(getters.loadMoreLastRecord)
          : db
              .collection("job")
              .orderBy("publishedAt", "desc")
              .where("createdBy", "==", payload.currentUser.id)
              .where("postStatus", "in", ["accepted", "pending"])
              .limit(payload.limit || 10);
      }
      const snapshot = await jobCollection.get();
      const jobs = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          data["creator"] = "";
          if (payload.currentRoute === "my-jobs") {
            data["creator"] = payload.currentUser;
          } else {
            let user = await db.collection("user").doc(data.createdBy).get();
            data["creator"] = user.exists ? user.data() : "";
          }
          return data;
        })
      );
      console.log("THE JOBS", jobs);
      commit("SET_ROWS", jobs);
      // await bindFirestoreRef("rows", jobCollection, {
      //   serialize: customSerializer,
      //   wait: true,
      // });
      // debugger
      // if (payload.currentRoute != "my-jobs") {
      //   // if (getters.users.length > 0) {
      //   //   const userCollection = db
      //   //     .collection("user")
      //   //     .where("id", "in", getters.users);
      //   //   await bindFirestoreRef(`rowsCreator`, userCollection);
      //   // }
      //   // getters.rows.forEach(async (row) => {
      //   //   let creator = getters.rowsCreator.find(
      //   //     (el) => el.id == row.createdBy
      //   //   );
      //   //   row["creator"] = creator;
      //   // });
      //   getters.rows.forEach(async (row) => {
      //     let doc = await db.collection("user").doc(row.createdBy).get();
      //     let creator = doc.exists ? doc.data() : null;
      //     console.log("creator1", creator);
      //     console.log("docExist", doc.exists);
      //     row["creator"] = creator;
      //   });
      // }
      commit("FETCH_SUCCESS");
    },

    doFetchFromRoute: async ({ commit }, payload) => {
      // debugger
      commit("FETCH_STARTED");
      commit("SET_ROWS_FROM_ROUTE", []);
      // const customSerializer = (doc) => {
      //   const data = doc.data();
      //   Object.defineProperty(data, "_doc", { value: doc });
      //   data["creator"] = "";
      //   if (payload.currentRoute == "my-jobs") {
      //     data["creator"] = payload.currentUser;
      //   } else {
      //     // let doc = await db.collection("user").doc(data.createdBy).get();
      //     // let creator = doc.exists ? doc.data() : null;
      //     // console.log("creator2", creator);
      //     // data["creator"] = creator;
      //     // if (getters.rowsCreator.length > 0) {
      //     //   var creatorIndex = getters.rowsCreator.findIndex(
      //     //     (el) => el.id == data.createdBy
      //     //   );
      //     //   if (creatorIndex != -1) {
      //     //     let creator = getters.rowsCreator.find(
      //     //       (el) => el.id == data.createdBy
      //     //     );
      //     //     data["creator"] = creator;
      //     //   }
      //     // }
      //     // var foundIndex = getters.users.findIndex(
      //     //   (el) => el == data.createdBy
      //     // );
      //     // if (foundIndex == -1) {
      //     //   getters.users.push(data.createdBy);
      //     //   if (getters.mountedTable) {
      //     //     commit("SET_ADDED_ROW_ID", data.id);
      //     //     commit("SET_ADDED_CREATOR_ID", data.createdBy);
      //     //   }
      //     // }
      //   }

      //   return data;
      // };
      const db = firebase.firestore();
      var jobCollection = null;
      const endAt = payload.page * payload.limit;
      // debugger
      if (payload.currentRoute == "my-jobs") {
        // debugger
        jobCollection = db
          .collection("job")
          .where("postStatus", "in", ["accepted", "pending"])
          .where("createdBy", "==", payload.currentUser.id)
          .orderBy("publishedAt", "desc")
          .limit(endAt);
      } else {
        if (payload.searchBy) {
          switch (payload.searchBy) {
            case "type":
              jobCollection = db
                .collection("job")
                .orderBy("publishedAt", "desc")
                .where("type", "==", payload.filter)
                .where("postStatus", "in", ["accepted", "deleted"])
                .where("isActive", "==", true)
                .limit(endAt);
              break;
            case "position":
              jobCollection = db
                .collection("job")
                .orderBy("publishedAt", "desc")
                .where("positionId", "==", payload.filter)
                .where("postStatus", "in", ["accepted", "deleted"])
                .where("isActive", "==", true)
                .limit(endAt);
              break;
            case "workplace":
              jobCollection = db
                .collection("job")
                .orderBy("publishedAt", "desc")
                .where("workplaceId", "==", payload.filter)
                .where("postStatus", "in", ["accepted", "deleted"])
                .where("isActive", "==", true)
                .limit(endAt);
              break;
            case "city":
              jobCollection = db
                .collection("job")
                .orderBy("publishedAt", "desc")
                .where("cityId", "==", payload.filter)
                .where("postStatus", "in", ["accepted", "deleted"])
                .where("isActive", "==", true)
                .limit(endAt);
              break;
          }
        } else {
          jobCollection = db
            .collection("job")
            .where("postStatus", "in", ["accepted", "deleted"])
            .where("isActive", "==", true)
            .orderBy("publishedAt", "desc")
            .limit(endAt);
        }
      }
      const snapshot = await jobCollection.get();
      // console.log("snap", snapshot.docs);
      const jobs = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          data["creator"] = "";
          if (payload.currentRoute === "my-jobs") {
            data["creator"] = payload.currentUser;
          } else {
            let user = await db.collection("user").doc(data.createdBy).get();
            data["creator"] = user.exists ? user.data() : "";
          }
          return data;
        })
      );
      // console.log("THE JOBS", jobs);
      commit("SET_ROWS_FROM_ROUTE", jobs);
      // if (payload.currentRoute != "my-jobs") {
      // if (getters.users.length > 0) {
      //   const userCollection = db
      //     .collection("user")
      //     .where("id", "in", getters.users);
      //   await bindFirestoreRef(`rowsCreator`, userCollection);
      // }
      // getters.rowsFromRoute.forEach(async (row) => {
      //   let creator = getters.rowsCreator.find(
      //     (el) => el.id == row.createdBy
      //   );
      //   row["creator"] = creator;
      // });
      // getters.rowsFromRoute.forEach(async (row) => {
      //   let doc = await db.collection("user").doc(row.createdBy).get();
      //   let creator = doc.exists ? doc.data() : null;
      //   console.log("creator2", creator);
      //   row["creator"] = creator;
      // });
      // }
      commit("FETCH_SUCCESS");
    },

    setRows({ commit }, payload) {
      commit("FETCH_STARTED");
      commit("SET_ROWS", payload);
      commit("FETCH_SUCCESS");
    },
    setLoadMoreLast({ commit }) {
      commit("SET_LOADMORE_LAST");
    },
    resetCursor({ commit }) {
      commit("RESET_CURSOR");
    },
    setLoadFirstRecord({ commit }) {
      commit("SET_LOADFIRSTRECORD");
    },
    setCreatorToRow({ commit }) {
      commit("SET_CREATOR_TO_ROW");
    },

    getPreviousRecordsBatch: async ({ commit, getters }, payload) => {
      commit("FETCH_STARTED");
      // const customSerializer = (doc) => {
      //   const data = doc.data();
      //   Object.defineProperty(data, "_doc", { value: doc });
      //   data["creator"] = "";
      //   if (payload.currentRoute == "my-jobs") {
      //     data["creator"] = payload.currentUser;
      //   } else {
      //     // if (getters.rowsCreator.length > 0) {
      //     //   var creatorIndex = getters.rowsCreator.findIndex(
      //     //     (el) => el.id == data.createdBy
      //     //   );
      //     //   if (creatorIndex != -1) {
      //     //     let creator = getters.rowsCreator.find(
      //     //       (el) => el.id == data.createdBy
      //     //     );
      //     //     data["creator"] = creator;
      //     //   }
      //     // }
      //     // var foundIndex = getters.users.findIndex(
      //     //   (el) => el == data.createdBy
      //     // );
      //     // if (foundIndex == -1) {
      //     //   getters.users.push(data.createdBy);
      //     //   if (getters.mountedTable) {
      //     //     commit("SET_ADDED_ROW_ID", data.id);
      //     //     commit("SET_ADDED_CREATOR_ID", data.createdBy);
      //     //   }
      //     // }
      //   }

      //   return data;
      // };
      const db = firebase.firestore();
      var jobCollection = null;
      if (payload.currentRoute == "my-jobs") {
        jobCollection = db
          .collection("job")
          .where("postStatus", "in", ["accepted", "pending"])
          .where("createdBy", "==", payload.currentUser.id)
          .orderBy("publishedAt", "desc")
          .endBefore(getters.loadFirstRecord)
          .limitToLast(payload.limit || 9);
      } else {
        if (payload.searchBy) {
          switch (payload.searchBy) {
            case "type":
              jobCollection = db
                .collection("job")
                .orderBy("publishedAt", "desc")
                .where("type", "==", payload.filter)
                .where("postStatus", "in", ["accepted", "deleted"])
                .where("isActive", "==", true)
                .endBefore(getters.loadFirstRecord)
                .limitToLast(payload.limit || 9);
              break;
            case "workplace":
              jobCollection = db
                .collection("job")
                .limit(payload.limit || 10)
                .orderBy("publishedAt", "desc")
                .where("workplaceId", "==", payload.filter)
                .where("postStatus", "in", ["accepted", "deleted"])
                .where("isActive", "==", true)
                .endBefore(getters.loadFirstRecord)
                .limitToLast(payload.limit || 9);
              break;
            case "position":
              jobCollection = db
                .collection("job")
                .limit(payload.limit || 10)
                .orderBy("publishedAt", "desc")
                .where("positionId", "==", payload.filter)
                .where("postStatus", "in", ["accepted", "deleted"])
                .where("isActive", "==", true)
                .endBefore(getters.loadFirstRecord)
                .limitToLast(payload.limit || 9);
              break;
            case "city":
              jobCollection = db
                .collection("job")
                .limit(payload.limit || 10)
                .orderBy("publishedAt", "desc")
                .where("cityId", "==", payload.filter)
                .where("postStatus", "in", ["accepted", "deleted"])
                .where("isActive", "==", true)
                .endBefore(getters.loadFirstRecord)
                .limitToLast(payload.limit || 9);
              break;
          }
        } else {
          jobCollection = db
            .collection("job")
            .where("postStatus", "in", ["accepted", "deleted"])
            .where("isActive", "==", true)
            .orderBy("publishedAt", "desc")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(payload.limit || 9);
        }
      }
      const snapshot = await jobCollection.get();
      // console.log("snap", snapshot.docs);
      const jobs = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          data["creator"] = "";
          if (payload.currentRoute === "my-jobs") {
            data["creator"] = payload.currentUser;
          } else {
            let user = await db.collection("user").doc(data.createdBy).get();
            data["creator"] = user.exists ? user.data() : "";
          }
          return data;
        })
      );
      // console.log("THE JOBS", jobs);
      commit("SET_ROWS", jobs);
      // if (payload.currentRoute != "my-jobs") {
      // if (getters.users.length > 0) {
      //   const userCollection = db
      //     .collection("user")
      //     .where("id", "in", getters.users);
      //   await bindFirestoreRef(`rowsCreator`, userCollection);
      // }
      // getters.rows.forEach(async (row) => {
      //   let creator = getters.rowsCreator.find(
      //     (el) => el.id == row.createdBy
      //   );
      //   row["creator"] = creator;
      // });
      // getters.rows.forEach(async (row) => {
      //     let doc = await db.collection("user").doc(row.createdBy).get();
      //     let creator = doc.exists ? doc.data() : "";
      //     // console.log("creator", creator);
      //     row["creator"] = creator;
      //   });
      // }
      commit("FETCH_SUCCESS");
    },

    setDisableNext: firestoreAction(
      async ({ getters, bindFirestoreRef }, payload) => {
        // console.log('payload',payload);
        // console.log('payload.filter',payload.filter);
        //         debugger
        const db = firebase.firestore();
        var jobCollection = null;
        if (payload.currentRoute == "my-jobs") {
          jobCollection = db
            .collection("job")
            .where("postStatus", "in", ["accepted", "pending"])
            .where("createdBy", "==", payload.currentUser.id)
            .orderBy("publishedAt", "desc")
            .startAfter(getters.loadMoreLastRecord)
            .limit(1);
        } else {
          if (payload.searchBy) {
            switch (payload.searchBy) {
              case "type":
                jobCollection = db
                  .collection("job")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("type", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .startAfter(getters.loadMoreLastRecord)
                  .limit(1);
                break;
              case "workplace":
                jobCollection = db
                  .collection("job")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("workplaceId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .startAfter(getters.loadMoreLastRecord)
                  .limit(1);
                break;
              case "position":
                jobCollection = db
                  .collection("job")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("positionId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .startAfter(getters.loadMoreLastRecord)
                  .limit(1);
                break;
              case "city":
                jobCollection = db
                  .collection("job")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("cityId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .startAfter(getters.loadMoreLastRecord)
                  .limit(1);
                break;
            }
          } else {
            jobCollection = db
              .collection("job")
              .where("postStatus", "in", ["accepted", "deleted"])
              .where("isActive", "==", true)
              .orderBy("publishedAt", "desc")
              .startAfter(getters.loadMoreLastRecord)
              .limit(1);
          }
        }
        await bindFirestoreRef("recordAfterLast", jobCollection);
      }
    ),

    setDisablePrevious: firestoreAction(
      async ({ getters, bindFirestoreRef }, payload) => {
        const db = firebase.firestore();
        var jobCollection = null;
        if (payload.currentRoute == "my-jobs") {
          jobCollection = db
            .collection("job")
            .where("postStatus", "in", ["accepted", "pending"])
            .where("createdBy", "==", payload.currentUser.id)
            .orderBy("publishedAt", "desc")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(1);
        } else {
          if (payload.searchBy) {
            switch (payload.searchBy) {
              case "type":
                jobCollection = db
                  .collection("job")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("type", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(1);
                break;
              case "workplace":
                jobCollection = db
                  .collection("job")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("workplaceId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(1);
                break;
              case "position":
                jobCollection = db
                  .collection("job")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("positionId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(1);
                break;
              case "city":
                jobCollection = db
                  .collection("job")
                  .limit(payload.limit || 10)
                  .orderBy("publishedAt", "desc")
                  .where("cityId", "==", payload.filter)
                  .where("postStatus", "in", ["accepted", "deleted"])
                  .where("isActive", "==", true)
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(1);
                break;
            }
          } else {
            jobCollection = db
              .collection("job")
              .where("postStatus", "in", ["accepted", "deleted"])
              .where("isActive", "==", true)
              .orderBy("publishedAt", "desc")
              .endBefore(getters.loadFirstRecord)
              .limitToLast(1);
          }
        }

        await bindFirestoreRef("recordBeforeFirst", jobCollection);
      }
    ),
    // //#endregion

    doUnselectAll({ commit }) {
      commit("UNSELECT_ALL");
    },

    doMountTable({ commit }, table) {
      commit("TABLE_MOUNTED", table);
    },

    async doReset({ commit, dispatch }) {
      commit("RESETED");
      return dispatch("doFetch");
    },

    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit("PAGINATION_CHANGED", pagination);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit("PAGINATION_PAGE_SIZE_CHANGED", pageSize);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit("PAGINATION_CURRENT_PAGE_CHANGED", currentPage);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit("SORTER_CHANGED", sorter);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },
    setMountedTable({ commit }, value) {
      commit("SET_MOUNTEDTABLE", value);
    },
    setMountedCityTable({ commit }, value) {
      commit("SET_MOUNTEDCITYTABLE", value);
    },
    setMountedPositionTable({ commit }, value) {
      commit("SET_MOUNTEDPOSITIONTABLE", value);
    },
    setMountedWorkplaceTable({ commit }, value) {
      commit("SET_MOUNTEDWORKPLACETABLE", value);
    },
    setMountedDeactivationReasonTable({ commit }, value) {
      commit("SET_MOUNTEDDEACTIVATIONREASON", value);
    },
    setLoadingAddMoreJobs({ commit }, value) {
      commit("SET_LOADING_ADD_MORE_JOBS", value);
    },

    async doFetchUser({ commit }, userId) {
      try {
        commit("FETCH_USER_STARTED");
        JobsService.findUser(userId, (doc) => {
          commit("FETCH_USER_SUCCESS", doc);
        });
      } catch (error) {
        Errors.handle(error);
        commit("FETCH_USER_ERROR");
      }
    },
    setJobDetailsStarted({ commit }) {
      commit("DETAILS_LOADING_STARTED");
    },
    setJobDetailsSuccess({ commit }) {
      commit("DETAILS_LOADING_SUCCESS");
    },

    async doFetchWorkplace({ commit }) {
      try {
        // commit('FETCH_STARTED')
        // JobsService.listWorkplace((doc,docType) => {
        //   commit('APPEND_TO_WORKPLACES',{doc,docType})
        //   commit('FETCH_SUCCESS')
        // })
        // commit('FETCH_WORKPLACE_STARTED')
        commit("FETCH_STARTED");
        const workplaceCollection = await firebase
          .firestore()
          .collection("workplace")
          .orderBy("index", "asc")
          .get();
        workplaceCollection.docs.forEach((doc) => {
          commit("APPEND_TO_WORKPLACES", doc.data());
        });
        commit("FETCH_SUCCESS");
      } catch (error) {
        Errors.handle(error);
        commit("FETCH_ERROR");
      }
    },
    async doFetchJobPosition({ commit }) {
      try {
        commit("FETCH_STARTED");
        const jobPositionCollection = await firebase
          .firestore()
          .collection("jobPosition")
          .orderBy("index", "asc")
          .get();
        jobPositionCollection.docs.forEach((doc) => {
          commit("APPEND_TO_JOBPOSITIONS", doc.data());
        });
        commit("FETCH_SUCCESS");
      } catch (error) {
        Errors.handle(error);
        commit("FETCH_ERROR");
      }
    },
    async doFetchCity({ commit }) {
      const citiesCollection = await firebase
        .firestore()
        .collection("city")
        .get();
      citiesCollection.docs.forEach(async (doc) => {
        let city = doc.data();
        city.id = doc.id;
        commit("APPEND_TO_CITIES", city);
        const regions = await firebase
          .firestore()
          .collection(`city/${city.id}/regions`)
          .get();
        let cityRegions = [];
        if (regions.docs.length > 0) {
          regions.docs.forEach((doc) => {
            let region = doc.data();
            region.id = doc.id;
            cityRegions.push(region);
          });
        }

        commit("APPEND_TO_REGIONS", { cityId: city.id, regions: cityRegions });
      });

      // try {
      //   commit('FETCH_STARTED')
      //   JobsService.listCity((doc,docType) => {
      //     commit('APPEND_TO_CITIES',{doc,docType})
      //     commit('FETCH_SUCCESS')
      //   },(cityId,regions)=>{
      //     // console.log('city Id',cityId);
      //     // console.log('regions',regions);
      //     commit('APPEND_TO_REGIONS',{cityId,regions})
      //   })
      // } catch (error) {
      //   Errors.handle(error);
      //   commit('FETCH_ERROR');
      // }
    },
    async doFetchRegion({ commit }) {
      try {
        commit("FETCH_STARTED");
        JobsService.listRegion((doc, docType) => {
          commit("APPEND_TO_REGIONS", { doc, docType });
          commit("FETCH_SUCCESS");
        });
      } catch (error) {
        Errors.handle(error);
        commit("FETCH_ERROR");
      }
    },
    async doFetchDeactivationReason({ commit }) {
      try {
        commit("FETCH_STARTED");
        const deactivationReasonCollection = await firebase
          .firestore()
          .collection("deactivationReason")
          .orderBy("index", "asc")
          .where("category", "==", "job")
          .get();
        deactivationReasonCollection.docs.forEach((doc) => {
          commit("APPEND_TO_DEACTIVATIONREASONS", doc.data());
        });
      } catch (error) {
        Errors.handle(error);
        commit("FETCH_ERROR");
      }
    },
    setCurrentRoute({ commit }, value) {
      commit("SET_CURRENTROUTE", value);
    },
    setJobs({ commit }, value) {
      commit("SET_JOBS", value);
    },
  },
};
