
// import { I18nUtil } from '@/shared/i18n/i18n-util';
import i18n from '@/vueI18n'
import { getLanguages, getLanguageCode } from '@/i18n';
import { Quasar } from 'quasar'
export default {
  name: 'app-i18n-toggle',

  data() {
    return {
      lang: getLanguageCode(),
    };
  },

  computed: {
    languages() {
      return getLanguages();
    },
    isRTL() {
      return i18n.locale == 'ar'

      // return this.lang == 'ar'
    },
  },

  methods: {
    doChangeLanguage(language) {
      //  debugger
      this.$emit('closeMenu')
      i18n.locale = language
      localStorage.setItem('language',language)

      const {lang} = this.$route.params
      let route = ''
      if(lang && (lang === 'ar' || lang === 'en')){
        route = `/${language}${this.$route.fullPath.substring(3)}`
      }
      else{
        route = `/${language}${this.$route.fullPath}`
      }
      if(language === 'ar'){
        // debugger
        // document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
        // this.$q.lang.rtl = true
        // this.ar = true
        import(`quasar/lang/ar`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }
      else {
        // document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
        // this.$q.lang.rtl = false
        // this.ar = false
        import(`quasar/lang/en-us`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }
      this.$router.push(route)
      // I18nUtil.doChangeLanguage(language);
    },
  },
};
