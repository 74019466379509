
import RegisterCard from '@/modules/auth/components/register-card.vue';
export default {
    name:'app-register-dialog',
    // props:['login',],
    props:['visible'],
    data(){
        return{
            // visible:''
            signup:false,
            loginForm:false,
            email:'',
            password:'',
            // dialogVisible:false

        }
    },
    components:{
        [RegisterCard.name]: RegisterCard,
        },
    computed:{
        signinTitle(){
            return this.i18n('auth.signin')
        },
        signupTitle(){
            return this.i18n('auth.signup')
        },
        createNewAccount(){
            return this.i18n('auth.createAnAccount')
        },
        alreadyAMember(){
            return this.i18n('auth.alreadyAMember')
        },
        // dialogVisible: {
        //     get: function() {
        //         return this.visible;
        //     },

        //     set: function(value) {
        //         if (!value) {
        //         this.$emit('close');
        //         }
        //     },
        // },

    },
    methods:{
        
        i18n(key, args) {
            return this.$t(key, args);
        },
        doSubmit(){

        },
        close(){

        },
        closeDialog(){
            
            // this.dialogVisible = false
            // this.visible = false
            this.$emit('close')
            

     
        },
        toggleSignup(){
            this.signup = !this.signup
        },
        registerUser(){
            this.$emit('register')
        }
    }
    
}
