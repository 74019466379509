import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/vueI18n';
// import { IamService } from '@/modules/iam/iam-service';
// import { CompanyProfileService } from '@/modules/company-profile/company-profile-service';
import { HomeService } from '@/modules/home/home-service';

export default {
  namespaced: true,

  state: {
    imageLoaded: 0,
    runprof_url: 'https://www.runprof.com',
    facebook_url: 'https://www.facebook.com/runprofllc',
    instagram_url: 'https://www.instagram.com/runprofllc',
    twitter_url: 'https://twitter.com/runprof_M',
    whatsapp_url: 'https://api.whatsapp.com/send?phone=+201050085101',
    appStore_url: '',
    googlePlay_url: '',
    drugdeal_email: 'support@drugdealapp.com',
    imagesToPreload: [
      //#region [ this Images is loaded in english & arabic lang only ]
        // 'images/landingHead.png',
        // 'images/landingLogo.svg',
        // '/images/halfFlag.png',
        // '/images/moccupPhone.svg',
      //#endregion
        
      //#region [ this Images is loaded in english lang only ]
        // '/images/slideItemOne.png',
        // '/images/slideItemTwo.png',
        // '/images/slideItemThree.png',
        // '/images/slideItemFour.png',
        // '/images/phones.png',
        // 'images/mobileHead.png',
        // '/images/tourAppStore.png',
        // '/images/tourIphone.png',
        // '/images/appTour.png',
        // '/images/clientAppStore.png',
      //#endregion

      //#region [ this Images is loaded in arabic lang only ]
        // '/images/slideItemOneAr.png',
        // '/images/slideItemTwoAr.png',
        // '/images/slideItemThreeAr.png',
        // '/images/slideItemFourAr.png',
        // 'images/phoneArabicUp.png',
        // '/images/mobileArabicRTL.png',
        // '/images/tourAppStoreAr.png',
        // '/images/tourIphoneAr.png',
        // '/images/appTourAr.png',
        // '/images/clientAppStoreAr.png',
      //#endregion

      // '/images/sarOpaciBg.svg',
      // '/images/footerBg.jpg',
      // '/images/bgSaryahApp.png',
    ],
    loginHeader:false,
    drugIndexHomeSearch:false,
    saveLoading: false,

  },

  getters: {
    isImagesLoaded: (state) => state.imageLoaded == (state.imagesToPreload.length - 10 ),
    imageLoaded: (state) => state.imageLoaded,
    imagesToPreload: (state) => state.imagesToPreload,

    runprof_url: (state) => state.runprof_url,
    facebook_url: (state) => state.facebook_url,
    instagram_url: (state) => state.instagram_url,
    twitter_url: (state) => state.twitter_url,
    whatsapp_url: (state) => state.whatsapp_url,
    appStore_url: (state) => state.appStore_url,
    googlePlay_url: (state) => state.googlePlay_url,
    drugdeal_email: (state) => state.drugdeal_email,
    loginHeader: (state) => state.loginHeader,
    drugIndexHomeSearch: (state) => state.drugIndexHomeSearch,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },
    RESET(state) {
      state.imageLoaded = 0;
      state.isImagesLoaded = false;
    },
    CONFIRM_IMAGE_LOADED(state) {
      state.imageLoaded += 1;
    },
    SET_LOGIN_HEADER(state,payload){
      state.loginHeader = payload
    },
    SET_DRUG_INDEX(state,payload){
      // debugger
      state.drugIndexHomeSearch = payload
    }
  },

  actions: {
    onImgLoad({ commit }) {
      commit('CONFIRM_IMAGE_LOADED');
      // console.log(`Number Of Loading Image is Done: [ ${getters.imageLoaded} ]`)
    },
    setLoginHeader({commit},payload){
      commit('SET_LOGIN_HEADER',payload)
    },
    setDrugIndex({commit},payload){
      // debugger
      commit('SET_DRUG_INDEX',payload)
    },
    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        // const foundRecord = await OfferService.find(values.barcode)
        // if (foundRecord) {
          // throw { customError: { message: i18n('errors.recordIsFound') } }
        // }
        await HomeService.create(values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('drugDeal.common.contactUsCreateSuccess'));
        // routerAsync().push('/offer');
        return true
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
        return false
      }
    },
    
  },
};
