import service from '@/modules/auth/auth-service';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
// import i18n from '@/vueI18n';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import ProgressBar from '@/shared/progress-bar/progress-bar';
// import { IamService } from '@/modules/iam/iam-service';
// import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions 
import { firestoreAction,firebaseAction, vuexfireMutations, rtdbOptions } from 'vuexfire'
rtdbOptions.wait = true

export default {
  namespaced: true,

  state: {
    authenticationUser: null,
    currentUser: null,
    userListener: null,
    loadingInit: true,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingUpdateProfile: false,
    loading: false,
    loadingVerify: false,
    firstTime: true,
    confirmationResult: null,
    ananUID:null,
    userLoading: false,
    jobMaxCount: 0,
    propertyMaxCount: 0
  },

  getters: {
    ananUID: (state) => state.ananUID,
    confirmationResult: (state) => state.confirmationResult,
    authenticationUser: (state) => state.authenticationUser,
    currentUser: (state) => state.currentUser,
    userListener: (state) => state.userListener,
    userLoading: (state) => state.userLoading,
    currentUserEmail: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.email
        : null,
    currentUserFullName: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.fullName
        : '',

    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,

    roles: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.roles || []
        : [],

    completeRegistration: (state, getters) =>
      getters.currentUser
        ? !!getters.currentUser.phoneNumber &&
          !!getters.currentUser.idNumber &&
          !!getters.currentUser.userType &&
          !!getters.currentUser.userCategory  
        : false,

    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,

    loading: (state) => !!state.loading,
    loadingVerify: (state) => !!state.loadingVerify,
    firstTime: (state) => !!state.firstTime,
    loadingInit: (state) => !!state.loadingInit,

    loadingEmailConfirmation: (state) =>
      !!state.loadingEmailConfirmation,

    loadingPasswordResetEmail: (state) =>
      !!state.loadingPasswordResetEmail,

    loadingUpdateProfile: (state) =>
      !!state.loadingUpdateProfile,

    currentUserNameOrEmailPrefix: (state, getters) => {
      if (!getters.currentUser) {
        return null;
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 25
      ) {
        return getters.currentUserFullName;
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName;
      }

      return getters.currentUser.email.split('@')[0];
    },

    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatars ||
        !getters.currentUser.avatars.length ||
        !getters.currentUser.avatars[0].publicUrl
      ) {
        return null;
      }

      return getters.currentUser.avatars[0].publicUrl;
    },


    jobMaxCount: (state) => state.jobMaxCount,
    propertyMaxCount: (state) => state.propertyMaxCount
  },

  mutations: {
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
    },
    VERIFY_START(state) {
      state.loadingVerify = true;
    },
    VERIFY_SUCCESS(state) {
      state.loadingVerify = false;
    },
    VERIFY_ERROR(state) {
      state.loadingVerify = false;
    },

    VERIFICATION_CODE_START(state) {
      state.loading = true;
    },
    VERIFICATION_CODE_SUCCESS(state, payload) {
      state.confirmationResult = payload.confirmationResult || null;
      state.loading = false;
    },
    VERIFICATION_CODE_ERROR(state) {
      state.confirmationResult = null;
      state.loading = false;
    },

    AUTH_START(state) {
      state.loading = true;
    },
    AUTH_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loading = false;
    },
    AUTH_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loading = false;
    },

    EMAIL_CONFIRMATION_START(state) {
      state.loadingEmailConfirmation = true;
    },

    EMAIL_CONFIRMATION_SUCCESS(state) {
      state.loadingEmailConfirmation = false;
    },

    EMAIL_CONFIRMATION_ERROR(state) {
      state.loadingEmailConfirmation = false;
    },

    PASSWORD_RESET_START(state) {
      state.loadingPasswordResetEmail = true;
    },

    PASSWORD_RESET_SUCCESS(state) {
      state.loadingPasswordResetEmail = false;
    },

    PASSWORD_RESET_ERROR(state) {
      state.loadingPasswordResetEmail = false;
    },

    UPDATE_PROFILE_START(state) {
      state.loadingUpdateProfile = true;
    },

    UPDATE_PROFILE_SUCCESS(state) {
      state.loadingUpdateProfile = false;
    },

    UPDATE_PROFILE_ERROR(state) {
      state.loadingUpdateProfile = false;
    },

    AUTH_INIT_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loadingInit = false;
    },

    AUTH_INIT_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },

    AUTH_NOT_FOUND_USER(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },

    STOP_SPLASH_SCREEN(state) {
      state.firstTime = false;
    },
    ADD_ANON_UID(state,payload){
      state.ananUID = payload
    },
    FETCH_USER_STARTED(state) {
      state.userLoading = true
    },
    FETCH_USER_SUCCESS(state,userListener) {
      state.userListener = userListener;
      state.userLoading = false
    },
    FETCH_USER_ERROR(state) {
      state.userLoading = false
    },
    ...vuexfireMutations
  },

  actions: {
//#region [ Init ]
    doStopSplash({ commit }) {
      commit('STOP_SPLASH_SCREEN');
    },
    async doInitFirebase() {
      await service.init();
    },
    async doInit({ commit, dispatch }) {
      // await service.init();
      let currentUser = localStorage.getItem('currentUser');
      if (!currentUser) {
        commit('AUTH_INIT_SUCCESS', {
          currentUser: null,
          authenticationUser: null,
        });
        ProgressBar.done();
        return;
      }
      
      const unsubscribe = service.onAuthStateChanged(
        (authenticationUser) => {
          dispatch('doSigninFromAuthChange', authenticationUser);
          unsubscribe();
        },
        () => {
          // console.error(error);
          commit('AUTH_INIT_ERROR');
        },
      );
    },
    async doWaitUntilInit({ getters }) {
      if (!getters.loadingInit) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const waitUntilInitInterval = setInterval(() => {
          if (!getters.loadingInit) {
            clearInterval(waitUntilInitInterval);
            resolve();
          }
        }, 500);
      });
    },
//#endregion
// #region [ Fetch User ]
// async doFetchUser({commit},userId){
//   try {
//       commit('FETCH_USER_STARTED')
//       service.findUser(userId,(doc) => {
//       commit('FETCH_USER_SUCCESS',doc)        
//     })
//   }
//   catch{
//     commit('FETCH_USER_ERROR')
//   }
// },
  // vuexFire
    doFetchUser:  firestoreAction(async ({bindFirestoreRef},userId) => {
      // commit('FETCH_USER_LISTENER_STARTED')
      const db = firebase.firestore()
      const userDoc = db.collection('user').doc(userId)
      await bindFirestoreRef(
        'userListener',
        userDoc
      ); 
      // commit('FETCH_USER_LISTENER_SUCCESS')
    }),
// //#endregion

// region [check user job in a month]

checkUserJobInAMonth:  firebaseAction(async ({bindFirebaseRef, getters},addedJobInMonth) => {
  // debugger
  const db = firebase.database()
  await bindFirebaseRef('jobMaxCount', db.ref('setting').child('job'))
  if(addedJobInMonth >= getters['jobMaxCount']['.value']){
    // debugger
    const lang = localStorage.getItem('language') || 'en'
    routerAsync().push(`/${lang}`)
    // routerAsync().push(`/${lang}/job-opportunities?page=1`)
    // routerAsync().push({
    //     name: 'job-opportunities',
    //     params: {
    //         lang
    //     },
    //     query:{
    //         page: 1
    //     }
    // })              
}
   
}),

checkUserPropertyInAMonth:  firebaseAction(async ({bindFirebaseRef, getters},addedPropertyInMonth) => {
  const db = firebase.database()
  await bindFirebaseRef('propertyMaxCount', db.ref('setting').child('property'))
  if(addedPropertyInMonth >= getters['propertyMaxCount']['.value']){
    const lang = localStorage.getItem('language') || 'en'
    routerAsync().push(`/${lang}`)             
  }   
}),


// async checkUserJobInAMonth({commit}, addedJobInMonth){
//   debugger
//   await firebase.database().ref('setting/job').on('value',(snapshot) => {
//     let jobMaxCount = snapshot.val()                
//     debugger
//     if(addedJobInMonth >= jobMaxCount){
//         const lang = localStorage.getItem('language') || 'en'
//         this.$router.push({
//             name: 'job-opportunities',
//             params: {
//                 lang
//             },
//             query:{
//                 page: 1
//             }
//         })              
//     }
//     })
// },

//#endregion
//#region [ Send Emails ]
    async doSendWelcomeEmail({ getters }) {
      try {
        // commit('EMAIL_CONFIRMATION_START');
        // 
        await service.sendWelcomeEmail(getters.currentUser.email, getters.currentUser.firstName);
        // Message.success(
        //   i18n('auth.verificationEmailSuccess'),
        // );
        // commit('EMAIL_CONFIRMATION_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        // commit('EMAIL_CONFIRMATION_ERROR');
      }
    },
    
    async doSendEmailConfirmation({ commit, getters }) {
      try {
        commit('EMAIL_CONFIRMATION_START');
        await service.sendEmailVerification(
          getters.authenticationUser,
        );
        Message.success(
          i18n('auth.verificationEmailSuccess'),
        );
        commit('EMAIL_CONFIRMATION_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('EMAIL_CONFIRMATION_ERROR');
      }
    },

    async doSendPasswordResetEmail({ commit }, email) {
      try {
        commit('PASSWORD_RESET_START');
        await service.sendPasswordResetEmail(email);
        Message.success(i18n('auth.passwordResetEmailSuccess'));
        commit('PASSWORD_RESET_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('PASSWORD_RESET_ERROR');
      }
    },
//#endregion

//#region [ Authentication With Social ]
    async doSigninSocial({ commit }, { provider, rememberMe }) {
      try {
        commit('AUTH_START');

        let authenticationUser = null;
        let currentUser = null;

        const credentials = await service.signinWithSocial(
          provider,
          rememberMe,
        );
        if (credentials && credentials.user) {
          
          authenticationUser = credentials.user;
          
          currentUser = await service.fetchMe();
          
          
          currentUser.emailVerified = authenticationUser.emailVerified;
          if (currentUser.accountType != "client") {
            throw {code: "auth/user-not-found", message: "There is no user record corresponding to this identifier. The user may have been deleted.", a: null}
          }
          // if (!currentUser.emailVerified) {
          //   service.sendEmailVerification(
          //     authenticationUser,
          //   );
          // }
          // ======================================================================================================== //
          //                               IF --> user is new and sign in by Social                                   //
          //                                 THEN-- > get profile info from social                                    //
          // ======================================================================================================== //
          if (credentials.additionalUserInfo.isNewUser) {
            let firstName;
            let lastName;
            let phoneNumber;
            let countryCode 
            let countryName
            // let email;
            countryCode = ""
            countryName = ""
            let avatar = "";
            if (provider == 'google') {
              firstName = credentials.additionalUserInfo.profile.given_name;
              lastName = credentials.additionalUserInfo.profile.family_name;
              phoneNumber = credentials.user.phoneNumber;
              // email = credentials.additionalUserInfo.profile.email
              if (credentials.user.photoURL) {
                avatar = credentials.additionalUserInfo.profile.picture//[
              //     {
              //       id: '',
              //       name: 'photo.jpg',
              //       privateUrl: '',
              //       publicUrl: credentials.additionalUserInfo.profile.picture,
              //     },
              //   ];
              }
            } else if (provider == 'facebook') {
              firstName = credentials.additionalUserInfo.profile.first_name;
              lastName = credentials.additionalUserInfo.profile.last_name;
              phoneNumber = credentials.user.phoneNumber;
              if (credentials.additionalUserInfo.profile.picture.data.url) {
                avatar = credentials.additionalUserInfo.profile.picture.data.url
                // avatar = [
                //   {
                //     id: '',
                //     name: 'photo.jpg',
                //     privateUrl: '',
                //     publicUrl: credentials.additionalUserInfo.profile.picture.data.url,
                //   },
                // ];
              }
            }
            
            // firebase.firestore().collection('user').doc(currentUser.id).update({ accountType: 'client' })
            // firebase.database().ref(`newUser/${currentUser.id}`).set(localStorage.getItem('language'));

            // await service.updateProfile(
            //   firstName,
            //   lastName,
            //   phoneNumber,
            //   avatar,
            // );
            await service.updateProfile(
              firstName + ' ' + lastName,
              phoneNumber,
              countryCode,
              countryName,
              avatar,
              // {"web": localStorage.getItem('language')}             
            );
            // debugger
            currentUser.deviceTokens['web'] = localStorage.getItem('language')
            // // if(!currentUser.deviceTokens['web']){
              firebase.firestore().collection('user').doc(currentUser.id).update(
                { accountType: 'client' ,
                  deviceTokens: currentUser.deviceTokens
                })
            // }
            // // debugger
            // // firebase.firestore().collection('user').doc(currentUser.id).update({ accountType: 'client' ,
            // // deviceTokens:{"web":localStorage.getItem('language')}})
            currentUser.avatar = avatar;
          }


          if(!currentUser.deviceTokens['web']){
            currentUser.deviceTokens['web'] = localStorage.getItem('language')
            firebase.firestore().collection('user').doc(currentUser.id).update({ accountType: 'client' ,
            deviceTokens: currentUser.deviceTokens})
          }          
          // ======================================================================================================== //
        }

        // in background
        service.reauthenticateWithStorageToken();

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });
        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        firebase.auth().languageCode = localStorage.getItem(
          'language',
        );
        // routerAsync().push('/');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },
//#endregion

signInAnon({commit}){
  service.signInAnon(uid => {
    commit("ADD_ANON_UID",uid)
  })
},
//#region [ Authentication With Email and Password ]
    async doRegisterEmailAndPassword(
      { commit },
      { email, password },
    ) {
      try {
        commit('AUTH_START');
        // 
        const authenticationUser = await service.registerWithEmailAndPassword(
          email,
          password,
        );
        const currentUser = await service.fetchMe();
        currentUser.emailVerified = authenticationUser.emailVerified;
        // firebase.firestore().collection('user').doc(currentUser.id).update({ accountType: 'client', 
        // deviceTokens:{"web":localStorage.getItem('language')}})
        // language: localStorage.getItem('language')})
        // firebase.firestore().collection('user').doc(currentUser.id).update( })

        // firebase.database().ref(`newUser/${currentUser.id}`).set(localStorage.getItem('language'));

        currentUser.accountType = 'client';
        // currentUser.accountType = "client"
        // in background
        service.reauthenticateWithStorageToken();
        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });

        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        // routerAsync().push('/');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSigninWithEmailAndPassword({ commit }, { email, password, rememberMe }) {
      try {
        commit('AUTH_START');

        // dispatch('layout/deleteUserFromAuthTable', null, { root: true })
       
        let authenticationUser = null;
        let currentUser = null;

        const credentials = await service.signinWithEmailAndPassword(
          email,
          password,
          rememberMe,
        );
          // 
        if (credentials && credentials.user) {
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          currentUser.emailVerified =
            authenticationUser.emailVerified;
          if (currentUser.accountType != 'client') {
            throw {code: "auth/user-not-found", message: "There is no user record corresponding to this identifier. The user may have been deleted.", a: null}
          }
        }
        // in background
        service.reauthenticateWithStorageToken();

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });

        // debugger

        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        // routerAsync().push('/');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    // async doEmailVerified({ commit,dispatch }) {
    async doEmailVerified({ commit}) {
      try {
        // ;
        // await dispatch('doSendWelcomeEmail');


        // await service.checkEmailVerified();

        commit('VERIFY_START');
        await firebase.auth().currentUser.reload();
        await firebase.auth().onAuthStateChanged((authenticationUser) => {
          if (authenticationUser.emailVerified) {
            commit('VERIFY_SUCCESS');
            
            let currentUser = localStorage.getItem('currentUser');
            if (currentUser) {
              let user = service.decryption(currentUser, 'secret-c-u');
              user.emailVerified = authenticationUser.emailVerified;
              localStorage.setItem('currentUser', service.encryption(user, 'secret-c-u'));
            }
            document.location.reload(false);
          } else {
            commit('VERIFY_SUCCESS');
            Message.error(i18n('Email not verified yet'));
          }
        });
        
      } catch (error) {
        service.signout();
        Errors.handle(error);
        commit('VERIFY_ERROR');
      }
    },
//#endregion

//#region [ Authentication With Phone Number ]
    async doSigninWithPhoneNumberCredential({ commit }, { verificationId, code, rememberMe }) {
      try {
        commit('AUTH_START');
        let currentUser;
        let authenticationUser;
        //#region [ Set Authentication State Persistence ]
        const persistence = rememberMe
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION;    
        
        await firebase.auth().setPersistence(persistence);
        //#endregion
        
        let credential = await firebase.auth.PhoneAuthProvider.credential(verificationId, code)
        const credentials = await firebase.auth().signInWithCredential(credential);
        
        if (credentials.additionalUserInfo.isNewUser) {
          await firebase.auth().currentUser.delete().then(() => {
          }).catch((error) => {
            throw error
          });
          commit('AUTH_SUCCESS', {
            currentUser,
            authenticationUser,
          });
          Errors.handle({code: 'auth/user-not-found'});
          routerAsync().push('/auth/signin');
          return
        } 

        if (credentials && credentials.user) {
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          currentUser.emailVerified = authenticationUser.emailVerified;
        }

        // in background
        service.reauthenticateWithStorageToken();
        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });

        localStorage.setItem('currentUser', service.encryption(currentUser, 'secret-c-u'));
        routerAsync().push('/auth/update-account-roles');
        // routerAsync().push('/');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSendVerificationCodeToUserPhone({ commit }, { phoneNumber, appVerifier }) {
      try {
        commit('VERIFICATION_CODE_START');
        await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {  
          window.confirmationResult = confirmationResult;
        }).catch((error) => {
          throw error
        });

        let confirmationResult = window.confirmationResult
        commit('VERIFICATION_CODE_SUCCESS', {
          confirmationResult,
        });
        return true;
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('VERIFICATION_CODE_ERROR');
        return false;
      }
    },
//#endregion


    async doSignout({ commit, dispatch }) {
      try {
        commit('AUTH_START');
        await service.signout();
        // localStorage.clear();
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        commit('AUTH_SUCCESS', {
          authenticationUser: null,
          currentUser: null,
        });
        dispatch('doStopSplash');
        routerAsync().push(`/${localStorage.getItem('language')}`);
      } catch (error) {
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSigninFromAuthChange({ commit }, authenticationUser) {
      try {
        let currentUser = localStorage.getItem('currentUser');
        if (authenticationUser) {
          if (!currentUser) {
            currentUser = await service.fetchMe();
            localStorage.setItem(
              'currentUser',
              service.encryption(currentUser, 'secret-c-u'),
            );
          } else {
            currentUser = service.decryption(
              currentUser,
              'secret-c-u',
            );
          }

          // in background
          // service.reauthenticateWithStorageToken();
          currentUser.emailVerified = authenticationUser.emailVerified;
        }

        commit('AUTH_INIT_SUCCESS', {
          currentUser,
          authenticationUser: authenticationUser,
        });
        ProgressBar.done();
      } catch (error) {
        service.signout();
        Errors.handle(error);
        commit('AUTH_INIT_ERROR', error);
        ProgressBar.done();
      }
    },

    async doRefreshCurrentUser({ commit, getters }) {
      try {
        const authenticationUser = getters.authenticationUser;
        const currentUser = await service.fetchMe();
        currentUser.emailVerified = authenticationUser.emailVerified;
        //#region [ refresh CurrentUser in localStorage ]
        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        //#endregion

        // in background
        service.reauthenticateWithStorageToken();

        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser,
        });
      } catch (error) {
        service.signout();
        Errors.handle(error);

        commit('CURRENT_USER_REFRESH_ERROR', error);
      }
    },

    async doUpdateProfile(
      { commit, dispatch },
      { 
        // firstName, lastName, 
        fullName,
        phoneNumber, 
        countryCode,
        countryName,
        avatar,
        deviceTokens },
    ) {

      try {
        commit('UPDATE_PROFILE_START');

        await service.updateProfile(
          // firstName,
          // lastName,
          fullName,
          phoneNumber,
          countryCode,
          countryName,
          avatar,
          deviceTokens
        );

        commit('UPDATE_PROFILE_SUCCESS');
        await dispatch('doRefreshCurrentUser');
        if(localStorage.getItem('language') == 'ar'){
          Message.success("تم تعديل الملف الشخصى بنجاح")
        }
        else{
          Message.success('Profile updated successfully')
        }
        // Message.success(i18n('auth.profile.success'));
        routerAsync().push('/');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_PROFILE_ERROR');
      }
    },

//#region [ Local Storage Functions ]
    doUpdateCurrentUserInLocalStorage( { commit }, currentUser) {
      if (currentUser) {
        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser,
        });
      }
    },
//#endregion
  },
};
