
import { mapGetters } from 'vuex';
import NoCurrentUserModal from "@/modules/layout/components/no-current-user-modal.vue";

export default {
    name:'app-services-items-expansion',
    data(){
        return{
            modalVisible: false,
            dialogVisible: false,
            item: null
        }
    },
    components:{
      [NoCurrentUserModal.name]: NoCurrentUserModal,
    },
    computed:{
        ...mapGetters({
            currentUser: 'auth/currentUser'
        })
    },
    methods:{
        // redirectTo(value){
        //   console.log(value);
        //   debugger
        // },
        i18n(key, args) {
            return this.$t(key, args);
        },
        closeMenu(value){          
        // console.log(value, this.$route);
        // debugger
        const lang = localStorage.getItem('language')
        this.item = value
        if(this.currentUser){
            // debugger
            this.$router.push(`/${lang}/${value}?page=1`)
        }
        else{
            if(value == 'drug-index'){
            this.$router.push(`/${lang}/${value}?page=1`)
            }
            else{
              this.$emit('closeMenu')
              this.modalVisible = true;
              this.dialogVisible = true;
            }
        }
        },
        close(){          
          this.dialogVisible = false;
          setTimeout(() => {
            this.modalVisible = false;
          }, 500);
        },
        login(){
          // debugger
          this.$emit('login')
        }
    }
}
