import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-contact';
// import firebase from 'firebase';
export class HomeService {
    static async create(data) {
      const response = await graphqlClient.mutate({
        mutation: gql `
          mutation CONTACT_CREATE($data: ContactInput!) {
            contactCreate(data: $data) {
              name
              email

            }
          }
        `,
  
        variables: {
          data,
        },
      });
  
      return response.data.contactCreate;
    }
}