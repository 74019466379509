import gql from "graphql-tag";
import graphqlClient from "@/shared/graphql/client-property";
import firebase from "firebase/app";
import "firebase/firestore";
export class FacilitiesService {
  static async create(data) {
    console.log(data, "data");
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PROPERTY_CREATE($data: PropertyInput!) {
          propertyCreate(data: $data) {
            id
            address
            income
            cityId
            regionId
            listedForId
            typeId
            price
            name
            images
            description
            phoneNumber
            spaceArea
            isClosed
            postStatus
            isActive
            deactivationReasonId
            viewers
            adNumber
            createdAt
            createdBy
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.propertyCreate;
  }
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PROPERTY_UPDATE($id: String!, $data: PropertyInput!) {
          propertyUpdate(id: $id, data: $data) {
            name
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.propertyUpdate;
  }

  static async list(callback) {
    firebase
      .firestore()
      .collection("property")
      .limit(5)
      .onSnapshot(async (coll) => {
        // console.log('in list service')
        coll.docChanges().forEach(async (doc) => {
          let property = doc.doc.data();
          const listedFor = await firebase
            .firestore()
            .collection("facilityListedFor")
            .doc(property.listedForId)
            .get();
          property.listedFor = listedFor.data();
          const creator = await firebase
            .firestore()
            .collection("user")
            .doc(property.createdBy)
            .get();
          property.creator = creator.data();
          callback(property, doc.type);
        });
      });
  }
  static async find(id, callback1, callback2) {
    await firebase
      .firestore()
      .collection("property")
      .doc(id)
      .onSnapshot({ includeMetadataChanges: true }, (coll) => {
        callback1(coll.data());
        this.findUser(coll.data().createdBy, callback2);
      });
  }
  static async findCity({ cityId, regionId }, callback) {
    await firebase
      .firestore()
      .collection("city")
      .doc(cityId)
      .onSnapshot({ includeMetadataChanges: true }, async (coll) => {
        const cityData = coll.data();
        if (regionId) {
          const region = await firebase
            .firestore()
            .collection("city")
            .doc(cityId)
            .collection("regions")
            .doc(regionId)
            .get();
          const regionData = region.data();
          callback({ cityData, regionData });
        } else {
          callback({ cityData });
        }
      });
  }
  static async findFacilityListedFor(id, callback) {
    await firebase
      .firestore()
      .collection("facilityListedFor")
      .doc(id)
      .onSnapshot({ includeMetadataChanges: true }, (coll) => {
        callback(coll.data());
      });
  }
  static async findFacilityType(id, callback) {
    await firebase
      .firestore()
      .collection("facilityType")
      .doc(id)
      .onSnapshot({ includeMetadataChanges: true }, (coll) => {
        callback(coll.data());
      });
  }
  static async findJobPosition(id, callback) {
    await firebase
      .firestore()
      .collection("jobPosition")
      .doc(id)
      .onSnapshot({ includeMetadataChanges: true }, (coll) => {
        callback(coll.data());
      });
  }
  static async findWorkplace(id, callback) {
    await firebase
      .firestore()
      .collection("workplace")
      .doc(id)
      .onSnapshot({ includeMetadataChanges: true }, (coll) => {
        callback(coll.data());
      });
  }
  static async listFacilityType(callback) {
    await firebase
      .firestore()
      .collection("facilityType")
      .orderBy("index", "asc")
      .onSnapshot(async (coll) => {
        // console.log('in list service workplace',coll)
        coll.docs.forEach(async (doc) => {
          let facilityType = doc.data();
          facilityType.id = doc.id;
          // console.log('workplace.id',doc);
          callback(facilityType);
        });
      });
  }
  static async listFacilityListedFor(callback) {
    await firebase
      .firestore()
      .collection("facilityListedFor")
      .orderBy("index", "asc")
      .onSnapshot(async (coll) => {
        // docChanges()
        coll.docs.forEach(async (doc) => {
          // let jobPosition =  doc.doc.data()
          let facilityListedFor = doc.data();
          facilityListedFor["id"] = doc.id;
          // console.log('job position',jobPosition);
          callback(facilityListedFor);
        });
      });
  }
  static async listCity(callback, callback2) {
    await firebase
      .firestore()
      .collection("city")
      .onSnapshot(async (coll) => {
        // console.log('in list service city')
        // .docChanges()
        coll.docs.forEach(async (doc) => {
          // let city =  doc.doc.data()
          let city = doc.data();
          city.id = doc.id;
          // console.log('city with id',city);
          // callback(city,doc.type)
          callback(city);
          let cityRegions = [];
          const regions = await firebase
            .firestore()
            .collection(`city/${city.id}/regions`)
            .get();

          regions.forEach((doc) => {
            let region = doc.data();
            region.id = doc.id;
            cityRegions.push(region);
          });
          callback2(city.id, cityRegions);
        });
      });
  }
  static async listRegion(callback) {
    await firebase
      .firestore()
      .collection("region")
      .onSnapshot(async (coll) => {
        // console.log('in list service region')
        coll.docChanges().forEach(async (doc) => {
          let region = doc.doc.data();
          callback(region, doc.type);
        });
      });
  }
  static async findUser(userId, callback) {
    await firebase
      .firestore()
      .collection("user")
      .doc(userId)
      .onSnapshot((coll) => {
        callback(coll.data());
      });
  }
}
