const en = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    
  },

  app: {
    title: 'Drug Deal',
  },

  drugDeal: {
    common: {
      drugDeal: 'Drug Deal',
      drug: 'DRUG',
      deal: 'DEAL',
      about: 'About',
      home: 'Home',
      services:'Services',
      login:'Login',
      downloadNow:'Download Now',
      aboutUs: 'About us',
      features: 'Features',
      contactUs: 'Contact us',
      privacyPolicy: 'Privacy Policy',
      termsAndConditions: 'Terms and conditions',
      readMore:'Read More',
      submit:'Submit',
      saveChanges:'Save Changes',
      views:'views',
      // submit:'Submit',
      publish:'Publish',
      viewDetails:'View Details',
      yesDelete:'Yes, delete',
      noThanks:'No, Thanks',
      doYouWantToDelete:'Do you want to delete this',
      post:'post',
      delete:'Delete',
      edit:'Edit',
      successfully:'Successfully',
      emptyJobs:"No Job Opportunities",
      emptyPrescriptions:"No Prescriptions",
      emptyFacilities:"No Facilities",
      pleaseLogin:"Please Login To See The",
      error:"Error",
      youCanNotAddPrescription:"You Can Not Add More Than 3 Prescriptions In A Month",
      youCanNotAddFacility:"You Can Not Add More Than 5 Facilities In A Month",
      theBest:'The Best',
      medicalReference:'Medical Reference',
      youNeed:'You Need',
      inOne:'In One',
      place:'Place.',
      invalidTitle:'Invalid Title',
      invalidDescripiton:'Invalid Description',
      invalidCompanyName:'Invalid Company Name',
      invalidWorkplaceName:'Invalid Workplace Name',
      invalidPositionName:'Invalid Position Name',
      invalidRegionName:'Invalid Region Name',
      invalidCityName:'Invalid City Name',
      invalidSalary:'Invalid Salary',
      invalidEmail:'Invalid Email Address',
      invalidPhone:'Invalid Phone Number',
      invalidAddress:'Invalid Address',
      fileSizeLessThanOneMB:"File Size must Be Less than 1MB",
      fileSizeLessThanHalfMB:"File Size must Be Less than 0.5MB",
      profile:'Profile',
      logout:'Logout',
      copyRight:"Copyright ©2021 Drug Deal",
     developedBy:" @ 2021 Developed by Runprof"
      
    },
    header: {
      home:'Home',
      services:'Services',
      about:'About',
      login:'Login',
      welcomeTo: 'Welcome to',
      message: 'The best Medical Reference you need in one place.',
      downloadOnTheAppStore: 'Download on the app store',
      getItOn: 'Get It On',
      googlePlay: 'Google Play',
      
     
    },
    
    footer:{
      downloadMobileApp:'Download Mobile App',
      getTheLatestNews:'Get The Latest News And Updates',
      createdByRunprof:'@ 2021 Developed by Runprof',
      copyright:'Copyright ©2021 Drug Deal',
      facebook:'Facebook',
      twitter:'Twitter',
      instagram:'Instagram',
      join:'Join',
      emailAddress:'Email Address'

    },
    about: {
      about:'About',
      knowMoreAboutUs:'Know more about us',
      aboutDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor .',
      searchForDrugIndexes:'Search For drug indexes',
      alternatives:'alternatives and similarities',
      searchForDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor .',
      checkout:'checkout your',
      prescription:'prescription',
      checkoutDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor .',
      findYour:'find your',
      job:'Job Opportunities',
      jobDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor .'
    },
    services:{
      ourServices:'Our Services',
      servicesDesc:`Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, 
      lectus magna fringilla urna, porttitor , consectetur adipiscing elit ut aliquam, 
      purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor`,
      servicesDetail1:`Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor , 
      consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor`,
      servicesDetail2:`Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor , 
      consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor`,
      drugIndex:'Drug Index',
      drugInteractions:'Drug Interactions',
      prescriptions:'Prescriptions',
      jobs:'Job opportunities',
      facilities:'Facilities',
      sellBuyRent:'Sell, buy and rent',
      offers:'Offers',
      prescription:'prescription',
      job:'job',
      facility:'facility',
      
    },
    drugIndex:{
      searchByDrugName:'Search by drug name',
      searchForDrugs:'Search for Drugs By',
      name:'Name',
      searchBy:'Search by drug name',
      mostSearchedDrugs:'Most Searched Drugs',
      moreDetails:'More Details',
      oldPrice:'Old Price',
      newPrice:'New Price',
      category:'Category',
      manifacturingCompany:'Manifacturing Company',
      manifacturingCompanies:'Manifacturing Companies',
      additionalInfo:'Additional Information',
      noSearchResults:'No search results',
      tryAgain:'Try again',
      le:'L.E',
      alternatives:"Alternatives",
      similarities:"Similaritites",
      tradeName:'Trade Name',
      genericName:'Generic Name',
      company:'Company',
      // newPrice:'New Price'
    },
    prescriptions:{
      // prescrition:'Prescription',
      myPrescriptions:'My Prescriptions',
      prescriptionTitle:'Prescription Title',
      prescriptionText:'Prescription Text',
      viewAndAddPrescription:'View And Add New Prescription',
      addNewPrescription:'Add New A Prescription',
      add:'Add New Prescription',
      comments:'comments',
      viewAllComments:'View More commnents',
      uploadPhoto:'You can upload your prescription photo',
      title:'Title',
      description:'Description',
      commentPlaceholder:"Enter your comment here...",
      typing:"Someone is typing a Comment ..."
     
    },
    download:{
      mobileApp:'Mobile App',
      download:'Download Drug Deal',
      downloadDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor .',
    },
    contactUs:{
      contact:'Contact',
      yourName:'Your name',
      email:'Email',
      phone:'Phone',
      msg:'Type your message here...'
    },
    profile:{
      prescriptions:'Prescriptions',
      jobs:'Jobs',
      properties:'Properties',
      myAccountInfo:'My account information',
      edit:'Edit',
      name:'Name',
      emailAddress:'Email Address',
      phoneNumber:'Phone Number',
      addPhoneNumber:'Add Phone Number',
      firstName: 'First Name',
      lastName: 'Last Name'
      // saveChanges:'Save Changes'
    },
    facilities:{
      myProperties:'My Properties',
      name:'Name',
      spaceArea:'Space Area',
      type:'Type',
      listedFor:'Listed For',
      city:'City',
      region:'Region',
      status:'Status',
      expectedPrice:'Expected Price',
      averageIncome:'Average Income',
      makeSure:'Please make sure that this is your daily income',
      address:'Address',
      phoneNumber:'Phone Number',
      description:'Description',
      uploadPictures:'Upload pictures',
      companyPlaceholder:'Enter your company name here',
      spaceAreaPlaceholder:'Enter your space area here',
      typePlaceholder:'Enter your type here',
      listedForPlaceholder:'This facility is listed for',
      cityPlaceholder:'Enter your city here',
      regionPlaceholder:'Enter your region here',
      statusPlaceholder:'Enter your status here',
      pricePlaceholder:'Enter your expected price here',
      averageIncomePlaceholder:'Enter your average income here',
      addressPlaceholder:'Enter your address here',
      phonePlaceholder:'Enter your phone number here',
      descriptionPlaceholder:'Enter your description here',
      selling:'Selling',
      adNumber:'Ad number',
      dailyIncome:'Daily Income',
      prices:'Prices',
      LE:"L.E",
      youCan:' You can Sell, Buy or Rent a',
      pharmacy:' pharmacy easily within',
      oneClick:'one click',
      add:'Add New Facility',
      facilities:'Facilities',
      searchFor:'Search for facility',
      filter:'Filter By',
      category:'Category',
      // pharmacy:'pharmacy easily within',
      currentStatus:'Current Status',
      closed:'Closed',
      opened:'Opened',
      space:'Space',
      dailyIcome:'Daily Income'

    },
    jobs:{
      myJobs:'My Job Opportunities',
      browse:'Browse job description and',
      moreInfo:'more information and easily',
      find:'find a new job',
      add:'Add New Job',
      jobOpportunities:'Job opportunities',
      search:'Search for jobs',
      filter:'Filter By',
      aboutCompany:'About Company',
      hiring:'Hiring',
      workplace:'Workplace',
      city:'City',
      position:'Position',
      salary:'Salary',
      region:'Region',
      jobInfo:'Job Info',
      company:'Owner',
      individual:'Employee',
      companyName:'Owner Name',
      optional:'Optional',
      negotiable:'Negotiable',
      from:'From',
      to:'To',
      perHour:'Per Hour',
      perMonth:'Per Month',
      email:'Email',
      phoneNumber:'Phone Number',
      address:'Address',
      description:'Description',
      run:'Run this ad until',
      selectDate:'Select Date',
      successMsg:'Your job opportunity waiting admin approval',
      selectCity:'Select city',
      selectRegion:'Select region',
      selectPosition:'Select Position',
      selectWorkplace:'Select Workplace',
      companyPlaceholder:'Enter your company name here',
      emailPlaceholder:'Enter your email address here',
      phonePlaceholder:'Enter your phone number here',
      addressPlaceholder:'Enter your address here',
      datePlaceholder:'Enter your date here',
      descriptionPlaceholder:'Enter your description here',
      hired:'Hired',
      searchForJobs:'Search For Jobs'
      // optional:"Optional"
      // hiring:'Hiring'
    },
    offers:{
      keepUsing:'Keep using drug deal',
      dontMiss:'and don’t miss up our',
      latestOffers:'latest offers.',
      aboutThisOffer:'About this offer'
    },
    // prescriptions:{
      
    // },
    features: {
      mobileApp: 'Mobile app',
      message: 'Stay on top of the best deals',
      searchFor: 'Search For Drugs',
      'searchFor-description': 'Browse drug index, drug Alternatives and similarities.',
      drugInteraction: 'Drug interaction',
      'drugInteraction-description': 'Start typing a drug name and select the best match from, the list of suggestions.',
      prescriptions: 'Prescriptions',
      'prescriptions-description': 'Browse the drug index and similarities.',
      jobOpportunities: 'JOB OPPORTUNITIES',
      'jobOpportunities-description': 'Browse job description and more information.',
      offers: 'Offers',
      'offers-description': 'Get daily offers on medical products.',
      sellAndBuyandrent: 'Sell, Buy and rent',
      'sellAndBuyandrent-description': 'You can Sell, Buy or Rent a pharmacy easily within one click and giving Place fulldescription.',
      keepMessage: 'Keep using drug deal and don’t miss up our latest offers and promotions on different categories.',
    },
    // footer: {
    //   by: 'By',
    //   madeWith: 'Made with',
    //   copyRight : 'copyright@drugdeal.2020'
    // },
  },


  entities: {
    customer: {
      name: 'customer',
      label: 'Customers',
      menu: 'Customers',
      exporterFileName: 'customer_export',
      list: {
        menu: 'Customers',
        title: 'Customers',
      },
      create: {
        success: 'Customer saved successfully',
      },
      update: {
        success: 'Customer saved successfully',
      },
      destroy: {
        success: 'Customer deleted successfully',
      },
      destroyAll: {
        success: 'Customer(s) deleted successfully',
      },
      edit: {
        title: 'Edit Customer',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'birthdateRange': 'Birthdate',
        'birthdate': 'Birthdate',
        'gender': 'Gender',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        'gender': {
          'male': 'Male',
          'female': 'Female',
        },
      },
      new: {
        title: 'New Customer',
      },
      view: {
        title: 'View Customer',
      },
      importer: {
        title: 'Import Customers',
        fileName: 'customer_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    product: {
      name: 'product',
      label: 'Products',
      menu: 'Products',
      exporterFileName: 'product_export',
      list: {
        menu: 'Products',
        title: 'Products',
      },
      create: {
        success: 'Product saved successfully',
      },
      update: {
        success: 'Product saved successfully',
      },
      destroy: {
        success: 'Product deleted successfully',
      },
      destroyAll: {
        success: 'Product(s) deleted successfully',
      },
      edit: {
        title: 'Edit Product',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'description': 'Description',
        'unitPriceRange': 'Unit Price',
        'unitPrice': 'Unit Price',
        'photos': 'Photos',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'New Product',
      },
      view: {
        title: 'View Product',
      },
      importer: {
        title: 'Import Products',
        fileName: 'product_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    order: {
      name: 'order',
      label: 'Orders',
      menu: 'Orders',
      exporterFileName: 'order_export',
      list: {
        menu: 'Orders',
        title: 'Orders',
      },
      create: {
        success: 'Order saved successfully',
      },
      update: {
        success: 'Order saved successfully',
      },
      destroy: {
        success: 'Order deleted successfully',
      },
      destroyAll: {
        success: 'Order(s) deleted successfully',
      },
      edit: {
        title: 'Edit Order',
      },
      fields: {
        id: 'Id',
        'customer': 'Customer',
        'products': 'Products',
        'employee': 'Employee',
        'delivered': 'Delivered',
        'attachments': 'Attachments',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'New Order',
      },
      view: {
        title: 'View Order',
      },
      importer: {
        title: 'Import Orders',
        fileName: 'order_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',
    alreadyHaveAnAccount:
      'Already have an account? Sign in.',
    signinWithAnotherAccount:
      'Sign in with another account',
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email verification`,
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
    },
    passwordResetEmail: {
      message: 'Send password reset email',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message:
        'Just a moment, your email is being verified...',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: `Full access to manage users roles`,
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    customerEditor: {
      label: 'Customer Editor',
      description: 'Edit access to Customers',
    },
    customerViewer: {
      label: 'Customer Viewer',
      description: 'View access to Customers',
    },
    productEditor: {
      label: 'Product Editor',
      description: 'Edit access to Products',
    },
    productViewer: {
      label: 'Product Viewer',
      description: 'View access to Products',
    },
    orderEditor: {
      label: 'Order Editor',
      description: 'Edit access to Orders',
    },
    orderViewer: {
      label: 'Order Viewer',
      description: 'View access to Orders',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess:
        'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess:
        'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint:
        'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint:
        'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists:
        'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint:
      'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },
  home: {
    menu: 'Home',
    message: `This page uses fake data for demonstration purposes only. You can edit it at frontend/src/modules/home/components/home-page.vue.`,
    verificationCode:'Please enter the verification code',
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf:
        '${path} must be one of the following values: ${values}',
      notOneOf:
        '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length:
        '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches:
        '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min:
        '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max:
        '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success:
        'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint:
        'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm:
        'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel:
        'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },

  firebaseErrors: {
    'auth/user-disabled': 'Your account is disabled',
    'auth/user-not-found': `Sorry, we don't recognize your credentials`,
    'auth/wrong-password': `Sorry, we don't recognize your credentials`,
    'auth/weak-password': 'This password is too weak',
    'auth/email-already-in-use': 'Email is already in use',
    'auth/invalid-email': 'Please provide a valid email',
    'auth/account-exists-with-different-credential':
      'Email is already in use for a different authentication method.',
    'auth/credential-already-in-use':
      'Credentials are already in use',
  },

  privacyPolicy: {
    content: `  <div>
        <strong class="privacy-policy-title fontResize" style="color: #C6426E;">Privacy </strong>
        <strong class="privacy-policy-title fontResize" style="color: #000000;">Policy.</strong> 
        <img src="/images/short-line.png" class="short-line-w short-line-h widthResize heightResize" style="padding: 1.389vw 0 5.764vw !important" />
        <img src="/images/lock.png" class="img-lock" />

                  <p>
                    RunProf built the Drug Deal app as
                    a Free app. This SERVICE is provided by
                    Drug Deal at no cost and is intended for use as
                    is.
                  </p> <p>
                    This page is used to inform visitors regarding our
                    policies with the collection, use, and disclosure of Personal
                    Information if anyone decided to use our Service.
                  </p> <p>
                    If you choose to use our Service, then you agree to
                    the collection and use of information in relation to this
                    policy. The Personal Information that we collect is
                    used for providing and improving the Service. We will not use or share your information with
                    anyone except as described in this Privacy Policy.
                  </p> <p>
                    The terms used in this Privacy Policy have the same meanings
                    as in our Terms and Conditions, which is accessible at
                    Drug Deal unless otherwise defined in this Privacy Policy.
                  </p> <p><strong>Information Collection and Use</strong></p> <p>
                    For a better experience, while using our Service, we
                    may require you to provide us with certain personally
                    identifiable information. The information that
                    we request will be retained by us and used as described in this privacy policy.
                  </p> <div><p>
                      The app does use third party services that may collect
                      information used to identify you.
                    </p> <p>
                      Link to privacy policy of third party service providers used
                      by the app
                    </p> 
                    <ul>
                    <li>
                    <a style="color: blue !important;" href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><li>
                    <a style="color: blue !important;" href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li>
                    <a style="color: blue !important;" href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><li>
                    <a style="color: blue !important;" href="https://www.facebook.com/about/privacy/update/printable" target="_blank" rel="noopener noreferrer">Facebook</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Log Data</strong></p> <p>
                    We want to inform you that whenever you
                    use our Service, in a case of an error in the app
                    we collect data and information (through third party
                    products) on your phone called Log Data. This Log Data may
                    include information such as your device Internet Protocol
                    (“IP”) address, device name, operating system version, the
                    configuration of the app when utilizing our Service,
                    the time and date of your use of the Service, and other
                    statistics.
                  </p> <p><strong>Cookies</strong></p> <p>
                    Cookies are files with a small amount of data that are
                    commonly used as anonymous unique identifiers. These are sent
                    to your browser from the websites that you visit and are
                    stored on your device's internal memory.
                  </p> <p>
                    This Service does not use these “cookies” explicitly. However,
                    the app may use third party code and libraries that use
                    “cookies” to collect information and improve their services.
                    You have the option to either accept or refuse these cookies
                    and know when a cookie is being sent to your device. If you
                    choose to refuse our cookies, you may not be able to use some
                    portions of this Service.
                  </p> <p><strong>Service Providers</strong></p> <p>
                    We may employ third-party companies and
                    individuals due to the following reasons:
                  </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                    We want to inform users of this Service
                    that these third parties have access to your Personal
                    Information. The reason is to perform the tasks assigned to
                    them on our behalf. However, they are obligated not to
                    disclose or use the information for any other purpose.
                  </p> <p><strong>Security</strong></p> <p>
                    We value your trust in providing us your
                    Personal Information, thus we are striving to use commercially
                    acceptable means of protecting it. But remember that no method
                    of transmission over the internet, or method of electronic
                    storage is 100% secure and reliable, and we cannot
                    guarantee its absolute security.
                  </p> <p><strong>Links to Other Sites</strong></p> <p>
                    This Service may contain links to other sites. If you click on
                    a third-party link, you will be directed to that site. Note
                    that these external sites are not operated by us.
                    Therefore, we strongly advise you to review the
                    Privacy Policy of these websites. We have
                    no control over and assume no responsibility for the content,
                    privacy policies, or practices of any third-party sites or
                    services.
                  </p> <p><strong>Children’s Privacy</strong></p> <p>
                    These Services do not address anyone under the age of 13.
                    We do not knowingly collect personally
                    identifiable information from children under 13. In the case
                    we discover that a child under 13 has provided
                    us with personal information, we immediately
                    delete this from our servers. If you are a parent or guardian
                    and you are aware that your child has provided us with
                    personal information, please contact us so that
                    we will be able to do necessary actions.
                  </p> <p><strong>Changes to This Privacy Policy</strong></p> <p>
                    We may update our Privacy Policy from
                    time to time. Thus, you are advised to review this page
                    periodically for any changes. We will
                    notify you of any changes by posting the new Privacy Policy on
                    this page.
                  </p> <p>This policy is effective as of 2020-07-07</p> <p><strong>Contact Us</strong></p> <p>
                    If you have any questions or suggestions about our
                    Privacy Policy, do not hesitate to contact us at support@drugdealapp.com.
                  </p>
    </div>`
  },

  termsAndConditions: {
    content: `<div>
                <strong class="privacy-policy-title fontResize" style="color: #C6426E;">Terms and </strong>
                <strong class="privacy-policy-title fontResize" style="color: #000000;">Conditions.</strong> 
                <img src="/images/short-line.png" class="short-line-w short-line-h widthResize heightResize" style="padding: 1.389vw 0 5.764vw !important" />
                <img src="/images/lock.png" class="img-lock" />
                
                              <p>  By downloading or using the app, these terms will
                                automatically apply to you – you should make sure therefore
                                that you read them carefully before using the app. You’re not
                                allowed to copy, or modify the app, any part of the app, or
                                our trademarks in any way. You’re not allowed to attempt to
                                extract the source code of the app, and you also shouldn’t try
                                to translate the app into other languages, or make derivative
                                versions. The app itself, and all the trade marks, copyright,
                                database rights and other intellectual property rights related
                                to it, still belong to Drug Deal.
                              </p> <p>
                                Drug Deal is committed to ensuring that the app is
                                as useful and efficient as possible. For that reason, we
                                reserve the right to make changes to the app or to charge for
                                its services, at any time and for any reason. We will never
                                charge you for the app or its services without making it very
                                clear to you exactly what you’re paying for.
                              </p> <p>
                                The Drug Deal app stores and processes personal data that
                                you have provided to us, in order to provide our
                                Service. It’s your responsibility to keep your phone and
                                access to the app secure. We therefore recommend that you do
                                not jailbreak or root your phone, which is the process of
                                removing software restrictions and limitations imposed by the
                                official operating system of your device. It could make your
                                phone vulnerable to malware/viruses/malicious programs,
                                compromise your phone’s security features and it could mean
                                that the Drug Deal app won’t work properly or at all.
                              </p> <div><p>
                                  The app does use third party services that declare their own
                                  Terms and Conditions.
                                </p> <p>
                                  Link to Terms and Conditions of third party service
                                  providers used by the app
                                </p> <ul><li>
                                <a style="color: blue !important;" href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><li>
                                <a style="color: blue !important;" href="https://firebase.google.com/terms/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li>
                                <a style="color: blue !important;" href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><li>
                                <a style="color: blue !important;" href="https://www.facebook.com/legal/terms/plain_text_terms" target="_blank" rel="noopener noreferrer">Facebook</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p>
                                You should be aware that there are certain things that
                                Drug Deal will not take responsibility for. Certain
                                functions of the app will require the app to have an active
                                internet connection. The connection can be Wi-Fi, or provided
                                by your mobile network provider, but Drug Deal
                                cannot take responsibility for the app not working at full
                                functionality if you don’t have access to Wi-Fi, and you don’t
                                have any of your data allowance left.
                              </p> <p></p> <p>
                                If you’re using the app outside of an area with Wi-Fi, you
                                should remember that your terms of the agreement with your
                                mobile network provider will still apply. As a result, you may
                                be charged by your mobile provider for the cost of data for
                                the duration of the connection while accessing the app, or
                                other third party charges. In using the app, you’re accepting
                                responsibility for any such charges, including roaming data
                                charges if you use the app outside of your home territory
                                (i.e. region or country) without turning off data roaming. If
                                you are not the bill payer for the device on which you’re
                                using the app, please be aware that we assume that you have
                                received permission from the bill payer for using the app.
                              </p> <p>
                                Along the same lines, Drug Deal cannot always take
                                responsibility for the way you use the app i.e. You need to
                                make sure that your device stays charged – if it runs out of
                                battery and you can’t turn it on to avail the Service,
                                Drug Deal cannot accept responsibility.
                              </p> <p>
                                With respect to Drug Deal’s responsibility for your
                                use of the app, when you’re using the app, it’s important to
                                bear in mind that although we endeavour to ensure that it is
                                updated and correct at all times, we do rely on third parties
                                to provide information to us so that we can make it available
                                to you. Drug Deal accepts no liability for any
                                loss, direct or indirect, you experience as a result of
                                relying wholly on this functionality of the app.
                              </p> <p>
                                At some point, we may wish to update the app. The app is
                                currently available on Android &amp; iOS – the requirements for
                                both systems(and for any additional systems we
                                decide to extend the availability of the app to) may change,
                                and you’ll need to download the updates if you want to keep
                                using the app. Drug Deal does not promise that it
                                will always update the app so that it is relevant to you
                                and/or works with the Android &amp; iOS version that you have
                                installed on your device. However, you promise to always
                                accept updates to the application when offered to you, We may
                                also wish to stop providing the app, and may terminate use of
                                it at any time without giving notice of termination to you.
                                Unless we tell you otherwise, upon any termination, (a) the
                                rights and licenses granted to you in these terms will end;
                                (b) you must stop using the app, and (if needed) delete it
                                from your device.
                              </p> <p><strong>Changes to This Terms and Conditions</strong></p> <p>
                                We may update our Terms and Conditions
                                from time to time. Thus, you are advised to review this page
                                periodically for any changes. We will
                                notify you of any changes by posting the new Terms and
                                Conditions on this page.
                              </p> <p>
                                These terms and conditions are effective as of 2020-07-07
                              </p> <p><strong>Contact Us</strong></p> <p>
                                If you have any questions or suggestions about our
                                Terms and Conditions, do not hesitate to contact us
                                at support@drugdealapp.com.
                              </p>
                </div>`
  },

};

export default en;
