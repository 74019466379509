
import DrugIndexViewModal from "@/modules/drug-index/components/drug-index-view-modal.vue";
import DrugIndexFilterModal from "@/modules/drug-index/components/drug-index-filter-modal.vue";

import { mapActions, mapGetters } from "vuex";
// import {i18n} from '@/i18n';
// import firebase from 'firebase/app'
// import 'firebase/firestore';
import i18n from "@/vueI18n";
import AddDialog from "@/modules/services/components/add-dialog.vue";
import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;
// import firebase from 'firebase';
import { AnalyticsManager } from "@/shared/firebase/firebase-analytics";

export default {
  name: "app-drug-index-list-table",
  props: ["searchDrugs", "hideDrugIndex"],
  data() {
    return {
      drugDealURL: "https://drug-deal-web-app.web.app/drug-index/",
      scrollToDrugIndex: false,
      showDrugIndexTable: false,
      searchInputAnimation: false,
      language: "",
      search: "",
      record: null,
      name: "",
      company: "",
      info: "",
      oldPrice: "",
      newPrice: "",
      category: "",
      filterTab: "tradeName",
      tab: "",
      alternatives: null,
      openDialog: false,
      // drugs:[],
      drugsFiltered: [],
      drugsCurrent: [],
      currentDrugPointer: 0,
      lastDrugPointer: 0,
      nbHits: 0,
      totalHitsForAltSim: 250,
      paginationForAltSim: false,
      // noMoreHits:false,

      searchEmpty: false,
      filter: false,
      filterSearch: "",
      loading: false,
      alternativesArray: [],
      similaritiesArray: [],
      similarity: "",
      alternative: "",
      searchOption: "tradeName",
      searchChoices: [],
      pagination: {
        page: 1,
        rowsPerPage: this.getItemsPerPage(),
      },
      checkBoxSimAlt: false,
      drugForAltSim: {},
      similarChecked: "",
      similarAlternative: "",
      genericNames: [],
      routeSimilarties: [],
      pharmacologicalUse: [],
      changeSimilarColor: false,
      altSimTab: "",
      searchInAll: "",
      matchingDrugs: [],

      // algolia variables
      algoliaSearchModel: "",
      pageNumberAlgolia: 0,
      // hitsPerPage:10,

      // filter xs
      topOfPage: true,
      modalFilterVisible: false,
      dialogFilterVisible: false,
      page: 1,
      filterModal: "tradeName",
      searchItems: ["tradeName", "genericName", "newPrice", "company"],
      searchTabs: ["similarities", "alternatives"],
      fromPage: 1,
      currentPage: 1,
      pickedDrug: false,
    };
  },
  components: {
    [DrugIndexViewModal.name]: DrugIndexViewModal,
    [AddDialog.name]: AddDialog,
    [DrugIndexFilterModal.name]: DrugIndexFilterModal,
  },
  watch: {
    drugsCurrent(newval, oldval) {
      console.log("newval==>", newval);
      console.log("oldval==>", oldval);
    },
    // tab(newval){
    //   // debugger
    // },
    // drugIndexHomeSearch(newvalue){
    // },
    // searchDrugs(newvalue,oldvalue){
    //     // this.filterSearch = newvalue
    // },
    // drugsFiltered(newvalue,oldvalue){
    //   // if(oldvalue.length == 0) {
    //   //   this.loading = true
    //   // }
    //   // if(newvalue.length != 0){
    //   //   this.loading = false
    //   // }

    // },
    // getRouteName(newvalue,oldvalue){
    //   if(newvalue=='home' && oldvalue == 'drugIndex'){
    //   }
    // },
    $route(to, from) {
      this.fromPage = from.query.page;
      this.currentPage = to.query.page;
      // alert('called it');
    },
  },
  computed: {
    isRTL() {
      return i18n.locale == "ar";
    },

    rowsPerPageOptions() {
      return [2];
    },
    ...mapGetters({
      limit: "drugIndex/list/limit",
      rows: "drugIndex/list/rows",
      drugIndexHomeSearch: "home/drugIndexHomeSearch",
      drugs: "drugIndex/list/drugs",
      mountedTable: "drugIndex/list/mountedTable",
      index: "drugIndex/list/index",
      is_screen_xs: "layout/is_screen_xs",
      is_screen_md: "layout/is_screen_md",
      is_screen_sm: "layout/is_screen_sm",
    }),
    checkIfRouteIsdrugIndex() {
      if (this.$route.name == "drug-index") {
        return true;
      } else {
        return false;
      }
    },
    getRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    openImageSearch(name) {
      const anchor = document.createElement("a");
      anchor.href = `http://images.google.com/images?um=1&hl=en&safe=active&nfpr=1&q=${name}`;
      anchor.target = "_blank";
      document.body.appendChild(anchor);
      anchor.click();
    },
    disableNext() {
      return (
        this.lastDrugPointer == this.nbHits ||
        this.lastDrugPointer > this.nbHits
      );
    },
    disablePrevious() {
      return this.currentDrugPointer == 0 ? true : false;
    },
    hitsPerPage() {
      const { screen } = this.$q;
      if (screen.lt.sm) {
        return 4;
      }
      if (screen.lt.md) {
        return 6;
      }
      if (screen.lt.lg) {
        return 9;
      }
      if (screen.lt.xl) {
        return 9;
      }
      return 9;
    },
    openFilterModal() {
      this.modalFilterVisible = true;
      this.dialogFilterVisible = true;
    },
    closeFilter(value) {
      // this.prescriptionDialog = false
      this.filterModal = value;
      this.chooseFilterTab(value);
      this.dialogFilterVisible = false;
      setTimeout(() => {
        this.modalFilterVisible = false;
      }, 500);
    },
    genericNamePresenter(value) {
      // return value
      if (value.length > 40) {
        return value.slice(0, 40) + "...";
      } else {
        return value.slice(0, 40);
      }
    },
    drugNamePresenter(value) {
      let drugName = "";
      if (value.length > 50) {
        drugName = value.slice(0, 50) + "...";
      } else {
        drugName = value.slice(0, 50);
      }
      return drugName;
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      s = s.trim();
      s = s.toLowerCase();
      s = s.charAt(0).toUpperCase() + s.slice(1);
      return s;
    },
    openDrugIndexForXs() {
      const ele = document.getElementById("header");
      const target = getScrollTarget(ele);
      const offset = ele.offsetTop;
      const duration = 0;
      this.$emit("setHideDrugIndex", false);
      setScrollPosition(target, offset, duration);

      this.searchInputAnimation = true;
      this.showDrugIndexTable = true;
      this.setDrugIndex(true);
    },
    openDrugIndex() {
      const ele = document.getElementById("header");
      const target = getScrollTarget(ele);
      const offset = ele.offsetTop;
      const duration = 0;
      this.$emit("setHideDrugIndex", false);
      setScrollPosition(target, offset, duration);

      this.searchInputAnimation = true;
      this.showDrugIndexTable = true;
      this.$emit("changeHomeHeader", true);
      this.$emit("changeHomeHeaderAfterDrugIndex", false);
      this.setDrugIndex(true);
      // this.$router.push('/en/drug-index')
    },
    getItemsPerPage() {
      const { screen } = this.$q;
      if (screen.lt.sm) {
        return 4;
      }
      if (screen.lt.md) {
        return 6;
      }
      if (screen.lt.lg) {
        return 9;
      }
      if (screen.lt.xl) {
        return 9;
      }
      return 9;
    },
    scrollDown() {
      this.scrollToDrugIndex = true;
    },

    ...mapActions({
      setHeaderBg: "layout/setHeaderBg",
      setNavbar: "layout/setNavbar",
      setFooter: "layout/setFooter",
      doFetch: "drugIndex/list/doFetch",
      doSetLimit: "drugIndex/list/doSetLimit",
      setDrugIndex: "home/setDrugIndex",
      setMountedTable: "drugIndex/list/setMountedTable",
      algoliaSearch: "drugIndex/list/algoliaSearch",
    }),
    openDrugIndexModal(row) {
      // debugger
      // this.drugForAltSim
      if (Object.keys(this.drugForAltSim).length == 0) {
        // setTimeout(() =>{
        this.openDrugDialog(row);
        // },1000)
      }
    },

    handleScroll() {
      window.scrollBy(0, 150); // Scroll 100px to the right
      // const ele = document.getElementById(eleId); // You need to get your element here
      // const target = getScrollTarget(ele);
      // // const offset = ele.offsetTop - ele.scrollHeight
      // const offset = ele.offsetTop;
      // // const offset = 50
      // const duration = 1000;
      // setScrollPosition(target, offset, duration);
      // this.navigation(eleId);
    },

    doGoToPrevious() {
      this.page = this.page - 1;
      //
      //

      // this.drugsCurrent = this.drugsFiltered.slice(this.currentDrugPointer,this.lastDrugPointer)
      // this.currentDrugPointer = this.currentDrugPointer - this.hitsPerPage()
      // this.lastDrugPointer = this.lastDrugPointer - this.hitsPerPage()
      this.lastDrugPointer = (this.page - 1) * this.hitsPerPage();
      // debugger
      this.searchInAlgoliaDrugs(this.pageNumberAlgolia - 1);

      //

      this.$router.push({
        query: {
          page: this.page,
          filter: this.algoliaSearchModel,
          searchBy: this.searchOption,
        },
      });
    },
    goNextWithoutAltSim(pageNumber) {
      this.page = this.page + 1;
      this.searchInAlgoliaDrugs(pageNumber);
    },
    searchAndResetPage(pageNumber) {
      this.page = 1;
      setTimeout(() => {
        this.searchInAlgoliaDrugs(pageNumber);
      }, 1000);
      // this.searchInAlgoliaDrugs(pageNumber)

      // setTimeout(() => {
      //   this.handleScroll()
      // },2000)
    },
    searchInAlgoliaDrugs(pageNumber) {
      this.loading = true;
      this.removeAltSim();
      if (pageNumber == 0) {
        this.drugsFiltered = [];
        this.currentDrugPointer = 0;
        this.lastDrugPointer = 0;
        this.drugsCurrent = [];
      }
      this.currentDrugPointer = this.lastDrugPointer;
      // debugger
      this.lastDrugPointer = this.currentDrugPointer + this.hitsPerPage();
      // debugger
      this.pageNumberAlgolia = pageNumber;
      // debugger
      // let query = ""
      if (this.algoliaSearchModel != "") {
        switch (this.searchOption) {
          case "tradeName":
            // this.filterWithTabs()
            //#region
            // if(this.hasWhiteSpace(this.algoliaSearchModel)) {
            //     let firstWord = this.algoliaSearchModel.split(/\s+/)
            //     this.index.search(firstWord[0], {
            //       page: pageNumber,
            //       hitsPerPage: this.hitsPerPage(),
            //       // hitsPerPage: this.totalHitsForAltSim,
            //       restrictSearchableAttributes: [
            //         'drugName',
            //       ]
            //     }).then(({ hits,nbHits }) => {
            //         this.nbHits = nbHits
            //         let drugContainingSpaces = []
            //         // firstWord.shift()
            //         firstWord.forEach((word) => {
            //           hits.forEach((el) =>{
            //             if(el.drugName.toLowerCase().includes(word)){
            //               drugContainingSpaces.push(el)
            //             }
            //           })
            //           // drugContainingSpaces.push(...hits.filter(el => el.drugName.toLowerCase().includes(word)))
            //         })
            //         // debugger
            //         // this.drugsFiltered.push(...drugContainingSpaces)
            //         // this.drugsCurrent = this.drugsFiltered.slice(this.currentDrugPointer,this.lastDrugPointer)
            //         // this.drugsCurrent = hits
            //         this.drugsCurrent = drugContainingSpaces
            //         this.loading = false
            //     })
            //     .catch(error => {
            //     });

            // }
            //#endregion
            // else {
            // debugger
            this.index
              .search(this.algoliaSearchModel, {
                // anchoring: "startsWith",
                queryType: "prefixAll",
                page: pageNumber,
                hitsPerPage: this.hitsPerPage(),
                restrictSearchableAttributes: ["drugName"],
                // anchoring: 'startsWith'
                // typoTolerance: false,
              })
              .then(({ hits, nbHits }) => {
                this.nbHits = nbHits;
                // hits.filter(el => el.drugName.toLowerCase().startsWith(this.algoliaSearchModel))

                this.drugsCurrent = hits;
                // this.drugsCurrent = hits.filter(el => el.drugName.toLowerCase().startsWith(this.algoliaSearchModel))
                this.loading = false;
              });
            // .catch(error => {
            //     console.log('error',error);
            // });
            // }
            break;
          case "newPrice":
            this.index
              .search("", {
                filters: `price = ${this.algoliaSearchModel}`,
              })
              // this.index.search(`"${this.algoliaSearchModel}"`, {
              //   page: pageNumber,
              //   hitsPerPage: this.hitsPerPage(),
              //   restrictSearchableAttributes: [
              //     'price',
              //   ],
              //   typoTolerance: false,
              //   exactOnSingleWordQuery: 'none'
              // })

              .then(({ hits, nbHits }) => {
                this.nbHits = nbHits;
                this.drugsFiltered.push(...hits);
                this.drugsCurrent = hits;
                // this.drugsCurrent = this.drugsFiltered.slice(this.currentDrugPointer,this.lastDrugPointer)
                this.loading = false;
              });
            // .catch(error => {
            //   console.log('error',error);
            // });
            break;
          case "genericName":
            this.index
              .search(this.algoliaSearchModel, {
                page: pageNumber,
                queryType: "prefixAll",
                hitsPerPage: this.hitsPerPage(),
                restrictSearchableAttributes: ["genericName"],
              })
              .then(({ hits, nbHits }) => {
                this.nbHits = nbHits;
                this.drugsFiltered.push(...hits);
                this.drugsCurrent = hits;
                // this.drugsCurrent = this.drugsFiltered.slice(this.currentDrugPointer,this.lastDrugPointer)
                this.loading = false;
              });
            // .catch(error => {
            //   console.log('error',error);
            // });
            break;
          case "company":
            this.index
              .search(this.algoliaSearchModel, {
                queryType: "prefixAll",
                page: pageNumber,
                hitsPerPage: this.hitsPerPage(),
                restrictSearchableAttributes: ["company"],
              })
              .then(({ hits, nbHits }) => {
                this.nbHits = nbHits;
                this.drugsFiltered.push(...hits);
                this.drugsCurrent = hits;
                // this.drugsCurrent = this.drugsFiltered.slice(this.currentDrugPointer,this.lastDrugPointer)
                this.loading = false;
              });
            // .catch(error => {
            //   console.log('error',error);
            // });
            break;
          default:
            this.searchOption = "tradeName";
        }
      } else {
        this.drugsCurrent = [];
        this.loading = false;
      }
      if (this.algoliaSearchModel != "") {
        this.$router.push({
          query: {
            page: this.page,
            filter: this.algoliaSearchModel,
            searchBy: this.searchOption,
          },
        });
      } else {
        this.$router.push({
          query: {
            page: this.page,
            searchBy: this.searchOption,
          },
        });
      }

      // }
    },
    closeDialog() {
      this.openDialog = !this.openDialog;
      this.$router.push({
        params: {
          id: null,
        },
      });
    },
    openDrugDialog(record) {
      // this.setHeaderBg('bg-shadow')
      // debugger
      this.record = record;
      this.openDialog = true;
      this.name = record.drugName;
      this.category = record.genericName;
      this.company = record.company;
      this.info = record.mechOfAction;
      this.oldPrice = record.oldPrice;
      this.newPrice = record.price;
      if (!this.$route.params.id) {
        this.$router.push({
          params: {
            id: record.id,
          },
        });
      }
    },
    checkAdult(age) {
      return age >= this.search;
    },
    async checkIconForAltSim(drug, firstPage) {
      if (this.similarChecked !== drug.id && this.checkBoxSimAlt) {
        await this.checkDrugForAltSimAlgolia(drug, firstPage);
      } else {
        this.removeAltSim();
      }
    },
    removeAltSim() {
      // debugger
      // setTimeout(() =>{
      //  this.searchInputAnimation = true
      this.alternatives = null;
      this.checkBoxSimAlt = null;
      this.similarChecked = null;
      this.changeSimilarColor = false;
      this.drugForAltSim = {};
      this.paginationForAltSim = false;
      // setTimeout(() =>{
      // this.tab = ""
      // debugger
      // }, 10000)

      // debugger
      // }, 1000)
      //   this.filterFn()
    },
    goNextInAltSim(callingFromRoute) {
      this.page = this.page + 1;
      this.currentDrugPointer = this.currentDrugPointer + this.hitsPerPage();
      this.lastDrugPointer = this.lastDrugPointer + this.hitsPerPage();
      this.drugsCurrent = this.drugsFiltered.slice(
        this.currentDrugPointer,
        this.lastDrugPointer
      );
      if (!callingFromRoute) {
        this.$router.push({
          query: {
            tab: this.altSimTab,
            id: this.drugForAltSim.id,
            page: this.page,
            filter: this.algoliaSearchModel,
            searchBy: this.searchOption,
          },
        });
      }
    },
    goPreviousInAltSim(callingFromRoute) {
      // debugger
      this.page = this.page - 1;
      this.currentDrugPointer = this.currentDrugPointer - this.hitsPerPage();
      this.lastDrugPointer = this.lastDrugPointer - this.hitsPerPage();
      this.drugsCurrent = this.drugsFiltered.slice(
        this.currentDrugPointer,
        this.lastDrugPointer
      );
      if (!callingFromRoute) {
        this.$router.push({
          query: {
            tab: this.altSimTab,
            id: this.drugForAltSim.id,
            page: this.page,
            filter: this.algoliaSearchModel,
            searchBy: this.searchOption,
          },
        });
      }
    },
    getSimilaritiesForAlgolia(pageNumber) {
      this.loading = true;
      if (pageNumber == 0) {
        this.drugsFiltered = [];
        this.currentDrugPointer = 0;
        this.lastDrugPointer = 0;
        this.drugsCurrent = [];
      }
      this.currentDrugPointer = this.lastDrugPointer;
      this.lastDrugPointer = this.currentDrugPointer + this.hitsPerPage();
      this.pageNumberAlgolia = pageNumber;

      let similaritiesForFilterUnordered = this.similarities.replace(
        /,/g,
        " AND genericName:"
      );
      similaritiesForFilterUnordered =
        "genericName:" + similaritiesForFilterUnordered;
      this.index
        .search("", {
          page: pageNumber,
          hitsPerPage: this.totalHitsForAltSim,
          filters: similaritiesForFilterUnordered,
        })
        .then(({ hits }) => {
          this.drugsFiltered = hits.filter(
            (hit) =>
              hit.genericName.length == this.drugForAltSim.genericName.length
          );
          this.nbHits = this.drugsFiltered.length;
          this.drugsFiltered.sort(function (a, b) {
            if (a.drugName < b.drugName) {
              return -1;
            }
            if (a.drugName > b.drugName) {
              return 1;
            }
            return 0;
          });
          let drugChecked = this.drugForAltSim;
          this.drugsFiltered.sort(function (x, y) {
            return x.id == drugChecked.id ? -1 : y.id == drugChecked.id ? 1 : 0;
          });
          this.drugsCurrent = this.drugsFiltered.slice(
            this.currentDrugPointer,
            this.lastDrugPointer
          );
          this.loading = false;
        });
      // .catch(error => {
      //   console.log('error',error);
      // });
    },
    getAlternativesForAlgolia(pageNumber) {
      if (pageNumber == 0) {
        this.drugsFiltered = [];
        this.currentDrugPointer = 0;
        this.lastDrugPointer = 0;
        this.drugsCurrent = [];
      }
      this.currentDrugPointer = this.lastDrugPointer;
      this.lastDrugPointer = this.currentDrugPointer + this.hitsPerPage();
      this.pageNumberAlgolia = pageNumber;

      // this.alternatives.forEach((alt) => {
      //   alt = "'" + alt + "'"
      // })
      let alternativeForFilterUnordered = this.alternative.replace(
        /,/g,
        " AND pharmacologicalUse:"
      );
      alternativeForFilterUnordered =
        "pharmacologicalUse:" + alternativeForFilterUnordered;
      this.index
        .search("", {
          page: pageNumber,
          hitsPerPage: this.totalHitsForAltSim,
          filters: alternativeForFilterUnordered,
        })
        .then(({ hits }) => {
          this.drugsFiltered = hits.filter(
            (hit) =>
              hit.pharmacologicalUse.length ==
              this.drugForAltSim.pharmacologicalUse.length
          );
          this.nbHits = this.drugsFiltered.length;
          this.drugsFiltered.sort(function (a, b) {
            if (a.drugName < b.drugName) {
              return -1;
            }
            if (a.drugName > b.drugName) {
              return 1;
            }
            return 0;
          });
          let drugChecked = this.drugForAltSim;
          this.drugsFiltered.sort(function (x, y) {
            return x.id == drugChecked.id ? -1 : y.id == drugChecked.id ? 1 : 0;
          });
          this.drugsCurrent = this.drugsFiltered.slice(
            this.currentDrugPointer,
            this.lastDrugPointer
          );
        });
      // .catch(error => {
      //   console.log('error',error);
      // });
    },

    checkDrugForAltSimAlgolia(drug, firstPage) {
      // debugger
      this.page = 1;
      this.drugForAltSim = drug;
      if (this.altSimTab === "similarities") {
        this.checkBoxSimAlt = !this.checkBoxSimAlt;
        this.similarChecked = drug.id;
        this.genericNames = drug.genericName.toString();
        let genericNames = drug.genericName
          .map((gName) => `"${gName}"`)
          .join(",");
        this.genericNames = genericNames.toString();
        this.similarities = this.genericNames;
        this.getSimilaritiesForAlgolia(0);
        this.changeSimilarColor = true;
        if (firstPage) {
          this.$router.push({
            query: {
              tab: "similarities",
              id: drug.id,
              page: this.page,
              filter: this.algoliaSearchModel,
              searchBy: this.searchOption,
            },
          });
        }
      }
      if (this.altSimTab === "alternatives") {
        this.checkBoxSimAlt = !this.checkBoxSimAlt;
        this.similarChecked = drug.id;
        this.pharmacologicalUse = drug.pharmacologicalUse
          .map((x) => "'" + x + "'")
          .toString();
        this.alternative = this.pharmacologicalUse;
        this.getAlternativesForAlgolia(0);
        this.changeSimilarColor = true;

        if (firstPage) {
          this.$router.push({
            query: {
              tab: "alternatives",
              id: drug.id,
              page: this.page,
              filter: this.algoliaSearchModel,
              searchBy: this.searchOption,
            },
          });
        }
      }
      this.pickedDrug = true;
    },
    checkDrug(drug) {
      this.drugForAltSim = drug;
      if (this.altSimTab === "similarities") {
        this.checkBoxSimAlt = !this.checkBoxSimAlt;
        this.similarChecked = drug.id;

        this.genericNames = drug.genericName.toString();
        let drugSimilarities = [];

        this.similarities = this.genericNames;
        // this.routeSimilarties = drug.route.toString()
        drugSimilarities.push(this.drugs.filter(this.getSimilarities));
        let newArr = [];
        for (var i = 0; i < drugSimilarities.length; i++) {
          newArr = newArr.concat(drugSimilarities[i]);
        }
        this.drugsFiltered = newArr;

        this.changeSimilarColor = true;

        this.drugsFiltered.sort(function (a, b) {
          if (a.drugName < b.drugName) {
            return -1;
          }
          if (a.drugName > b.drugName) {
            return 1;
          }
          return 0;
        });

        // this.drugsFiltered.sort(function(x,y){ return x.id == drug.id ? -1 : y.id == drug.id ? 1 : 0; });
      }
      if (this.altSimTab === "alternatives") {
        this.checkBoxSimAlt = !this.checkBoxSimAlt;
        this.similarChecked = drug.id;
        this.pharmacologicalUse = drug.pharmacologicalUse.toString();
        let drugAlternatives = [];
        this.alternative = this.pharmacologicalUse;
        drugAlternatives.push(this.drugs.filter(this.getAlternatives));
        let newArr = [];
        for (var j = 0; j < drugAlternatives.length; j++) {
          newArr = newArr.concat(drugAlternatives[j]);
        }
        this.drugsFiltered = newArr;

        this.changeSimilarColor = true;

        this.drugsFiltered.sort(function (a, b) {
          if (a.drugName < b.drugName) {
            return -1;
          }
          if (a.drugName > b.drugName) {
            return 1;
          }
          return 0;
        });
        this.drugsFiltered.sort(function (x, y) {
          return x.id == drug.id ? -1 : y.id == drug.id ? 1 : 0;
        });
      }
    },
    chooseTabs(value, firstPage) {
      AnalyticsManager.logDrugSimilaritiesAlternativesSelection(value);
      this.checkBoxSimAlt = true;
      this.paginationForAltSim = true;
      if (value === "alternatives") {
        this.alternatives = true;
      }
      if (value === "similarities") {
        this.alternatives = false;
      }

      this.altSimTab = value;
      if (Object.keys(this.drugForAltSim).length !== 0) {
        this.checkDrugForAltSimAlgolia(this.drugForAltSim, firstPage);
      }
    },
    getSimilarities(value) {
      if (
        value.genericName.toString().toLowerCase() ===
        this.similarities.toLowerCase()
      ) {
        return value;
      }
    },
    getAlternatives(value) {
      // if(value.pharmacologicalUse.toString().toLowerCase().includes(this.alternative.toLowerCase())){
      if (
        value.pharmacologicalUse.toString().toLowerCase() ===
        this.alternative.toLowerCase()
      ) {
        return value;
      }
    },
    checkName(value) {
      if (this.searchOption == "tradeName") {
        if (value.drugName.toLowerCase().includes(this.search.toLowerCase())) {
          return value;
        }
      }
      if (this.searchOption == "genericName") {
        if (
          value.genericName
            .toString()
            .toLowerCase()
            .includes(this.search.toLowerCase())
        ) {
          return value;
        }
      }
      if (this.searchOption == "newPrice") {
        if (
          value.price
            .toString()
            .toLowerCase()
            .includes(this.search.toLowerCase())
        ) {
          return value;
        }
      }
      if (this.searchOption == "company") {
        const search = value.company.toString();
        if (search.toLowerCase().includes(this.search.toLowerCase())) {
          return value;
        }
      }
    },

    checkForTab(event) {
      if (event.key == "Tab") {
        event.preventDefault();
        this.search = this.search + " ";
      }
    },
    hasWhiteSpace(s) {
      return /\s/g.test(s);
    },

    contains(target, pattern) {
      const result = pattern.every((p) => {
        if (target.includes(p)) {
          return target.includes(p);
        }
      });
      return result;
    },
    loadSuggest(drugs, search) {
      var terms = search.split(" ");
      return drugs.filter(function (element) {
        return terms.every(function (e) {
          return element.drugName.toLowerCase().indexOf(e.toLowerCase()) !== -1;
        });
      });
    },
    filterWithTabs() {
      this.drugsFiltered = this.loadSuggest(this.drugs, this.search);
    },
    filterFn() {
      let spaceIncluded = this.hasWhiteSpace(this.search);

      if (spaceIncluded && this.searchOption == "tradeName") {
        this.filterWithTabs();
      } else {
        this.drugsFiltered = this.drugs.filter(this.checkName);
      }
      if (this.drugsFiltered.length == 0) {
        this.searchEmpty = true;
      }
    },
    // openFilter(){
    //   this.filter = !this.filter
    // },
    closeSearch(searchOption) {
      this.filter = !this.filter;
      this.searchOption = searchOption;
    },
    chooseFilterTab(value) {
      // debugger
      // const analytics = firebase.analytics()
      // analytics.logEvent('drug_filter', {
      //     name: value,
      // });
      // debugger

      AnalyticsManager.logDrugFilterSelection(value);

      this.algoliaSearchModel = "";
      this.searchInAlgoliaDrugs(0);
      this.searchOption = value;
      // this.drugsFiltered = this.drugs.filter(this.checkName)
    },

    scrollHandler(scroll) {
      if (
        scroll.position == 0 &&
        !this.openDialog &&
        !this.modalFilterVisible
      ) {
        this.setHeaderBg("bg-transparent");
        this.topOfPage = true;
      }
      if (scroll.position > 0 && !this.openDialog && !this.modalFilterVisible) {
        // debugger
        this.topOfPage = false;
        // ================== without the setTimeout it does not work sometimes ==============
        setTimeout(() => {
          this.setHeaderBg("bg-shadow");
        }, 0);

        this.searchInputAnimation = false;
        if (!this.hideDrugIndex) {
          this.$emit("changeHomeHeader", false);
        }
      }
    },
    navigateBackwardFroward() {
      if (this.$route.name == "home") {
        this.setDrugIndex(false);
      }
      if (this.$route.name == "drugIndex") {
        this.searchOption = this.$route.query.searchBy;
        this.page = this.isNumeric(this.page)
          ? parseInt(this.$route.query.page)
          : 1;
        const simAltTab = this.$route.query.tab;
        this.algoliaSearchModel = this.$route.query.filter;

        if (
          this.isNumeric(this.page) &&
          this.page > 0 &&
          this.searchItems.includes(this.searchOption) &&
          !this.searchTabs.includes(simAltTab) &&
          this.$route.query.filter != ""
        ) {
          this.algoliaSearchModel = this.$route.query.filter;
          this.lastDrugPointer = (this.page - 1) * this.hitsPerPage();
          this.tab = "";
          this.drugForAltSim = null;
          this.searchInAlgoliaDrugs(this.page - 1);

          // this.fetchedFromRoute =
        } else if (
          this.isNumeric(this.page) &&
          this.page > 0 &&
          this.searchItems.includes(this.searchOption) &&
          this.$route.query.filter != "" &&
          simAltTab == "alternatives"
        ) {
          // debugger
          this.index.getObject(this.$route.query.id).then((object) => {
            if (this.page == 1 && this.$route.query.id) {
              this.drugForAltSim = object;
              this.lastDrugPointer = (this.page - 1) * this.hitsPerPage();
              this.chooseTabs("alternatives", false);
            } else {
              // debugger
              if (parseInt(this.fromPage) > parseInt(this.currentPage)) {
                // debugger
                this.goPreviousInAltSim(true);
              }
              if (parseInt(this.fromPage) < parseInt(this.currentPage)) {
                // debugger
                this.goNextInAltSim(true);
              }
            }
          });
        } else if (
          this.isNumeric(this.page) &&
          this.page > 0 &&
          this.searchItems.includes(this.searchOption) &&
          this.$route.query.filter != "" &&
          simAltTab == "similarities"
        ) {
          this.index.getObject(this.$route.query.id).then((object) => {
            if (this.page == 1 && this.$route.query.id) {
              this.drugForAltSim = object;
              this.lastDrugPointer = (this.page - 1) * this.hitsPerPage();
              this.chooseTabs("similarities", false);
            } else {
              // debugger
              if (parseInt(this.fromPage) > parseInt(this.currentPage)) {
                // debugger
                this.goPreviousInAltSim(true);
              }
              if (parseInt(this.fromPage) < parseInt(this.currentPage)) {
                // debugger
                this.goNextInAltSim(true);
              }
            }
          });
        } else {
          this.searchOption = "tradeName";
          this.page = 1;
          this.algoliaSearchModel = "";
          this.$router.replace({
            query: {},
          });
        }
      }
    },
  },
  async mounted() {
    this.language = localStorage.getItem("language");
    this.setHeaderBg("bg-transparent");
    this.setNavbar("services");
    this.setFooter(false);
    if (!this.mountedTable) {
      this.setMountedTable(true);
    }
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardFroward();
    };
  },
  async created() {
    // debugger
    this.algoliaSearch();
    // debugger

    if (
      this.$route.name == "home" &&
      Object.values(this.$route.query).length > 0
    ) {
      // debugger
      // this.$router.push({
      //   name: 'drugIndex'),
      //   query: this.$route.query
      // }
      // this.$router.push('en/prescriptions')
      // debugger
    }

    this.searchOption = this.$route.query.searchBy;
    this.page = this.isNumeric(this.page)
      ? parseInt(this.$route.query.page)
      : 1;
    const simAltTab = this.$route.query.tab;
    this.algoliaSearchModel = this.$route.query.filter;

    if (this.$route.name == "drugIndex" && this.$route.params.id) {
      // debugger
      // EQ6shlBns4RVacyy05dl
      this.index.getObject(this.$route.params.id).then((object) => {
        this.openDrugDialog(object);
        // debugger
        // this.drugForAltSim = object
        // this.lastDrugPointer = (this.page - 1) * this.hitsPerPage()
        // this.chooseTabs('similarities', false)
      });
    }

    if (
      this.$route.name == "drugIndex" &&
      this.isNumeric(this.page) &&
      this.page > 0 &&
      this.searchItems.includes(this.searchOption) &&
      !this.searchTabs.includes(simAltTab) &&
      this.$route.query.filter != ""
    ) {
      this.algoliaSearchModel = this.$route.query.filter;
      this.lastDrugPointer = (this.page - 1) * this.hitsPerPage();
      this.searchInAlgoliaDrugs(this.page - 1);
      // this.fetchedFromRoute =
    } else if (
      this.$route.name == "drugIndex" &&
      this.isNumeric(this.page) &&
      this.page > 0 &&
      this.searchItems.includes(this.searchOption) &&
      this.$route.query.filter != "" &&
      simAltTab == "alternatives"
    ) {
      this.index.getObject(this.$route.query.id).then((object) => {
        this.drugForAltSim = object;
        this.lastDrugPointer = (this.page - 1) * this.hitsPerPage();
        this.chooseTabs("alternatives", false);
      });
    } else if (
      this.$route.name == "drugIndex" &&
      this.isNumeric(this.page) &&
      this.page > 0 &&
      this.searchItems.includes(this.searchOption) &&
      this.$route.query.filter != "" &&
      simAltTab == "similarities"
    ) {
      this.index.getObject(this.$route.query.id).then((object) => {
        this.drugForAltSim = object;
        this.lastDrugPointer = (this.page - 1) * this.hitsPerPage();
        this.chooseTabs("similarities", false);
      });
    } else {
      this.searchOption = "tradeName";
      this.page = 1;
      this.algoliaSearchModel = "";
      this.$router.replace({
        query: {},
      });
    }
  },
};
