import { JobsService } from '@/modules/jobs/jobs-service';
import Errors from '@/shared/error/errors';
import { firestoreAction,vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/app';
// import { routerAsync } from '@/app-module';

export default {
  namespaced: true,

  state: {
    loading: false,
    userLoading:false,
    cityLoading:false,
    jobPositionLoading: false,
    workplaceLoading: false,
    record: null,
    user: null,
    city: null,
    region: null,
    jobPosition: null,
    workplace: null,
    recordMounted: false
  },

  getters: {
    record: (state) => state.record,
    user: (state) => state.user,
    loading: (state) => !!state.loading,
    userLoading: (state) => !!state.loading,
    cityLoading: (state) => !!state.cityLoading,
    jobPositionLoading: (state) => !!state.jobPositionLoading,
    workplaceLoading: (state) => !!state.workplaceLoading,

    city: (state) => state.city,
    region: (state) => state.region,
    jobPosition: (state) => state.jobPosition,
    workplace: (state) => state.workplace,
    recordMounted: (state) => state.recordMounted


  },

  mutations: {
    RESET_RECORD(state){
      state.record = null;
      state.city = null;
      state.region = null;
      state.jobPosition = null;
      state.workplace = null
    },
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state) {
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
    FIND_USER_STARTED(state) {
      state.user = null;
      state.userLoading = true;
    },

    FIND_USER_SUCCESS(state) {
      state.userLoading = false;
    },

    FIND_USER_ERROR(state) {
      state.user = null;
      state.userLoading = false;
    },
    FIND_CITY_STARTED(state){
      state.cityLoading = true
    },
    FIND_CITY_SUCCESS(state,payload){
      state.cityLoading = false
      state.city = payload.cityData
      state.region = payload.regionData
    },
    FIND_CITY_ERROR(state){
        state.cityLoading = false
    },

    FIND_JOBPOSITION_STARTED(state){
      state.jobPositionLoading = true
    },
    FIND_JOBPOSITION_SUCCESS(state,payload){
      state.jobPosition = payload
      state.jobPositionLoading = false      
    },
    FIND_JOBPOSITION_ERROR(state){
      state.jobPositionLoading = false
    },
    FIND_WORKPLACE_STARTED(state){
      state.workplaceLoading = true
    },
    FIND_WORKPLACE_SUCCESS(state,payload){
      state.workplace = payload
      state.workplaceLoading = false      
    },
    FIND_WORKPLACE_ERROR(state){
      state.workplaceLoading = false
    },
    RECORD_MOUNTED(state){
      state.recordMounted = true
    },
    ...vuexfireMutations,

  },

  actions: {
    doFind:  firestoreAction(async ({bindFirestoreRef,getters,commit},payload) => {
      commit('FIND_STARTED');
      commit('FIND_USER_STARTED');
      const db = firebase.firestore()
      const recordDocument =  db.collection('job').doc(payload.id)
      await bindFirestoreRef(
        'record',
        recordDocument,
      );
      const userDocument = db.collection('user').doc(getters.record.createdBy) 
      await bindFirestoreRef(
        'user',
        userDocument,
      );
      commit('FIND_USER_SUCCESS')
      const cityDocument = db.collection('city').doc(getters.record.cityId) 
      await bindFirestoreRef(
        'city',
        cityDocument,
      );
      if(getters.record.regionId){
        const regionDocument = db.collection('city').doc(getters.record.cityId).collection('regions').doc(getters.record.regionId)
        await bindFirestoreRef(
          'region',
          regionDocument,
        );
        console.log('getters.region',getters.region);
      }
      
      const workplaceDocument = db.collection('workplace').doc(getters.record.workplaceId) 
      await bindFirestoreRef(
        'workplace',
        workplaceDocument,
      );
      const positionDocument = db.collection('jobPosition').doc(getters.record.positionId) 
      await bindFirestoreRef(
        'jobPosition',
        positionDocument,
      );
      commit('FIND_SUCCESS')  
    }),


    // async doFind({ commit },values) {
    //   try {
    //     // console.log('in doFind view store')
    //     commit('FIND_STARTED');
    //     commit('FIND_USER_STARTED');
    //     await JobsService.find(values.id, async(doc) => {
    //       commit('FIND_SUCCESS',  doc); 
    //           },(user) =>{
    //             // console.log('user',user)
    //             commit('FIND_USER_SUCCESS',  user);
    //           }
    //       );
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FIND_ERROR');
    //     commit('FIND_USER_ERROR');
    //     // routerAsync().push('/offer');
    //   }
    // },
    async doFindCity({ commit },values) {
        try {
        //   console.log('in doFind view store')
          commit('FIND_CITY_STARTED');
        //   commit('FIND_USER_STARTED');
            await JobsService.findCity({cityId:values.cityId,regionId:values.regionId}, async(doc) => {
                commit('FIND_CITY_SUCCESS',  doc); 
            });
        } catch (error) {
          Errors.handle(error);
          commit('FIND_CITY_ERROR');
        //   commit('FIND_ERROR');
        //   commit('FIND_USER_ERROR');
          // routerAsync().push('/offer');
        }
      },
      async doFindJobPosition({ commit },values) {
        try {
        //   console.log('in doFind view store')
          commit('FIND_JOBPOSITION_STARTED');
        //   commit('FIND_USER_STARTED');
            await JobsService.findJobPosition(values.id, async(doc) => {
                commit('FIND_JOBPOSITION_SUCCESS', doc); 
            });
        } catch (error) {
          Errors.handle(error);
          commit('FIND_JOBPOSITION_ERROR');
        //   commit('FIND_ERROR');
        //   commit('FIND_USER_ERROR');
          // routerAsync().push('/offer');
        }
      },
      async doFindWorkplace({ commit },values) {
        try {
        //   console.log('in doFind view store')
          commit('FIND_WORKPLACE_STARTED');
        //   commit('FIND_USER_STARTED');
            await JobsService.findWorkplace(values.id, async(doc) => {
                commit('FIND_WORKPLACE_SUCCESS', doc); 
            });
        } catch (error) {
          Errors.handle(error);
          commit('FIND_WORKPLACE_ERROR');
        //   commit('FIND_ERROR');
        //   commit('FIND_USER_ERROR');
          // routerAsync().push('/offer');
        }
      },
      setRecordMounted({commit},value){
        commit('RECORD_MOUNTED',value)
      },
      resetRecord({commit}){
        commit('RESET_RECORD')
      }
  },
};
