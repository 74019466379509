
import{ mapActions, mapGetters } from 'vuex';
import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/analytics';
import { AnalyticsManager } from '@/shared/firebase/firebase-analytics';
import i18n from '@/vueI18n';


export default {
    name:'app-register-card',
    props:['signup','visible','title','registerAction','loginOrSignup','socialMedia'],
    data(){
        return{
            loginForm:false,
            email:'',
            password:'',
            validPassword: false,
            validEmail: false,
            confirmEmail: false,
            phoneNumberSection: false,
            phoneNumber:'',
            user:null,
            validPhone:false,
            loadingPhone:false,
            typePassword:true,
            provider:null,
            successfullyCreated: false,
            signedIn: false
        }
    },

    methods:{
         i18n(key, args) {
            return this.$t(key, args);
        },
        close(){
            this.$emit('closeDialog')
        },
        toggleCard(){
            this.signupCard =! this.signupCard 
            this.loginForm = false
        },
        ...mapActions({
            doRegisterEmailAndPassword: 'auth/doRegisterEmailAndPassword',
            doSigninSocial: 'auth/doSigninSocial',
            doSigninWithEmailAndPassword:'auth/doSigninWithEmailAndPassword',
            doSendEmailConfirmation:'auth/doSendEmailConfirmation',
            doEmailVerified: 'auth/doEmailVerified',
            doRefreshCurrentUser: 'auth/doRefreshCurrentUser',
            doFetchUser:'auth/doFetchUser',
            deleteUserFromAuthTable: 'layout/deleteUserFromAuthTable'
        }),
        async doSubmit(){
            // login
            // await this.deleteUserFromAuthTable(this.anonymousUid)


            if(!this.signupCard) {
                await this.doSigninWithEmailAndPassword({email:this.email,password:this.password,rememberMe:true})
                // const analytics = getAnalytics();
                // logEvent(analytics, 'notification_received');
                AnalyticsManager.logSignIn('email')
                this.close() 
                await this.doFetchUser(this.currentUser.id)
                AnalyticsManager.setUserId(this.currentUser.id)
                AnalyticsManager.setUserLanguage(i18n.locale)
            }
            // sign up
            else {
                await this.doRegisterEmailAndPassword({email:this.email,password:this.password})

                AnalyticsManager.logSignUp('email')


                // const analytics = getAnalytics();
                // firebase.analytics().logEvent('notification_received');
                // logEvent(analytics, 'notification_received');
                // const analytics = firebase.analytics()
                // analytics.logEvent('Login', {
                //     content_type: 'image',
                //     content_id: 'P12453',
                //     items: [{ name: 'Kittens' }]
                // });

                await this.doFetchUser(this.currentUser.id)
                AnalyticsManager.setUserId(this.currentUser.id)
                AnalyticsManager.setUserLanguage(i18n.locale)
                this.successfullyCreated = true
                setTimeout(() => {
               this.close() 
            },3000)
            }

            
            
        },
        async addPhoneNumber(){
            this.loadingPhone = true
            this.user = this.currentUser
            const updatedTime = firebase.firestore.Timestamp.fromDate(new Date()).toDate();
            this.user.updatedAt = updatedTime
            this.user.phoneNumber = this.phoneNumber
            await firebase.firestore().collection('user').onSnapshot({ includeMetadataChanges: true },async () => {
                await firebase.firestore().collection('user').doc(this.currentUser.id).set(this.user)
                await this.doRefreshCurrentUser()
                this.loadingPhone = false
            })
            this.phoneNumberSection = false
            if(this.currentUser.emailVerified){
                this.$emit('closeDialog')
            }
            else{
                this.confirmEmail = true
            }
        },
        async verifyAccount(){
            await this.doEmailVerified()
        },
        async resendEmailConfirmation(){
            this.doSendEmailConfirmation(this.currentUser.email)
        },
        async SignInWithSocial(provider){
            // await this.deleteUserFromAuthTable(this.anonymousUid)
            this.provider = provider
            await this.doSigninSocial({ provider, rememberMe: true })
            AnalyticsManager.logSignIn(provider)
            await this.doFetchUser(this.currentUser.id)
            AnalyticsManager.setUserId(this.currentUser.id)           
            AnalyticsManager.setUserLanguage(i18n.locale)

            this.showCard = !this.showCard
        },
        validateEmail(email){
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if(re.test(String(email).toLowerCase())){
            this.validEmail = true
            return true
        }
        else{
            this.validEmail = false
            return false
        }  
        },
        validatePassword(password){
            if(password.length >= 6){
                this.validPassword = true
                return true
            }
            else{
                this.validPassword = false
                return false
            }  
        },
        validatePhone(inputtxt){
            const phoneno = /^\d{11}$/
            if (phoneno.test(inputtxt)){
                this.validPhone = true
                return true; 
            }
            else {
                this.validPhone = false
                return false;
            } 
            },
        },
        watch:{
            // userListener(newvalue,oldvalue){
            //     console.log('userListener newvalue',newvalue);
            //     console.log('userListener oldvalue',oldvalue);
            // }
        },
     computed:{
         ...mapGetters({
            currentUser: 'auth/currentUser',
            loading: 'auth/loading',
            loadingEmailConfirmation: 'auth/loadingEmailConfirmation',
            loadingVerify: 'auth/loadingVerify',
            userListener:'auth/userListener',
            anonymousUid: 'layout/anonymousUid'
        }),

        dialogVisible: {
            get: function() {
                return this.visible;
            },
            set: function(value) {
                if (!value) {
                this.$emit('close');
                }
            },
        },

        showCard: {
        get() {
            return this.visible
        },
        set() {
            this.$emit('closeDialog');
        },
        },
        signupCard: {
        get() {
            return this.signup
        },
        set() {
            this.$emit('toggleSignup');
        },
        }
    },
    mounted(){
        this.$q.iconSet.field.error = "img:/images/invalid-email.png"
    }

}
