// import { storeAsync } from '@/app-module';
import { storeAsync } from '@/app-module';
export default {
  async beforeRouteEnter(to, from, next) {
    const store = storeAsync();
    await store.dispatch('auth/doWaitUntilInit');
    // debugger
    let { lang } = to.params
    if (!lang || (lang !== 'ar' && lang !== 'en')){
      lang = localStorage.getItem('language') || 'en'
      return next({ path: `/${lang}${to.path}` });
    }
    if (!to.meta || !to.meta.unauth) {
        if (to.path !== `/${lang}`&& !to.path.includes('drug-index') && to.path !== `/${lang}/services` && to.path !== `/${lang}/about` && !store.getters['auth/signedIn']) {
          next({ path: `/${lang}` });
          return;
        }
      next();
      return;
    }

//     await storeAsync().dispatch('auth/doWaitUntilInit');

//     if (storeAsync().getters['auth/signedIn']) {
//       next('/');
//     } else {
//       next();
//     }
  },
};
