import { storeAsync } from '@/app-module';
// import { I18nUtil } from '@/shared/i18n/i18n-util';
// import { getLanguageCode } from '@/i18n';
import i18n from '@/vueI18n'

export default {
  async beforeRouteEnter(to, from, next) {
    if (!to.path.includes('drug-index')) {
      // storeAsync().dispatch('home/setDrugIndex', false);
    }
    let { lang } = to.params
    // check for language and redirect to the same path with default language
    // debugger
    if (!lang || (lang !== 'ar' && lang !== 'en')){
      lang = localStorage.getItem('language') || 'en'
      return next({ path: `/${lang}${to.path}` });
    }
    i18n.locale = lang
    if(lang !== 'ar' && lang !== 'en') lang = 'en'
    localStorage.setItem("language",lang)
    const store = storeAsync();
    // let currentUser = localStorage.getItem('currentUser');
      
    await store.dispatch('auth/doWaitUntilInit');
    
    if (!to.meta || !to.meta.auth) {
      // && to.path !== `/${lang}/drug-index`
        if (to.path !== `/${lang}` && !to.path.includes('drug-index')  && to.path !== `/${lang}/services` && to.path !== `/${lang}/about` && !store.getters['auth/signedIn']) {
          next({ path: `/${lang}` });
          return;
        }
      next();
      return;
    }
    if(to.meta.auth){
      // && to.path !== `/${lang}/drug-index`
    if (to.path !== `/${lang}` && !to.path.includes('drug-index') && to.path !== `/${lang}/services` && to.path !== `/${lang}/about` && !store.getters['auth/signedIn'] 
    ) {
        next({ path: `/${lang}` });
        return;
      }
    if(to.path == `/${lang}/add-job` || to.path == '/add-job'){
      if(!store.getters['auth/userListener']){
        const currentUser = store.getters['auth/currentUser']
        await store.dispatch('auth/doFetchUser',currentUser.id);
      }
      let addedJobInMonth = 0
      var today = new Date();
      if(store.getters['auth/userListener']){
        if(store.getters['auth/userListener'].job || store.getters['auth/userListener'].job != undefined){    
          for (const [key] of Object.entries(store.getters['auth/userListener'].job)) {
              var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
              var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
              if(key >= firstDay && key < lastDay){
                addedJobInMonth = addedJobInMonth + 1
              }
          }
          await store.dispatch('auth/checkUserJobInAMonth',addedJobInMonth);   
      }
      }     
    }


    if(to.path == `/${lang}/add-facility` || to.path == '/add-facility'){
      if(!store.getters['auth/userListener']){
        const currentUser = store.getters['auth/currentUser']
        await store.dispatch('auth/doFetchUser',currentUser.id);
      }
      let addedPropertyInMonth = 0
      var todayProperty = new Date();
      if(store.getters['auth/userListener']){
        if(store.getters['auth/userListener'].property || store.getters['auth/userListener'].property != undefined){    
          for (const [key] of Object.entries(store.getters['auth/userListener'].property)) {
              var firstDayProperty = new Date(todayProperty.getFullYear(), todayProperty.getMonth(), 1);
              var lastDayProperty = new Date(todayProperty.getFullYear(), todayProperty.getMonth() + 1, 0);
              if(key >= firstDayProperty && key < lastDayProperty){
                addedPropertyInMonth = addedPropertyInMonth + 1
              }
          }
          await store.dispatch('auth/checkUserPropertyInAMonth',addedPropertyInMonth);   
      }
      }     
    }
  
    next()
    return
    } 
    // && to.path !== `/${lang}/drug-index` 
    if (to.path !== `/${lang}` && !to.path.includes('drug-index') && to.path !== `/${lang}/services` && to.path !== `/${lang}/about` && !store.getters['auth/signedIn']) {
      next({ path: `/${lang}` });
      return;
    }



    next();
    return;
  },
};
