import { render, staticRenderFns } from "./register-card.vue?vue&type=template&id=5fc9ef6a&scoped=true&"
import script from "./register-card.vue?vue&type=script&lang=js&"
export * from "./register-card.vue?vue&type=script&lang=js&"
import style0 from "./register-card.vue?vue&type=style&index=0&id=5fc9ef6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc9ef6a",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QIcon,QBtn,QSpinnerIos,QSeparator,QForm,QInput,QAvatar} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QIcon,QBtn,QSpinnerIos,QSeparator,QForm,QInput,QAvatar})
