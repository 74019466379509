
import { mapGetters } from 'vuex'
import i18n from "@/vueI18n";
import { AnalyticsManager } from '@/shared/firebase/firebase-analytics';

export default {
  props: ["record", "service"],
  name: 'app-share-button',
  data(){
      return{
            // drugDealURL:"https://drug-deal-web-app.web.app/",
            drugDealURL: `https://web.drugdealapp.com/${localStorage.getItem("language")}/${this.service}/${this.record.id}`,
            media: null,
            title: null,
            description: null
      }
  },
  created(){
    if(this.service == 'prescriptions'){
      this.title = this.record.title
      this.description = this.record.description
    }
    else if(this.service == 'drug-index'){
      this.title = this.record.drugName
      this.description = this.record.mechOfAction
    }
    else if(this.service == 'offers'){
      this.title = this.isRTL ? this.record.title.ar : this.record.title.en
      this.description = this.isRTL ? this.record.description.ar : this.record.description.en
    }
    else{      
      this.title = this.record.name
      this.description = this.record.description
      // debugger
    }
    // this.title = this.service == 'prescriptions' ? this.record.title : this.record.name
    // this.drugDealURL.concat(this.service).concat('/')
    // debugger
  },
 
  computed:{
      ...mapGetters({
          is_screen_xs: 'layout/is_screen_xs'
      }),
      isRTL() {
      return i18n.locale == "ar";
    },
  },
  methods:{
    i18n(key, args) {
        return this.$t(key, args);
    },
    callFirebaseAnalytics(){
      // console.log('share');
      // debugger
      AnalyticsManager.logShare(this.record.id, this.service)
    }
  }
};
