import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire'
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Router from 'vue-router';
import shared from '@/shared/shared-module';
import auth from '@/modules/auth/auth-module';
import layout from '@/modules/layout/layout-module';
import home from '@/modules/home/home-module';
import services from '@/modules/services/services-module';
import about from '@/modules/about/about-module';
import drugIndex from '@/modules/drug-index/drugIndex-module';
import prescriptions from '@/modules/prescriptions/prescriptions-module';
import jobs from '@/modules/jobs/jobs-module';
import facilities from '@/modules/facilities/facilities-module';
import offers from '@/modules/offers/offers-module';
import notification from '@/modules/notification/notification-module';

// import iam from '@/modules/iam/iam-module';
// import settings from '@/modules/settings/settings-module';
// import auditLog from '@/modules/audit-log/audit-log-module';
// import customer from '@/modules/customer/customer-module';
// import product from '@/modules/product/product-module';
// import order from '@/modules/order/order-module';

const modules = {
  shared,
  home,
  layout,
  auth,
  services,
  about,
  drugIndex,
  prescriptions,
  jobs,
  facilities,
  offers,
  notification
  // settings,
  // iam,
  // auditLog,
  // customer,
  // product,
  // order,
};

// start - boilerplate code

const exists = (el) => !!el;

function setupComponentsFiltersDirectivesAndMixins() {
  Object.keys(modules)
    .map((key) => modules[key].components)
    .filter(exists)
    .forEach((components) => {
      components.forEach((component) => {
        Vue.component(component.name, component);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].filters)
    .filter(exists)
    .forEach((components) => {
      components.forEach((filter) => {
        Vue.filter(filter.name, filter.implementation);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].directives)
    .filter(exists)
    .forEach((directives) => {
      directives.forEach((directive) => {
        Vue.directive(
          directive.name,
          directive.implementation,
        );
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].mixins)
    .filter(exists)
    .forEach((mixins) => {
      mixins.forEach((mixin) => {
        Vue.mixin(mixin);
      });
    });
}

const routes = [
  ...Object.keys(modules)
    .filter((key) => !!modules[key].routes)
    .map((key) => modules[key].routes)
    .reduce((a, b) => a.concat(b), []),
  { path: '*', redirect: '/en/404' },
];

let router = null;

const routerAsync = () => {
  if (!router) {
    router = new Router({
      mode: 'history',
      routes,
      scrollBehavior() {
        return { x: 0, y: 0 };
      },
    });

    router.beforeEach((to, from, next) => {
      if (to.name) {
        ProgressBar.start();
      }

      next();
    });

    router.afterEach(() => {
      ProgressBar.done();
    });
  }

  return router;
};

const buildStores = () => {
  const output = {};

  Object.keys(modules)
    .filter((key) => !!modules[key].store)
    .forEach((key) => {
      output[key] = modules[key].store;
    });

  return output;
};

let store = null;

const storeAsync = () => {
  if (!store) {
    store = new Vuex.Store({ 
      modules: buildStores(),
      mutations: {
        // other mutations
        ...vuexfireMutations,
      },
    });
  }

  return store;
};

export {
  setupComponentsFiltersDirectivesAndMixins,
  routerAsync,
  storeAsync,
};

// end - boilerplate code
