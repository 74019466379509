// import { PrescriptionsService } from '@/modules/prescriptions/prescriptions-service';
// import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';

import { firestoreAction,vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/app';

export default {
  namespaced: true,

  state: {
    loading: false,
    userLoading:false,
    record: null,
    user: null,
    comments: [],
    commentsTotal: 0,
    users: [],
    commentCreator: [],
    addedCommentId: null,
    addedCommentorId: null,
    mountedRecord: false,
    loadMoreLastRecord: null,
    recordAfterLast: [],
    loadingComments: false

  },

  getters: {
    record: (state) => state.record,
    user: (state) => state.user,
    loading: (state) => !!state.loading,
    comments: (state) =>  state.comments,
    commentsTotal: (state) => state.commentsTotal,
    users: (state) => state.users,
    commentCreator: (state) => state.commentCreator,
    addedCommentId: (state) => state.addedCommentId,
    addedCommentorId: (state) => state.addedCommentorId,
    mountedRecord: (state) => state.mountedRecord,
    loadMoreLastRecord: (state) =>  state.loadMoreLastRecord,
    recordAfterLast: (state) => state.recordAfterLast,
    loadingComments: (state) => state.loadingComments
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
    FIND_USER_STARTED(state) {
      state.user = null;
      state.userLoading = true;
    },

    FIND_USER_SUCCESS(state, payload) {
      state.user = payload;
      state.userLoading = false;
    },

    FIND_USER_ERROR(state) {
      state.user = null;
      state.userLoading = false;
    },
    ...vuexfireMutations,
    SET_ADDED_COMMENT_ID(state,payload){
      state.addedCommentId = payload      
    },
    SET_ADDED_COMMENTOR_ID(state,payload){
      state.addedCommentorId = payload      
    },
    async SET_CREATOR_TO_COMMENT(state){
      const creator = await firebase.firestore().collection("user").doc(state.addedCommentorId).get()
      state.commentCreator.push(creator.data())
      const addedComment = state.comments.find((el) => el.id == state.addedCommentId)
      addedComment['creator'] = creator.data()
    },
    SET_MOUNTED_RECORD(state,payload){
      state.mountedRecord = payload
    },
    SET_LOADMORE_LAST(state) {
      state.loadMoreLastRecord = state.comments[state.comments.length - 1]._doc
    },
    FETCH_COMMENTS_STARTED(state){
      state.loadingComments = true
    },
    FETCH_COMMENTS_SUCCESS(state){
      state.loadingComments = false
    },
    RESET_COMMENTS_CURSOR(state){
      state.loadMoreLastRecord = null
    },
    async APPEND_TO_COMMENTS(state,payload){
      if(payload.createdBy){
        const creator = await firebase.firestore().collection("user").doc(payload.createdBy).get()
        payload['creator'] = creator.data()
        // debugger
      }
      state.comments.push(payload)
    },
    SET_LAST_RECORD(state) {
      // console.log('setLoadMoreLast =====>',state.secondBatch[state.secondBatch.length - 1].id);
      state.loadMoreLastRecord = state.comments[state.comments.length - 1]._doc
    },
  },

  actions: {
    doFind:  firestoreAction(async ({ bindFirestoreRef,getters },payload) => {
      const db = firebase.firestore()
      const recordDocument =  db.collection('prescription').doc(payload.id)
      await bindFirestoreRef(
        'record',
        recordDocument,
      );
      const userDocument = db.collection('user').doc(getters.record.createdBy) 
      await bindFirestoreRef(
        'user',
        userDocument,
      );
 
    }),
    doFetchTotalComments:  firestoreAction(async ({ bindFirestoreRef },payload) => {
      const db = firebase.firestore()
      const commentsCollection = db.collection('prescription')
      .doc(payload.id)
      .collection('comments')
      

      await bindFirestoreRef(
        'commentsTotal',
        commentsCollection,
        // { serialize: customSerializer }
      );
      // .orderBy('createdAt','desc')
      // .startAfter(getters.loadMoreLastRecord)
      // .limit(5)
    }),
    doFindCommentsFirstBatch:  firestoreAction(async ({bindFirestoreRef,getters, commit},payload) => {
      commit('FETCH_COMMENTS_STARTED')
      const customSerializer = (doc) => {
        const data = doc.data()
        Object.defineProperty(data, '_doc', { value: doc })
        data['creator'] = ''
        if(getters.commentCreator.length > 0){
          var creatorIndex = getters.commentCreator.findIndex((el) => el.id == data.createdBy);
          if(creatorIndex != -1){
            let creator = getters.commentCreator.find((el) => el.id == data.createdBy)
            data['creator'] = creator
          }          
        }
          var foundIndex = getters.users.findIndex((el) => el == data.createdBy);
          if(foundIndex == -1){
            getters.users.push(data.createdBy)
            if(getters.mountedRecord){
              commit('SET_ADDED_COMMENT_ID',data.id) 
              commit('SET_ADDED_COMMENTOR_ID',data.createdBy)     
            }
        }   

        // getters.comments.sort(function (a, b) {
        //   debugger
        //   var keyA = new Date(a.createdAt.seconds),
        //     keyB = new Date(b.createdAt.seconds);
        //   if (keyA > keyB) return 1;
        //   if (keyA < keyB) return -1;
        //   return 0;
        // })
        // debugger
        // getters.comments
        
        return data
      }
      const db = firebase.firestore()
      const commentsCollection =  getters.loadMoreLastRecord ? 
      db.collection('prescription')
      .doc(payload.id)
      .collection('comments')
      .orderBy('createdAt','desc')
      .startAfter(getters.loadMoreLastRecord)
      .limit(5) :
      db.collection('prescription')
      .doc(payload.id)
      .collection('comments')
      .orderBy('createdAt','desc')
      .limit(5)

      await bindFirestoreRef(
        'comments',
        commentsCollection,
        { serialize: customSerializer }
      );
      if(getters.users.length > 0){
        const userCollection = db.collection('user').where('id', 'in', getters.users)
      await bindFirestoreRef(
            `commentCreator`,
            userCollection)
      }
      
      getters.comments.forEach(async (comment) => {
        let creator = getters.commentCreator.find((el) => el.id == comment.createdBy);
        comment['creator'] = creator
      })
      commit('FETCH_COMMENTS_SUCCESS')
    }),


    async doFetchMoreComments({commit, getters},payload){
      // debugger
      const comments = await firebase.firestore().collection('prescription')
      .doc(payload.id)
      .collection('comments')
      .orderBy('createdAt','desc')
      .startAfter(getters.loadMoreLastRecord)
      .limit(5)
      .get()
      // if(comments.docs.length > 0){
        // debugger
        comments.docs.forEach(async doc => {
          // debugger
          let comment = doc.data()
          comment['_doc'] = doc
          // currentNotifications.push(notification)
          await commit('APPEND_TO_COMMENTS',comment)
        })
        // commit('SET_LAST_RECORD')
        commit('SET_LOADMORE_LAST')
      // }      
    },


    resetCommentsCursor({commit}){
      commit('RESET_COMMENTS_CURSOR')
    },
    setDisableNext:  firestoreAction(async ({bindFirestoreRef,getters},payload) => {
      // console.log(getters.loadMoreLastRecord);
      // debugger
      const db = firebase.firestore()
      const commentsCollection = db.collection('prescription')
      .doc(payload.id)
      .collection('comments')
      .orderBy('createdAt','desc')
      .startAfter(getters.loadMoreLastRecord)
      .limit(1)
      // debugger



      await bindFirestoreRef(
        `recordAfterLast`,
        commentsCollection)     
    }),
    setLoadMoreLast({commit}){
      commit('SET_LOADMORE_LAST')
    },
    setCreatorToComment({commit}){
      commit('SET_CREATOR_TO_COMMENT')
    },
    setMountedRecord({commit},payload){
      commit('SET_MOUNTED_RECORD',payload)
    }
    // async doFind({ commit },values) {
    //   try {
    //     console.log('in doFind view store')
    //     commit('FIND_STARTED');
    //     commit('FIND_USER_STARTED');
    //     await PrescriptionsService.find(values.id, async(doc) => {
    //       commit('FIND_SUCCESS',  doc); 
    //           },(user) =>{
    //             console.log('user',user)
    //             commit('FIND_USER_SUCCESS',  user);
    //           }
    //       );
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FIND_ERROR');
    //     commit('FIND_USER_ERROR');
    //     // routerAsync().push('/offer');
    //   }
    // },
    
  },
};
